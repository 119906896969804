/* global fetch */
/* global Blob */

import {
  clone,
  cloneDeep,
  pick,
  isArray,
  isEqual,
  isEmpty,
  isString,
  isObject,
  isNumber,
  find,
  isMatch,
  debounce,
  uniq,
  omit,
  get as getProperty,
  set as setProperty,
  merge,
  includes,
  without,
  intersection,
  reject,
} from 'lodash';
import moment from 'moment';
import algoliasearch from 'algoliasearch';
import firebase from 'firebase/app';
import "firebase/database";
import "firebase/auth";
import {
  baseTalentFields,
  cloudinaryUploadPreset,
  cloudinaryCloudName,
  firebaseFunctionsURL,
  algoliaConfig,
  plans,
  //defaultTalentFields, going to be useful for creating agencies
} from 'config';

import {
  createId,
  cloudinaryUrl,
  // updateEpayAccount,
  // chargeEpayAccount,
} from 'skybolt-api';
// import clean from 'utils/clean';

const algoliaClient = algoliasearch(algoliaConfig.applicationId, algoliaConfig.apiKey);
const talentsIndex = algoliaClient.initIndex('talents');
const talentsByDateCreatedIndex = algoliaClient.initIndex('talentsByDateCreated');
const usersIndex = algoliaClient.initIndex('users');
const eventsIndex = algoliaClient.initIndex('events');
const packsIndex = algoliaClient.initIndex('packs');
const contactsIndex = algoliaClient.initIndex('contacts');

const baseTalent = {
  image: {},
  playsAge: {},
  emails: {},
  phones: {},
  unions: [],
  ssn: "",
  statusHistory: {},
  status: 'INCOMPLETE',
  approved: true,
};
const baseAgency = {
  name: "",
  divisions: [],
  talentTags: [],
  mediaTags: [],
  talentFields: {},
};
const baseUser = {
  id: null,
  firstName: null,
  lastName: null,
  email: null,
  agencyName: null,
  agencyId: null,
  activeDivision: null,
  recentPacks: {},
  talents: {},
  permissions:{},
  divisions: [],
};
const baseEvent = {};
const basePack = {};






export function loadTalentUser(talentId) {
  return async (dispatch, getState) => {
    const talent = await dispatch( loadTalent(talentId) );
    if(!talent) {
      // error already reported by loadTalent.
      return;
    }

    if(!talent.userId) {
      const talentName = `${talent.firstName || ""} ${talent.lastName || ""}`.trim();
      dispatch({
        type:'LOAD_TALENT_USER_FAILURE',
        code: 'loadTalentUser/01',
        message: `No user found for ${talentName}`,
      });
      return;
    }

    const user = await dispatch( loadUser(talent.userId) );

    return user;
  };
}

export function loadTalentSiblings(talentId) {
  return async (dispatch, getState) => {
    const talent = await dispatch( loadTalent(talentId) );
    if(!talent) {
      // error already reported by loadTalent.
      return null;
    }

    if(!talent.userId) {
      dispatch({
        type:'LOAD_TALENT_FAILURE',
        code: 'loadTalentUser/01',
        message: `No user found.`,
      });
      return null;
    }

    const userTalents = await firebase.database().ref(`users/${talent.userId}/talents`).once('value').then(snapshot => snapshot.val());
    const talentIds = Object.keys(userTalents || {});

    const talents = await Promise.all(
      talentIds.map(async talentId => await dispatch(loadTalent(talentId)))
    );

    return talents;
  };
}

export function loadThread(threadId, forceRefresh=false) {
  return async (dispatch, getState) => {
    // const user = getState().user;
    // const canAdmin = user.permissions.canAdminAgencies;
    let thread = getState().threads.all[threadId];
    if(thread && !forceRefresh) {
      return thread;
    }

    thread = await firebase.database().ref(`threads/${threadId}`)
      .once('value')
      .then(snapshot => {
        if(!snapshot.exists()) {
          return null;
        }
        return {...snapshot.val(), id:snapshot.key};
      });

    if(!thread) {
      dispatch({
        type: 'LOAD_THREAD_FAILURE',
        code: 'loadThread/001',
        message: 'Thread not found.',
        threadId,
      });
      return null;
    }

    // TODO: This was causing issues when the user agencyId didn't line up
    // with the talent agencyId.

    // if(user.isLoggedIn && thread.agencyId !== user.agencyId && !canAdmin) {
    //   dispatch({
    //     type: 'LOAD_THREAD_FAILURE',
    //     code: 'loadThread/002',
    //     message: "You don't have permission to view this thread.",
    //     threadId,
    //   });
    //   return null;
    // }

    dispatch({
      type: 'LOAD_THREAD',
      threadId,
      thread,
    });

    return thread;
  };
}

export function loadPackThread(packId) {
  return async (dispatch, getState) => {
    const pack = await dispatch(loadPack(packId));
    let thread;

    if(pack.threadId) {
      thread = await dispatch(loadThread(pack.threadId));
    }

    if(!thread) {
      thread = await dispatch(addThread({
        subject: pack.name,
        recipients: [{
          userId: getState().user.id,
          name: `${getState().user.firstName || ""} ${getState().user.lastName || ""}`.trim(),
        }]
      }));
      pack.threadId = thread.id;
      firebase.database().ref(`packs/${packId}/threadId`).set(thread.id);
    }

    return thread;
  };
}

export function loadThreads(userId) {
  return async (dispatch, getState) => {
    userId = userId || getState().user.id;
    if(!userId) {
      dispatch({
        type: 'LOAD_USER_THREADS_FAILURE',
        userId,
        code: 'loadThreads/001',
        message: 'No user given'
      });
      return;
    }

    const state = getState();
    let threads = state.threads.byUser[userId];
    if(!!threads) {
      return threads;
    }

    dispatch({type:'LOAD_USER_THREADS_REQUEST', userId});

    threads = await firebase.database().ref(`userThreads/${userId}`)
      .orderByChild('dateUpdated')
      .once('value').then(async snapshot => {
        if(!snapshot.exists()) {
          dispatch({
            type: 'LOAD_USER_THREADS_FAILURE',
            code: 'loadUserThreads/001',
            message: 'No threads exist.',
            userId,
          });
          return {};
        }

        const userThreads = snapshot.val();
        const threads = {};

        await Promise.all(
          Object.keys(userThreads).map(async threadId => threads[threadId] = await dispatch( loadThread(threadId) ))
        );

        // self repair any bad data
        for(const id in threads) {
          if(!threads[id]) {
            // console.warn(`Cleaning bad data userThreads/${userId}/${id}`);
            delete threads[id];
            // firebase.database().ref(`userThreads/${userId}/${id}`).remove();
          }
        }

        return threads;
      });

    if(!threads) {
      threads = {};
    }

    dispatch({type:'LOAD_USER_THREADS_SUCCESS', userId, threads});

    return threads;
  };
}

export function loadMessages(threadId) {
  return async (dispatch, getState) => {
    const state = getState();
    let messages = state.messages.byThread[threadId];
    if(!!messages) {
      return messages;
    }

    dispatch({type:'LOAD_MESSAGES_REQUEST', threadId});

    messages = await firebase.database().ref('messages')
      .orderByChild('threadId').equalTo(threadId)
      .once('value').then(snapshot => {
        if(!snapshot.exists()) {
          return {};
        }

        const messages = snapshot.val();
        for(const id in messages) {
          messages[id].id = id;
        }
        return messages;
      });

    dispatch({type:'LOAD_MESSAGES_SUCCESS', threadId, messages});

    return messages;
  };
}

export function loadMessage(messageId) {
  return (dispatch, getState) => {
    let message = getState().messages.all[messageId];
    if(message) {
      return message;
    }

    // console.warn('Unimplemented: actions/loadMessage', messageId);
  };
}

export function subscribeToUnreadMessages(userId) {
  return dispatch => {
    dispatch({
      type: 'LOAD_UNREAD_MESSAGES_REQUEST',
      userId,
    });

    firebase.database().ref(`
    unreadUserMessages/${userId}`).on('value', snapshot => {
      const messages = snapshot.val() || {};
      dispatch({
        type: 'LOAD_UNREAD_MESSAGES',
        userId,
        messages,
      });
    });

  };
}

export function readMessage(messageId) {
  return (dispatch, getState) => {
    const userId = getState().user.id;
    firebase.database().ref(`unreadUserMessages/${userId}/${messageId}`).remove();
    // note: no need to dispatch this. the subscription will pick it up.
  };
}

export function approveMedia(mediaId) {
  return (dispatch, getState) => {
    const state = getState();
    const agencyId = state.user.agencyId;
    let media = state.media.all[mediaId];
    if(!media) {
      dispatch({
        type: 'UPDATE_MEDIA_FAILURE',
        code: 'approveMedia/001',
        message: 'Media not found.',
        mediaId,
      });
      return;
    }

    firebase.database().ref(`media/${mediaId}/approved`).set(true);
    firebase.database().ref(`mediaForApproval/${agencyId}/${mediaId}`).remove();
    media = {...media, approved:true};

    dispatch({
      type: 'UPDATE_MEDIA',
      mediaId,
      media,
    });

    return media;
  };
}

export function loadCannedMessages(agencyId) {
  return async (dispatch, getState) => {
    let messages = getState().cannedMessages.byAgency[agencyId];
    if(messages) {
      return messages;
    }

    messages = await firebase.database().ref(`cannedMessages`)
      .orderByChild('agencyId').equalTo(agencyId).once('value')
      .then(snapshot => {
        if(!snapshot.exists()) {
          return {};
        }

        const messages = snapshot.val();
        for(const id in messages) messages[id].id = id;
        return messages;
      });

    dispatch({
      type: 'LOAD_CANNED_MESSAGES_SUCCESS',
      agencyId,
      messages,
    });

    return messages;
  };
}

export function addCannedMessage(agencyId) {
  return dispatch => {

    const message = {agencyId};
    const messageId = firebase.database().ref(`cannedMessages`).push(message).key;
    message.id = messageId;

    dispatch({
      type: 'ADD_CANNED_MESSAGE',
      agencyId,
      messageId,
      message,
    });
  };
}

export function updateCannedMessage(messageId, update) {
  return (dispatch, getState) => {
    let message = getState().cannedMessages.all[messageId];
    if(!message) {
      dispatch({
        type: 'UPDATE_CANNED_MESSAGE_FAILURE',
        code: 'updateCannedMessage/001',
        message: "Message not found.",
        messageId,
      });
      return;
    }

    firebase.database().ref(`cannedMessages/${messageId}`).update(update);
    message = {...message, ...update};

    dispatch({
      type: "UPDATE_CANNED_MESSAGE",
      messageId,
      agencyId: message.agencyId,
      message,
    });
  };
}

export function deleteCannedMessage(messageId) {
  return (dispatch, getState) => {
    let message = getState().cannedMessages.all[messageId];
    if(!message) {
      dispatch({
        type: 'REMOVE_CANNED_MESSAGE_FAILURE',
        code: 'deleteCannedMessage/001',
        message: "Message not found.",
        messageId,
      });
      return;
    }

    firebase.database().ref(`cannedMessages/${messageId}`).remove();

    dispatch({
      type: "REMOVE_CANNED_MESSAGE",
      agencyId: message.agencyId,
      messageId,
    });
  };
}

export function updateUserPayment(userId, payment) {
  return async (dispatch, getState) => {
    userId = userId || getState().user.id;

    const url = `${firebaseFunctionsURL}/updateBraintreePayment`;
    const options = {
      method:'post',
      headers:{'content-type': 'application/json'},
      body: JSON.stringify({
        customerId: userId,
        nonce:payment
      })
    };

    return await fetch(url, options).then(res => res.json());
  };
}

export function addAgencySubscription(agencyId, payment, planId='agency') {
  return async (dispatch, getState) => {
    agencyId = agencyId || getState().user.agencyId;

    const url = `${firebaseFunctionsURL}/createBraintreeSubscription`;
    const options = {
      method:'post',
      headers:{'content-type': 'application/json'},
      body: JSON.stringify({
        customerId: agencyId,
        planId,
        nonce:payment
      })
    };

    let subscription;

    try {
      subscription = await fetch(url, options).then(res => res.json());
    }
    catch(error) {
      dispatch({
        type: "LOAD_SUBSCRIPTION_ERROR",
        agencyId,
        planId,
        error,
        subscription,
        code: 'addAgencySubscription/01',
      });
      return;
    }

    if(subscription.id) {
      dispatch( updateAgency(agencyId, {subscriptionId:subscription.id}) );
    }
    dispatch( updateAgency(agencyId, {planId}) );

    dispatch({
      type: "LOAD_SUBSCRIPTION",
      agencyId,
      subscription,
      planId,
    });

    return subscription;
  };
}

export function loadAgencyTransactions(agencyId) {
  return async (dispatch, getState) => {
    agencyId = agencyId || getState().user.agencyId;

    dispatch({
      type: "LOAD_AGENCY_TRANSACTIONS_REQUEST",
      agencyId,
    });

    let url = `${firebaseFunctionsURL}/getBraintreeTransactions`;
    url += `?customerId=${agencyId}`;
    const transactions = await fetch(url).then(res => res.json());

    dispatch({
      type: "LOAD_AGENCY_TRANSACTIONS",
      agencyId,
      transactions,
    });
  };
}

export function loadUserTalentsSubscriptions(userId) {
  return async dispatch => {
    if(!userId) {
      dispatch({
        type: "LOAD_USER_TALENT_SUBSCRIPTIONS_FAILURE",
        message: "No user given.",
      });
      return;
    }

    const user = await dispatch( loadUser(userId) );
    const talents = user.talents;

    await Promise.all(
      Object.keys(talents).map(talentId => dispatch( loadTalentSubscription(talentId) ))
    );

    return user;
  };
}











/* App */

export function watchVersion() {
  return (dispatch, getState) => {

    let version = null;

    Promise.all([
      firebase.database().ref('version').once('value').then(s => version = s.val()),
      firebase.database().ref('build').once('value').then(s => s.val()),
    ])
    .then(([version, build]) => {
      if(version === null || build === null) {
        return;
      }
      dispatch({
        type: "SET_VERSION",
        version: `${version}.${build}`,
      });
    });

    // If there's been a major update,
    // prompt the user to refresh.
    firebase.database().ref('version').on('value', snap => {
      if(version && version !== snap.val()) {
        dispatch(
          showSnackbar("A new version of Skybolt is available.", 0, "REFRESH")
        );
      }
    });
  };
}

export function showSnackbar(message="", timeout=null, action) {
  return async dispatch => {
    dispatch({
      type: "SNACKBAR",
      message,
      timeout,
      action
    });

    return new Promise(resolve => setTimeout(resolve, timeout || 400));
  };
}

export function checkEmailAddress(email) {
  return dispatch => {
    return firebase.auth().fetchSignInMethodsForEmail(email);
  };
}

export function inviteTalent(firstName, lastName, email, divisions, talentId, agencyId, plan, comp) {
  return async (dispatch, getState) => {

    const state = getState();


    if(!agencyId) {
      agencyId = state.user.agencyId;
    }
    const agency = state.agencies.all[agencyId];

    if(!agency) {
      return false;
    }

    if(!email) {
      return false;
    }
    email = email.trim();


    // If no talent id is given, create a new talent.

    if(!talentId) {
      const talentData = {
        agencyId,
        agencyContact: {
          userId: state.user.id,
          name: `${state.user.firstName} ${state.user.lastName}`,
        },
        firstName: firstName || "",
        lastName: lastName || "",
        emails: [{
          label: "Home",
          email: email,
        }],
        divisions: divisions || [],
        approved: true,
        requiresApproval: false,
        plan,
      };

      if(agency.chargeTalentsToAgency) {
        talentData.status = "PAID";
        talentData.requireSubscription = false;
        talentData.billingAgencyId = agencyId;
      }
      else if(plan) {
        talentData.status = "PAID";
        talentData.requireSubscription = false;
        talentData.billingUserId = state.user.id;
      }
      else if(agency.requireUserSubscription) {
        talentData.requireSubscription = false;
      }
      else if(!agency.requireTalentSubscription && !agency.requireUserSubscription) {
        talentData.status = "PAID";
        talentData.requireSubscription = false;
      }
      else {
        talentData.requireSubscription = true;
      }

      const talent = await dispatch(addTalent(talentData));
      talentId = talent.id;
    }


    // Build the invitation.

    const invitation = {
      agencyId,
      agencyName: agency.name,
      user: {
        role: 'talent',
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        agencyId,
        permissions: {
          canOwnTalents: true
        },
        divisions: divisions || [],
        requireSubscription: !!agency.requireUserSubscription
      },
      talentId: talentId,
    };

    const invitationRef = firebase.database().ref('invitations').push(invitation);
    const invitationId = invitationRef.key;

    dispatch(updateTalent(talentId, {
      userId:null,
      invitationId,
    }));


    // If we're comping this talent, add them to the user's plan.

    if(comp) {
      firebase.database().ref('transactions').push({
        type:'COMP_TALENT',
        talentId,
        userId: state.user.id,
      });
    }


    // Report to user.

    dispatch(showSnackbar("Invitation Sent", 3000));

    // Return the invitation object.
    return invitation;
  };
}

export function resendInvitation(invitationId, override={}) {
  return async (dispatch, getState) => {
    dispatch(showSnackbar("Sending Invitation..."));
    let invitation = await firebase.database().ref(`invitations/${invitationId}`).once('value').then(snap => snap.val());
    if(!invitation) {
      return;
    }

    invitation = merge(invitation, override);
    invitation = omit(invitation, ["dateSent", "dateUsed"]);

    firebase.database().ref('invitations').push(invitation);
    dispatch(showSnackbar("Invitation Sent", 2000));
  };
}

export function inviteAgent(firstName, lastName, email, divisions) {
  return async (dispatch, getState) => {
    const state = getState();
    const agencyId = state.user.agencyId;
    const agencyContact = {
      userId: state.user.id,
      name: `${state.user.firstName} ${state.user.lastName}`,
    };

    if(!email) {
      dispatch({
        type: 'INVITE_AGENT_ERROR',
        firstName,
        lastName,
        email,
        divisions,
        code: 'inviteAgent/01',
        message: 'No invitation email found.',
      });
      return;
    }

    const invitation = {
      agencyId,
      user: {
        role: 'agent',
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        agencyId,
        agencyContact,
        permissions: {
          canPack: true,
          canMessageTalents: true,
          canEditTalents: true,
          canApproveTalents: true,
          canApproveMedia: true,
          canConfigAgency: true,
          canAddTalents: true,
          canNoteTalents: true,
          canAgencyCalendar: true,
        },
        divisions: divisions || [],
      }
    };

    firebase.database().ref('invitations').push(invitation);

    dispatch({
      type: 'INVITE_AGENT',
      invitation,
    });

    return invitation;
  };
}

export function inviteCastingDirector(firstName, lastName, email, agencyIds=[]) {
  return async (dispatch, getState) => {
    if(!email) {
      dispatch({
        type: 'INVITE_CASTINGDIRECTOR_ERROR',
        firstName,
        lastName,
        email,
        agencyIds,
        code: 'inviteCastingDirector/01',
        message: 'No invitation email found.',
      });
      return;
    }

    const invitation = {
      agencyId: getState().user.agencyId,
      user: {
        role: 'castingdirector',
        firstName: firstName || "",
        lastName: lastName || "",
        email: email || "",
        agencyIds: agencyIds,
        permissions: {
          canViewPacks: true,
          canViewTalents: agencyIds.length > 0,
        },
      }
    };

    firebase.database().ref('invitations').push(invitation);

    dispatch({
      type: 'INVITE_CASTINGDIRECTOR',
      invitation,
    });

    return invitation;
  };
}

export function loadTalentInvitation(talentId) {
  return async dispatch => {
    const talent = await dispatch(loadTalent(talentId));
    if(talent.invitationId) {
      return await dispatch(loadInvitation(talent.invitationId));
    }
    return null;
  };
}

export function loadInvitations() {
  return async dispatch => {
    const invitations = await firebase.database()
      .ref(`invitations`)
      .orderByChild('dateSent').limitToLast(100)
      .once('value').then(snapshot => {
        let invitations = snapshot.val();
        for(const id in invitations) invitations[id].id = id;
        return invitations;
      });

    dispatch({
      type: "LOAD_INVITATIONS",
      invitations,
    });

    return invitations;
  };
}

export function loadInvitation(invitationId) {
  return async (dispatch, getState) => {
    let invitation = getState().invitations.all[invitationId];
    if(!!invitation) {
      return invitation;
    }

    dispatch({
      type: 'LOAD_INVITATION_REQUEST',
      invitationId,
    });

    invitation = await firebase.database().ref(`invitations/${invitationId}`)
      .once('value').then(snapshot => {
        if(!snapshot.exists()) {
          return false;
        }
        return {
          ...snapshot.val(),
          id:snapshot.key
        };
      });

    if(!invitation) {
      dispatch({
        type: 'LOAD_INVITATION_FAILURE',
        code: 'loadInvitation/001',
        message: "Invitation not found.",
        invitationId,
      });
    }

    dispatch({
      type: 'LOAD_INVITATION',
      invitationId,
      invitation,
    });

    return invitation;
  };
}

export function subscribeToTransactions(type) {
  return dispatch => {
    const valueHandler = snap => {
      let transactions = snap.val();
      transactions = Object.keys(transactions).map(id => ({...transactions[id], id})).reverse();

      dispatch({
        type: "LOAD_TRANSACTIONS",
        transactions,
      });
    };

    let ref = firebase.database().ref('transactions');

    if(type) {
      ref = ref.orderByChild('type').equalTo(type);
    }

    ref.limitToLast(500).on('value', valueHandler);

    return () => ref.off('value', valueHandler);
  };
}



/* Agencies */

const updateAgencyFieldsDate = debounce(agencyId => {
  if(!agencyId) {
    return;
  }
  firebase.database().ref(`agencies/${agencyId}/dateFieldsUpdated`).set(Date.now());
}, 10*1000);

export function updateAgency(agencyId, update) {
  return (dispatch, getState) => {
    let agency = getState().agencies.all[agencyId];
    if(!agency) {
      dispatch({
        type: 'UPDATE_AGENCY_FAILURE',
        code: 'updateAgency/001',
        message: "Agency not found.",
        agencyId,
      });
      return;
    }

    agency = {...agency, ...update};
    agency = omit(agency, ['terms']);

    firebase.database().ref(`agencies/${agencyId}`).update(update);

    dispatch({
      type: 'UPDATE_AGENCY',
      agencyId,
      agency
    });

    return agency;
  };
}

export function addAgencyTalentField(agencyId) {
  return (dispatch, getState) => {
    let agency = getState().agencies.all[agencyId];
    if(!agency) {
      dispatch({
        type: 'UPDATE_AGENCY_FAILURE',
        code: 'addAgencyTalentField/001',
        message: "Agency not found.",
        agencyId,
      });
      return;
    }

    const field = {
      name: '',
      type: 'text',
    };
    const fieldId = firebase.database().ref(`agencies/${agencyId}/talentFields`).push(field).key;
    agency = clone(agency);
    agency.talentFields[fieldId] = field;

    updateAgencyFieldsDate(agencyId);

    dispatch({
      type: 'UPDATE_AGENCY',
      agency,
    });

    return fieldId;
  };
}

export function removeAgencyTalentField(agencyId, fieldId) {
  return (dispatch, getState) => {
    let agency = getState().agencies.all[agencyId];
    if(!agency) {
      dispatch({
        type: 'UPDATE_AGENCY_FAILURE',
        code: 'removeAgencyTalentField/001',
        message: "Agency not found.",
        agencyId,
      });
      return;
    }

    agency = clone(agency);
    delete agency.talentFields[fieldId];

    firebase.database().ref(`agencies/${agencyId}/talentFields/${fieldId}`).remove();

    updateAgencyFieldsDate(agencyId);

    dispatch({
      type: 'UPDATE_AGENCY',
      agency,
    });
  };
}

export function updateAgencyTalentField(agencyId, fieldId, field) {
  return (dispatch, getState) => {
    let agency = getState().agencies.all[agencyId];
    if(!agency) {
      dispatch({
        type: 'UPDATE_AGENCY_FAILURE',
        code: 'updateAgencyTalentField/001',
        message: "Agency not found.",
        agencyId,
      });
      return;
    }

    agency = clone(agency);
    agency.talentFields[fieldId] = Object.assign(agency.talentFields[fieldId] || {}, field);

    firebase.database().ref(`agencies/${agencyId}/talentFields/${fieldId}`).update(field);

    // updateAlgoliaTalentFieldIndexing(agency.talentFields);
    updateAgencyFieldsDate(agencyId);

    dispatch({
      type: 'UPDATE_AGENCY',
      agency,
    });

  };
}

export function moveAgencyTalentField(agencyId, fieldId, beforeFieldId) {
  return (dispatch, getState) => {
    const state = getState();
    let fields = getProperty(state, `agencies.all[${agencyId}].talentFields`, null);
    if(!fields) {
      return;
    }

    const thisField = fields[fieldId] || {};
    const beforeField = fields[beforeFieldId] || {};

    const thisOrder = thisField.order || 0;
    const beforeOrder = beforeField.order || 0;

    fields[fieldId].order = beforeOrder > thisOrder ? beforeOrder+0.5 : beforeOrder-0.5;

    Object.keys(fields)
      // .filter(id => fields[id].category !== 'system')
      .sort((a, b) => 
        isNumber(fields[a].order) && fields[a].order > fields[b].order ? 1 : -1
      )
      .forEach((id, i) => {
        fields[id].order = i;
      });

    return dispatch( updateAgency(agencyId, {talentFields:fields}) );
  };
}

export function loadAgencyTerms(agencyId) {
  return async (dispatch, getState) => {
    if(!agencyId) {
      return null;
    }

    const terms = await firebase.database().ref(`terms/${agencyId}`).once('value').then(s => s.val());

    if(terms) {
      let agency = await dispatch(loadAgency(agencyId));
      dispatch({
        type: 'UPDATE_AGENCY',
        agencyId,
        agency: {...agency, terms}
      });
    }
  };
}




/* Talents */

async function createCode(prefix="") {
  if(prefix.length < 4) {
    return null;
  }
  let code = prefix.toUpperCase().replace(/[^A-Z]+/g, "-").slice(0,4);
  return await firebase.database().ref('talents')
    .orderByChild('code').startAt(code).endAt(`${code}\\uf8ff`)
    .once('value').then(snapshot => {
      let count = snapshot.numChildren() + 1;
      count = ("0"+count).slice(-2);
      return `${code}${count}`;
    });
}

export function loadTalent(talentId /* depricated, use dedicated functions instead */, options={}) {
  return async (dispatch, getState) => {
    if(!talentId) {
      return null;
    }

    let talent = getState().talents.all[talentId];
    if(!options.refresh && !!talent) {
      return talent;
    }

    dispatch({type:'LOAD_TALENT_REQUEST', talentId});

    talent = await firebase.database().ref(`talents/${talentId}`)
      .once('value')
      .then(snapshot => {
        if(!snapshot.exists()) {
          return null;
        }
        return {...baseTalent, ...snapshot.val(), id:talentId};
      });
    
    // If the user is an admin or the talent owner, load
    // their social security number.
    let user = getState().user;
    let isOwner = user?.talents && user?.talents[talentId];
    let isAgent = user?.permissions?.canEditTalents && user?.agencyId == talent?.agencyId;
    let isAdmin = user?.permissions?.canAdminTalents;
    if(isOwner || isAgent || isAdmin) {
      talent.ssn = await firebase.database().ref(`talentSSN/${talentId}`).once('value').then(s => s.val() || "");
    }

    if(!talent) {
      dispatch({
        type:'LOAD_TALENT_FAILURE',
        code: 'loadTalent/01',
        message: "Talent not found.",
        talentId,
      });
      return null;
    }

    if(!talent.code) {
      let code = await createCode(talent.lastName);
      if(code) {
        talent.code = code;
        firebase.database().ref(`talents/${talentId}/code`).set(code);
      }
    }

    if(options.withAgency) {
      await dispatch( loadAgency(talent.agencyId) );
    }

    if(options.withMedia) {
      await dispatch(loadTalentMedia(talentId));
    }

    if(options.withUser && talent.userId) {
      await dispatch(loadUser(talent.userId));
    }

    if(talent.invitationId && !talent.userId) {
      await dispatch(loadInvitation(talent.invitationId));
    }

    dispatch({type:'LOAD_TALENT_SUCCESS', talent});

    return talent;
  };
}

export function setActiveCategory(categoryId) {
  return dispatch => {
    dispatch({
      type: 'SET_ACTIVE_CATEGORY',
      categoryId,
    });
  };
}

export function setAgencyTalentsSearchMode(mode) {
  return dispatch => {

    if(mode === 'simple') {
      dispatch({type:'CLEAR_AGENCY_TALENTS_SEARCH'});
      dispatch(searchAgencyTalents());
    }

    dispatch({
      type: 'SET_AGENCY_TALENTS_SEARCH_MODE',
      mode,
    });

  };
}

export function searchTalents(update={}, appendResults=false) {
  return async (dispatch, getState) => {
    const state = getState();
    if(state.talents.isSearching) {
      return;
    }

    const search = {
      ...state.talents.search,
      query: "",
      offset:0,
      length:40,
      ...update
    };

    dispatch({
      type: 'SET_TALENTS_SEARCH',
      search,
    });

    console.log('searching', search);

    const results = await talentsIndex.search(search);

    const talents = {};

    // To maintain order in our object, add an order property to each talent.
    // If we're appending results rather than replacing them, add the existing
    // number of talent to maintain the order.
    const numExistingResults = appendResults ? Object.keys(state.talents.searchResults).length : 0;
    if(results.hits) {
      results.hits.forEach((hit, i) =>
        talents[hit.objectID] = {
          ...baseTalent,
          ...hit,
          id:hit.objectID,
          order:i+numExistingResults
        }
      );
    }

    dispatch({
      type: appendResults ? 'ADD_TALENTS_SEARCH_RESULTS' : 'SET_TALENTS_SEARCH_RESULTS',
      count: results.nbHits,
      talents,
    });
  };
}

export function loadMoreTalentsSearchResults() {
  return async (dispatch, getState) => {

    const state = getState();
    if(state.talents.isSearching) {
      return state.talents.searchResults;
    }

    const loadedCount = Object.keys(state.talents.searchResults).length;
    const searchCount = state.talents.searchCount;
    const remaining = searchCount - loadedCount;

    if(remaining <= 0) {
      return state.talents.searchResults;
    }

    const length = Math.min(remaining, 20);
    const offset = loadedCount;
    return dispatch( searchTalents({offset, length}, true) );
  };
}

export function searchAgencyTalents(update={}, appendResults=false) {
  return async (dispatch, getState) => {
    const state = getState();

    // Check

    if(state.agencyTalents.isSearching) {
      return;
    }

    if(!state.user.agencyId && !state.user.agencyIds) {
      return;
    }

    // console.log(state.user);

    // Emit search value change

    const search = {
      query: "",
      tags: [],
      mediaTags: [],
      status: [],
      ...state.agencyTalents.search,
      offset:0,
      length:100,
      ...update
    };

    dispatch({
      type: 'SET_AGENCY_TALENTS_SEARCH',
      search,
    });


    // Build search fields.

    const role = getProperty(state, 'user.role', null);

    let talentFields = {...baseTalentFields};
    // todo: this should default to true, but then all talent in agences that don't require
    // talent approval need to mark new talent as approved on creation so they show up.
    let requireTalentApproval = false;
    let agencyDivisions = [];

    const agencyIds = [state.user.agencyId, ...(state.user.agencyIds || [])].filter(v => !!v);

    agencyIds.forEach(agencyId => {
      const agency = state.agencies.all[ agencyId ] || {};

      const agencyTalentFields = agency.talentFields || {};
      talentFields = merge(talentFields, agencyTalentFields);

      let thisAgencyDivisions = agency.divisions ? agency.divisions.concat('none') : ['none'];
      if(role === 'castingdirector' && agency.cdDivisions) {
        thisAgencyDivisions = agency.cdDivisions;
      }
      thisAgencyDivisions = thisAgencyDivisions.map(division => `${agencyId}-${division}`);
      agencyDivisions = agencyDivisions.concat(thisAgencyDivisions);

      if(agency.requireApproval) {
        requireTalentApproval = true;
      }
    });

    const filters = Object.keys(talentFields)
      .filter(fieldId => !!search[fieldId] && !isEmpty(search[fieldId]))
      .map(fieldId => {

        // change "age" into date ranges
        if(fieldId === 'age') {
          const years = search[fieldId];
          const ranges = years.map(year => {
            year = Number(year);
            const from = moment().subtract(year+1, 'years').valueOf();
            const to = moment().subtract(year, 'years').valueOf();
            return `dob:${from} TO ${to}`;
          });

          return `(${ranges.join(' OR ')})`;
        }

        // add any other fields, separated by OR
        const type = talentFields[fieldId].type;

        if(isArray(search[fieldId]) && search[fieldId].length > 0) {
          return `(${
            search[fieldId]
              .map(val => {
                if(isString(val)) {
                  val = `'${val}'`;
                }

                return type === 'select' ? `${fieldId}:${val}` :
                       type === 'number' || type === 'range' ? `${fieldId}:${parseFloat(val)} TO ${parseFloat(val)+parseFloat((talentFields[fieldId].step || 1)-.01)}` :
                       type === 'text' ? `${fieldId}:${val}` :
                       type === 'date' & val === 'valid' ? `${fieldId} > ${Date.now()}` :
                       type === 'date' & val === 'expired' ? `${fieldId} < ${Date.now()}` :
                       `${fieldId}='${val}'`;
              })
              .join(' OR ')
          })`;
        }

        return type === 'select' ? `${fieldId}:${search[fieldId]}` :
               type === 'number' || type === 'range' ? `${fieldId}:${parseFloat(search[fieldId])} TO ${parseFloat(search[fieldId])+parseFloat((talentFields[fieldId].step || 1)-.01)}` :
               type === 'text' ? `${fieldId}:'${search[fieldId]}'` :
               type === 'date' & search[fieldId] === 'valid' ? `${fieldId} > ${Date.now()}` :
               type === 'date' & search[fieldId] === 'expired' ? `${fieldId} < ${Date.now()}` :
               `${fieldId}='${search[fieldId]}'`;
      })

      // only show talents from the user's agency or agencies
      .concat(
        search.agencyId ? `agencyId:${search.agencyId}` :
        state.user.agencyId ? `agencyId:${state.user.agencyId}` :
        state.user.agencyIds ? `(${state.user.agencyIds.map(id => `agencyId:${id}`).join(' OR ')} OR createdByUserId:${state.user.id})` :
        []
      )

      // limit to available agency-division pairs
      .concat(
        state.user.agencyIds
          ? `(${agencyDivisions.map(d => `agencyDivisions:'${d}'`).join(' OR ')} OR createdByUserId:${state.user.id})`
          : []
      )

      // if requiring talent approval, show only approved talent
      .concat(!!requireTalentApproval ? "approved:true" : null)

      // add active division for agent users
      .concat(
        state.user.permissions.canPack && state.user.activeDivision
          ? `divisions:'${state.user.activeDivision}'`
          : []
      )

      // add talent tags
      .concat(
        search.tags.map(tag => `tags:'${tag}'`)
      )

      // add media tags
      .concat(
        search.mediaTags.map(tag => `mediaTags:'${tag}'`)
      )

      // filter by status
      .concat(
        search.status.map(status => {
          if(role === 'agent' && status === 'active') {
            // return `(status:'PAID' OR status:'TRIAL')`;
            return `(status:'PAID' OR status:'TRIAL')`;
          }
          if(role === 'castingdirector') {
            return `(status:'PAID' OR status:'TRIAL' OR status:'INCOMPLETE')`;
          }
          return `status:'${status.toUpperCase()}'`;
        })
        .join(' OR ')
      )

      .filter(el => !!el)
      .join(' AND ');

    // Compile and run the search on Algolia

    const algoliaSearch = {
      offset: search.offset,
      length: search.length,
      query: search.query,
      filters
    };

    if(search.distance && !search.lat) {
      const distance = parseFloat(search.distance);
      const address = [search.geostreet, search.geocity, search.geostate, search.geozip].filter(i => !!i).join(', ');
      const googleApiKey = "AIzaSyAHZOmFhCfctYHANdYHJHbQQKOUyWHd-Pg";
      const location = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleApiKey}`).then(res => res.json());
      const lat = getProperty(location, "results[0].geometry.location.lat", null);
      const lng = getProperty(location, "results[0].geometry.location.lng", null);
      if(lat && lng) {
        algoliaSearch.aroundRadius = parseInt(distance * 1609.34, 10);
        algoliaSearch.aroundLatLng = `${parseFloat(lat)}, ${parseFloat(lng)}`;
      }
    }

    if(search.distance && search.lat && search.lng) {
      algoliaSearch.aroundRadius = parseInt( parseFloat(search.distance) * 1609.34, 10);
      algoliaSearch.aroundLatLng = `${parseFloat(search.lat)}, ${parseFloat(search.lng)}`;
    }


    // console.log('searching', JSON.stringify(algoliaSearch));

    const index = search.sort === "dateCreated" || search.sortBy === "dateCreated" ? talentsByDateCreatedIndex : talentsIndex;
    const results = await index.search(algoliaSearch);
    const talents = {};
    // To maintain order in our object, add an order property to each talent.
    // If we're appending results rather than replacing them, add the existing
    // number of talent to maintain the order.
    const numExistingResults = appendResults ? Object.keys(state.agencyTalents.searchResults).length : 0;

    if(results.hits) {
      // console.log('hits', results.hits);
      results.hits.forEach((hit, i) => {

        talents[hit.objectID] = {
          ...baseTalent,
          ...hit,
          id: hit.objectID,
          order: i + numExistingResults,
        };

        if(search.filterByTags) {
          for(let tag of search.filterByTags) {
            tag = tag.replace(/[.#$/[\]]/g, "_");
            const tagImage = getProperty(talents[hit.objectID], `imageByTag['${tag}']`, null);
            if(tagImage) {
              talents[hit.objectID].image = tagImage;
            }
          }
        }
      });
    }

    dispatch({
      type: appendResults ? 'ADD_AGENCY_TALENTS_SEARCH_RESULTS' : 'SET_AGENCY_TALENTS_SEARCH_RESULTS',
      count: results.nbHits,
      talents,
      algoliaSearch,
    });

    return getState().agencyTalents.searchResults;
  };
}

export function loadMoreAgencyTalentsSearchResults(count=20) {
  return async (dispatch, getState) => {

    const state = getState();
    if(state.agencyTalents.isSearching) {
      return state.agencyTalents.searchResults;
    }

    const loadedCount = Object.keys(state.agencyTalents.searchResults).length;
    const searchCount = state.agencyTalents.searchCount;
    const remaining = searchCount - loadedCount;

    if(remaining <= 0) {
      return state.agencyTalents.searchResults;
    }

    const length = Math.min(remaining, count);
    const offset = loadedCount;
    return dispatch( searchAgencyTalents({offset, length}, true) );
  };
}

export function loadTalentAgency(talentId) {
  return async dispatch => {
    const agencyId = await firebase.database().ref(`talents/${talentId}/agencyId`).once('value').then(s => s.val());
    if(!agencyId) {
      return null;
    }
    const agency = await dispatch( loadAgency(agencyId) );
    return agency;
  };
}

export function markWelcomeSeen(talentId) {
  return (dispatch, getState) => {
    if(!talentId) {
      dispatch({
        type: 'MARK_WELCOME_SEEN_FAILURE',
        code: 'markWelcomeSeen/01',
        message: "No talent found.",
      });
      return;
    }

    const now = moment().valueOf();

    firebase.database().ref(`talents/${talentId}/requireShowWelcome`).remove();
    firebase.database().ref(`talents/${talentId}/dateWelcomeSeen`).set(now);
    dispatch({
      type:"MARK_WELCOME_SEEN",
      hasSeenWelcome:now,
      talentId,
    });
  };
}

export function addTalentImage(talentId, file, onProgress) {
  return async (dispatch, getState) => {
    const talent = getState().talents.all[talentId];

    if(!talent) {
      dispatch({
        type: 'ADD_TALENT_IMAGE_FAILURE',
        code: 'addTalentImage/001',
        message: 'Talent not found.',
        talentId
      });
      return;
    }

    return new Promise((resolve, reject) => {

      let formData = new FormData();
      formData.append('upload_preset', cloudinaryUploadPreset);
      formData.append("file", file);

      var xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function(e) {
        // not done yet
        if(xhr.readyState !== 4) {
          return;
        }

        // something went wrong.
        if(xhr.status !== 200) {
          console.log('error', xhr);
          reject();
          return;
        }

        var res = JSON.parse(xhr.responseText);
        if(!res || !res.public_id) {
          reject(res);
          return;
        }

        const image = {
          cloudinary_id: res.public_id,
        };

        if(!image.cloudinary_id) {
          const error = {
            type: 'ADD_TALENT_IMAGE_FAILURE',
            code: 'addTalentImage/003',
            message: 'Image failed to upload.',
            talentId,
          };
          dispatch(error);
          reject(error);
          return;
        }

        // Update the talent.
        const dateUpdated = moment().valueOf();
        const updatedTalent = { ...talent, image, dateUpdated };

        firebase.database().ref(`talents/${talentId}/image`).set(image);
        firebase.database().ref(`talents/${talentId}/dateUpdated`).set(dateUpdated);

        dispatch({
          type: 'UPDATE_TALENT',
          talentId,
          talent: updatedTalent,
        });

        // Add image as media item on talent.
        dispatch( addMedia(talentId, image) );

        resolve(updatedTalent);
      };

      if(onProgress) {
        xhr.upload.addEventListener("progress", e => {
          var progress = Math.round((e.loaded * 100.0) / e.total);
          onProgress(progress);
        });
      }

      xhr.open("post", `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/auto/upload`);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

      xhr.send(formData);

    });

  };
}

export function addTalent(data={}) {
  return async (dispatch, getState) => {

    const state = getState();
    let agencyId = data.agencyId;

    if(!agencyId) {
      agencyId = state.user.agencyId;
    }

    if(!agencyId) {
      const userTalents = Object.keys(state.user.talents || {})
        .map(talentId => state.talents.all[talentId])
        .filter(talent => !!talent);

      const firstTalent = userTalents[0] || {};
      agencyId = firstTalent.agencyId;
    }

    if(!agencyId) {
      dispatch({
        type:'ADD_TALENT_FAILURE',
        data,
      });
      return;
    }

    const meta = {
      dateCreated: moment().valueOf(),
      dateUpdated: moment().valueOf(),
      requireShowWelcome: true,
      agencyId,
    };

    const talent = Object.assign({}, baseTalent, meta, data);


    // If the agency doesn't require a subscription, mark new talents as PAID.

    const agency = await dispatch( loadAgency(agencyId) );
    // if(talent.requireSubscription === false) {
    //   talent.statusHistory[Date.now()] = 'PAID';
    //   talent.status = 'PAID';
    // }
    // else if(agency.requireTalentSubscription) {
    //   talent.statusHistory[Date.now()] = 'INCOMPLETE';
    //   talent.status = 'INCOMPLETE';
    //   talent.requireSubscription = true;
    // }
    // else if(agency.requireUserSubscription) {
    //   talent.statusHistory[Date.now()] = 'INCOMPLETE';
    //   talent.status = 'INCOMPLETE';
    //   talent.requireSubscription = false;
    // }
    // else if(agency.chargeTalentsToAgency) {
    //   talent.statusHistory[Date.now()] = 'PAID';
    //   talent.status = 'PAID';
    //   talent.requireSubscription = false;
    // }


    // Push the talent.

    const ref = firebase.database().ref('talents').push(talent);
    talent.id = ref.key;


    // If the agency requires approval, flag the talent for approval.

    if(agency.requireTalentApproval && talent.approved !== true) {
      dispatch(flagTalentForApproval(talent.id));
    }


    // Add a legacyId to the new talent.

    const id = await createId();
    ref.child('legacyId').set(id);


    // Add code to new talent.

    let code = await createCode(data.lastName);
    if(code) {
      talent.code = code;
      ref.child('code').set(code);
    }


    // Clear the search cache so this talent will appear in searches.

    talentsIndex.clearCache();


    // Dispatch

    dispatch({
      type:'ADD_TALENT',
      talent,
    });

    return talent;
  };
}

export function addUserTalent(userId=null, data) {
  return async (dispatch, getState) => {
    if(!userId) {
      userId = getState().user.id;
    }
    if(!userId) {
      showSnackbar("You must be logged in to create a talent.");
      return false;
    }
    const talent = await dispatch(addTalent(data));
    return await dispatch(assignTalentToUser(talent.id, userId));
  };
}


const updateTalentAddressDate = debounce(talentId => {
  firebase.database().ref(`talents/${talentId}/dateAddressUpdated`).set(Date.now());
}, 3000);

const updateTalentLegalName = debounce((talentId, legalName) => {
  firebase.functions().httpsCallable('billingUpdateVendor')({
    talentId, 
    nameOnCheck: legalName
  });
}, 3000);

const updateTalentSSN = debounce((talentId, ssn) => {
  firebase.functions().httpsCallable('billingUpdateVendor')({
    talentId,
    taxId: ssn
  });
}, 3000);

export function updateTalent(talentId, update) {
  return async (dispatch, getState) => {
    const talent = getState().talents.all[talentId];
    if(!talent) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'updateTalent/01',
        message: 'Talent not found.',
        talentId,
        update,
      });
      return;
    }

    const dateUpdated = moment().valueOf();
    let updatedTalent = Object.assign({}, talent, update, {dateUpdated});

    // todo: we should clean the talent more thoroughly
    if(updatedTalent._highlightResult) {
      delete updatedTalent._highlightResult;
    }

    // If the talent is updating their SSN, save it separately.
    let ssn = "";
    if(updatedTalent.ssn) {
      ssn = updatedTalent.ssn;
      delete updatedTalent.ssn;
      firebase.database().ref(`talentSSN/${talentId}`).set(ssn);
    }

    if(update.street) {
      updateTalentAddressDate(talentId);
    }

    if(update.legalName) {
      updateTalentLegalName(talentId, update.legalName);
    }

    if(update.ssn) {
      updateTalentSSN(talentId, update.ssn);
    }

    // If the birthday was updated, update day and month keys.
    if(!!update.dob) {
      updatedTalent.dobMonth = moment(update.dob).month() + 1;
      updatedTalent.dobDay = moment(update.dob).date();
    }

    // Update dates for any tracked fields.
    if(talent.agencyId) {
      updatedTalent.dateFieldUpdated = updatedTalent.dateFieldUpdated || {};
      const agency = await dispatch( loadAgency(talent.agencyId) );
      Object.keys(agency?.talentFields || {})
        .filter(fieldId => !!agency.talentFields[fieldId].trackChanges)
        .filter(fieldId => Object.keys(update || {}).indexOf(fieldId) > -1)
        .forEach(fieldId => {
          updatedTalent.dateFieldUpdated[fieldId] = {
            name: agency.talentFields[fieldId].name,
            date: Date.now(),
          };
        });
    }

    if(talent.status === 'INACTIVE') {
      dispatch( activateTalent(talentId) );
    }

    if(!talent.code) {
      let code = await createCode(talent.lastName);
      if(code) {
        talent.code = code;
        firebase.database().ref(`talents/${talentId}/code`).set(code);
      }
    }

    firebase.database().ref(`talents/${talentId}`).update(updatedTalent);
    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: {
        ...updatedTalent,
        ssn: ssn,
      }
    });

    talentsIndex.clearCache();

    return updatedTalent;
  };
}

export function flagTalentForApproval(talentId) {
  return async dispatch => {
    let talent = await dispatch( loadTalent(talentId) );
    const agency = await dispatch( loadAgency(talent.agencyId) );

    if(!agency.requireTalentApproval) {
      return;
    }

    firebase.database().ref(`talentsForApproval/${agency.id}/${talent.id}`).set(true);
    firebase.database().ref(`talents/${talentId}/requiresApproval`).set(true);
    firebase.database().ref(`talents/${talentId}/approved`).set(false);

    talent.requiresApproval = true;
    talent.approved = false;

    dispatch({
      type: "UPDATE_TALENT",
      talentId,
      talent,
    });

    return talent;
  };
}

export function removeTalentForApprovalFlag(talentId) {
  return async dispatch => {

    let talent = await dispatch( loadTalent(talentId) );
    const agency = await dispatch( loadAgency(talent.agencyId) );
    
    if(!agency.id || !talent.id) {
      return;
    }

    firebase.database().ref(`talentsForApproval/${agency.id}/${talent.id}`).remove();

    return talent;
  };
}

export function flagTalentForSubscription(talentId) {
  return async dispatch => {
    let talent = await dispatch( loadTalent(talentId) );

    firebase.database().ref(`talents/${talentId}/requireSubscription`).set(true);
    talent.requireSubscription = true;

    dispatch({
      type: "UPDATE_TALENT",
      talentId,
      talent,
    });

    return talent;
  };
}

export function approveTalent(talentId) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'approveTalent/001',
        message: 'Talent not found.',
        talentId,
      });
      return;
    }

    let agencyId = getState().user.agencyId;
    if(!agencyId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'approveTalent/002',
        message: 'Agency not found.',
        agencyId,
      });
      return;
    }

    firebase.database().ref(`talents/${talentId}/dateUpdated`).set(Date.now());
    firebase.database().ref(`talents/${talentId}/approved`).set(true);
    firebase.database().ref(`talents/${talentId}/requiresApproval`).remove();
    firebase.database().ref(`talentsForApproval/${agencyId}/${talentId}`).remove();
    talent = {...talent, approved:true, requiresApproval:false, dateUpdated:Date.now()};

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent,
    });

    talentsIndex.clearCache();

    return talent;
  };
}

export function unapproveTalent(talentId) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'approveTalent/001',
        message: 'Talent not found.',
        talentId,
      });
      return;
    }

    let agencyId = getState().user.agencyId;
    if(!agencyId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'approveTalent/002',
        message: 'Agency not found.',
        agencyId,
      });
      return;
    }

    firebase.database().ref(`talents/${talentId}/approved`).set(false);
    firebase.database().ref(`talents/${talentId}/requiresApproval`).set(true);
    firebase.database().ref(`talentsForApproval/${agencyId}/${talentId}`).set(true);
    talent = {...talent, approved:false, requiresApproval:true};

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent,
    });

    talentsIndex.clearCache();

    return talent;
  };
}

export function addTalentEmail(talentId) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'addTalentEmail/001',
        message: 'Talent not found.',
      });
      return;
    }

    const dateUpdated = moment().valueOf();
    firebase.database().ref(`talents/${talentId}/dateUpdated`).set(dateUpdated);

    const ref = firebase.database().ref(`talents/${talentId}/emails`).push();
    const emailId = ref.key;

    const updatedTalent = Object.assign({}, talent, {
      emails: Object.assign({}, talent.emails, {[emailId]:{}}),
      dateUpdated,
    });

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function addTalentPhone(talentId) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'addTalentPhone/001',
        message: 'Talent not found.',
      });
      return;
    }

    const dateUpdated = moment().valueOf();
    firebase.database().ref(`talents/${talentId}/dateUpdated`).set(dateUpdated);

    const ref = firebase.database().ref(`talents/${talentId}/phones`).push();
    const phoneId = ref.key;

    const updatedTalent = Object.assign({}, talent, {
      phones: Object.assign({}, talent.phones, {[phoneId]:{}}),
      dateUpdated,
    });

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function addTalentExperience(talentId) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'addTalentExperience/001',
        message: 'Talent not found.',
      });
      return;
    }

    const dateUpdated = moment().valueOf();
    firebase.database().ref(`talents/${talentId}/dateUpdated`).set(dateUpdated);

    const ref = firebase.database().ref(`talents/${talentId}/experience`).push();
    const key = ref.key;

    const updatedTalent = Object.assign({}, talent, {
      experience: {
        ...talent.experience,
        [key]: {
          type: null,
          title: "",
          role: "",
          company: "",
        }
      },
      dateUpdated,
    });

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function activateTalent(talentId) {
  return async dispatch => {
    if(!talentId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'activateTalent/001',
        message: 'Talent not found.',
      });
      return;
    }

    const talent = await dispatch( loadTalent(talentId) );
    let lastStatus = 'PAID';

    const activeHistory = Object.keys(talent.statusHistory || {})
      .map(key => ({date:key, status:talent.statusHistory[key]}))
      .sort((a,b) => a.date < b.date ? -1 : 1)
      .filter(s => s.status !== 'INACTIVE');


    if(activeHistory.length && activeHistory[0].status) {
      lastStatus = activeHistory[0].status;
    }

    const updatedTalent = {
      ...talent,
      status: lastStatus,
      statusHistory: {
        ...talent.statusHistory,
        [Date.now()]: lastStatus,
      }
    };

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    talentsIndex.clearCache();
  };
}

export function deactivateTalent(talentId) {
  return async dispatch => {
    if(!talentId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'deactivateTalent/001',
        message: 'Talent not found.',
      });
      return;
    }

    const talent = await dispatch( loadTalent(talentId) );

    const statusHistory = !isEmpty(talent.statusHistory) ? talent.statusHistory : {[Date.now()-1]:talent.status};

    const updatedTalent = {
      ...talent,
      dateUpdated: Date.now(),
      status: "INACTIVE",
      statusHistory:{
        ...statusHistory,
        [Date.now()]: "INACTIVE",
      }
    };
    firebase.database().ref(`talents/${talentId}`).update(updatedTalent);

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    talentsIndex.clearCache();

    return updatedTalent;
  };
}

export function removeTalentPhone(talentId, phoneId) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent || !talentId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'removeTalentPhone/001',
        message: 'Talent not found.',
      });
      return;
    }

    if(!phoneId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'removeTalentPhone/002',
        message: 'Phone number not found.',
      });
      return;
    }

    const dateUpdated = moment().valueOf();
    const phones = talent.phones;
    delete phones[phoneId];
    const updatedTalent = Object.assign({}, talent, {phones, dateUpdated});

    firebase.database().ref(`talents/${talentId}/phones/${phoneId}`).remove();
    firebase.database().ref(`talents/${talentId}/dateUpdated`).set(dateUpdated);

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function removeTalentExperience(talentId, experienceId) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent || !talentId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'removeTalentExperience/001',
        message: 'Talent not found.',
      });
      return;
    }

    if(!experienceId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'removeTalentExperience/002',
        message: 'Experience not found.',
      });
      return;
    }

    const dateUpdated = moment().valueOf();
    const experience = talent.experience;
    delete experience[experienceId];
    const updatedTalent = {...talent, experience, dateUpdated};

    firebase.database().ref(`talents/${talentId}/experience/${experienceId}`).remove();
    firebase.database().ref(`talents/${talentId}/dateUpdated`).set(dateUpdated);

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function removeTalentEmail(talentId, emailId) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent || !talentId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'removeTalentEmail/001',
        message: 'Talent not found.',
      });
      return;
    }

    if(!emailId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'removeTalentEmail/002',
        message: 'Email address not found.',
      });
      return;
    }

    const dateUpdated = moment().valueOf();
    const emails = talent.emails;
    delete emails[emailId];
    const updatedTalent = Object.assign({}, talent, {emails, dateUpdated});

    firebase.database().ref(`talents/${talentId}/emails/${emailId}`).remove();
    firebase.database().ref(`talents/${talentId}/dateUpdated`).set(dateUpdated);

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function sendTalentEmailVerification(talentId, emailId) {
  return async dispatch => {

    const url = `${firebaseFunctionsURL}/sendTalentEmailVerification`;
    const options = {
      method: 'post',
      headers: {'content-type':'application/json'},
      body: JSON.stringify({
        talentId,
        emailId,
      })
    };

    try {
      const res = await fetch(url, options).then(res => res.json());
      dispatch({
        type: "EMAIL_TALENT_VERIFICATION_SENT",
        talentId,
        emailId,
      });

      firebase.database().ref(`talents/${talentId}/emails/${emailId}/verificationSent`).set(Date.now());

      dispatch(showSnackbar("Sent"));
      return res;
    }

    catch(error) {
      dispatch({
        type: 'MESSAGE_TALENT_VERIFICATION_FAILURE',
        code: 'sendTalentEmailVerification/01',
        error: error,
        message: "Message failed. Please try again.",
      });
      dispatch(showSnackbar("Message failed. Please try again."));
      return Promise.reject(error);
    }

  };
}

export function verifyTalentEmail(talentId, emailId) {
  return dispatch => {

    if(!talentId || !emailId) {
      dispatch({
        type: 'VERIFY_TALENT_EMAIL_FAILURE',
        code: 'verify/01',
        message: "Verification Failed",
      });
      return;
    }

    try {
      firebase.database().ref(`talents/${talentId}/emails/${emailId}/isVerified`).set(Date.now());
      dispatch({
        type: 'VERIFY_TALENT_EMAIL',
        talentId,
        emailId,
      });
      return;
    }
    catch(error) {
      dispatch({
        type: 'VERIFY_TALENT_EMAIL_FAILURE',
        code: 'verify/02',
        error,
        message: "Verification Failed",
      });
      return;
    }

  };
}

export function updateTalentPhone(talentId, phoneId, phone) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'updateTalentPhone/001',
        message: 'Talent not found.',
      });
      return;
    }

    const dateUpdated = moment().valueOf();
    const updatedTalent = {
      ...talent,
      dateUpdated,
      phones: {
        ...talent.phones,
        [phoneId]: {
          ...talent.phones[phoneId],
          ...phone,
        }
      }
    };

    if(talent.status === 'INACTIVE') {
      dispatch( activateTalent(talentId) );
    }

    firebase.database().ref(`talents/${talentId}/phones/${phoneId}`).update(phone);
    firebase.database().ref(`talents/${talentId}/dateUpdated`).set(dateUpdated);

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function updateTalentStatus(talentId, status) {
  return async (dispatch, getState) => {
    if(!talentId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'updateTalentStatus/001',
        message: 'Talent not found.',
      });
      return;
    }

    const talent = await dispatch( loadTalent(talentId) );

    const statusHistory = !isEmpty(talent.statusHistory) ? talent.statusHistory : {[Date.now()-1]:talent.status};
    const update = {
      status: status,
      statusHistory:{
        ...statusHistory,
        [Date.now()]: status,
      }
    };

    firebase.database().ref(`talents/${talentId}`).update(update);
    const updatedTalent = {...talent, ...update};


    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    talentsIndex.clearCache();

    return updatedTalent;

  };
}

export function updateTalentEmail(talentId, emailId, email) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'updateTalentEmail/001',
        message: 'Talent not found.',
      });
      return;
    }

    const dateUpdated = moment().valueOf();

    firebase.database().ref(`talents/${talentId}/emails/${emailId}`).update(email);
    firebase.database().ref(`talents/${talentId}/dateUpdated`).set(dateUpdated);
    const updatedTalent = {
      ...talent,
      dateUpdated,
      emails: {
        ...talent.emails,
        [emailId]: {
          ...talent.emails[emailId],
          ...email,
        }
      }
    };

    if(talent.status === 'INACTIVE') {
      dispatch( activateTalent(talentId) );
    }

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function updateTalentExperience(talentId, experienceId, experience) {
  return (dispatch, getState) => {
    let talent = getState().talents.all[talentId];
    if(!talent) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'updateTalentExperience/001',
        message: 'Talent not found.',
      });
      return;
    }

    const dateUpdated = moment().valueOf();
    const updatedTalent = {
      ...talent,
      dateUpdated,
      experience: {
        ...talent.experience,
        [experienceId]: experience,
      }
    };

    firebase.database().ref(`talents/${talentId}/experience/${experienceId}`).update(experience);
    firebase.database().ref(`talents/${talentId}/dateUpdated`).set(dateUpdated);

    if(talent.status === 'INACTIVE') {
      dispatch( activateTalent(talentId) );
    }

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function assignTalentToUser(talentId, userId) {
  return async dispatch => {
    let talent, fromUserId;

    if(talentId) {
      talent = await dispatch(loadTalent(talentId));
      fromUserId = talent.userId;

      if(fromUserId) {
        firebase.database().ref(`users/${fromUserId}/talents/${talentId}`).remove();
      }
    }

    if(talentId && userId) {
      firebase.database().ref(`users/${userId}/talents/${talentId}`).set(Date.now());
      firebase.database().ref(`talents/${talentId}/userId`).set(userId);
      talent.userId = userId;
    }

    dispatch({
      type: "ASSIGN_TALENT_SUCCESS",
      talentId,
      fromUserId,
      userId,
    });

    return talent;
  };
}

let unapprovedTalentsSubscription = null;
export function subscribeToUnapprovedTalents(agencyId) {
  return (dispatch, getState) => {

    if(!!unapprovedTalentsSubscription) {
      dispatch( unsubscribeFromUnapprovedTalents() );
    }

    if(!agencyId) {
      agencyId = getState().user.agencyId;
    }

    unapprovedTalentsSubscription = firebase.database().ref(`talentsForApproval/${agencyId}`).on('value', async snapshot => {

      let talents = snapshot.val() || {};

      await Promise.all(
        Object.keys(talents).map(async key => talents[key] = await dispatch(loadTalent(key)))
      );

      dispatch({
        type:'LOAD_UNAPPROVED_TALENTS',
        talents
      });

    });

  };
}
export function unsubscribeFromUnapprovedTalents() {
  return (dispatch, getState) => {
    if(!unapprovedTalentsSubscription) {
      return;
    }
    const agencyId = getState().user.agencyId;
    firebase.database().ref(`talentsForApproval/${agencyId}`).off('value', unapprovedTalentsSubscription);
  };
}

// function updateTalentMediaTags(talentId) {
//   return async (dispatch, getState) => {
//     if(!talentId) {
//       return;
//     }

//     const media = await dispatch(loadTalentMediaIfNeeded(talentId));

//     const tags = uniq(
//       Object.keys(media).reduce((all, itemId) => all.concat(media[itemId].tags || []), [])
//     );

//     firebase.database().ref(`talents/${talentId}/mediaTags`).set(tags);

//   };
// }



/* Contacts */

export function loadAgencyContacts(options={search:{query:""}}) {
  return async (dispatch, getState) => {

    const state = getState();

    const agencyId = state.user.agencyId;
    if(!agencyId) {
      return {};
    }

    const searchUpdate = options.search || {};
    const search = {
      query: "",
      ...state.agencyContacts.search,
      offset:0,
      length:100,
      ...searchUpdate
    };

    dispatch({
      type: "SET_AGENCY_CONTACTS_SEARCH",
      search: search,
    });

    const algoliaSearch = {
      offset: search.offset,
      length: search.length,
      query: search.query,
      filters: `agencyId:${agencyId}`
    };

    const results = await contactsIndex.search(algoliaSearch);

    const contacts = {};
    if(results.hits) {
      results.hits.forEach((hit, i) => {
        contacts[hit.objectID] = {
          ...hit,
          id: hit.objectID,
        };
      });
    }

    dispatch({
      type: "SET_AGENCY_CONTACTS_SEARCH_RESULTS",
      contacts,
      count: results.nbHits,
    });

    return contacts;
  };
}

export function loadContact(id) {
  return (dispatch, getState) => {

    dispatch({
      type: "CONTACT_REQUEST",
      id,
    });

    firebase.database()
      .ref(`contacts/${id}`)
      .once('value')
      .then(snap => {
        if(!snap.exists()) {
          dispatch({
            type: "CONTACT_ERROR",
            id,
            code: "loadContact/01",
            message: "Contact does not exist.",
          });
        }
        const contact = snap.val();

        dispatch({
          type: "CONTACT",
          id,
          contact,
        });
      });

  };
}

export function createContact(data) {
  return (dispatch, getState) => {

    const agencyId = getState().user.agencyId;
    if(!agencyId) {
      dispatch( showSnackbar("Contact could not be created.") );
      return;
    }

    const userId = getState().user.id;
    if(!userId) {
      dispatch( showSnackbar("Something went wrong, please try again.") );
      return;
    }

    const contact = {
      ...data,
      dateCreated: Date.now(),
      dateUpdated: Date.now(),
      createdBy: userId,
      agencyId: agencyId,
    };

    const contactRef = firebase.database().ref(`contacts`).push(contact);

    dispatch({
      type: "CONTACT",
      id: contactRef.key,
      contact,
    });

    contactsIndex.clearCache();
  };
}

const indexContact = debounce(id => {
  firebase.database().ref(`contacts/${id}/dateUpdated`).set(Date.now());
}, 3000);

export function updateContact(id, contact={}) {
  return (dispatch, getState) => {
    if(!id) {
      return;
    }

    firebase.database().ref(`contacts/${id}`).update(contact);
    indexContact(id);

    dispatch({
      type: "CONTACT",
      id,
      contact,
    });
  };
}

export function removeContact(id) {
  return dispatch => {
    if(!id) {
      return;
    }
    firebase.database().ref(`contacts/${id}`).remove();
    dispatch({
      type: "REMOVE_CONTACT",
      id,
    });
  };
}



/* Packs */

const updatePackDate = debounce((packId, now=null) => {
  if(!packId) {
    return;
  }
  if(!now) {
    now = Date.now();
  }
  firebase.database().ref(`packs/${packId}/dateUpdated`).set(now);
}, 3000);

export function addPack(data={}, options={}) {
  return async (dispatch, getState) => {

    const state = getState();
    const userId  = state.user.id;
    const userName = `${state.user.firstName || ""} ${state.user.lastName || ""}`.trim() || state.user.email;
    const agencyId = state.user.agencyId;
    const agency = state.agencies.all[agencyId] || {};

    const pack = {
      name: "New Package",
      dateCreated: moment().valueOf(),
      dateUpdated: moment().valueOf(),
      userId,
      userName,
      agencyId: agencyId || null,
      agencyName: agency.name || "",
      dateViewed: {
        [userId]: Date.now(),
      },
      recipients: {
        [userId]: {
          name: userName,
          userId: userId,
        }
      },
      ...data
    };

    const ref = firebase.database().ref('packs').push(pack);
    pack.id = ref.key;

    if(options.createCategory !== false) {
      const catRef = ref.child('categories').push('Category 1');
      pack.categories = {[catRef.key]:'Category 1'};
    }

    if(options.createThread !== false) {
      const thread = await dispatch( addThread({
        subject: pack.name,
        recipients: [{
          userId: userId,
          name: userName,
        }]
      }));
      pack.threadId = thread.id;
      firebase.database().ref(`packs/${pack.id}/threadId`).set(thread.id);
    }

    if(agencyId) {
      firebase.database().ref(`agencyPacks/${agencyId}/${pack.id}`).set(Date.now());
    }

    dispatch({
      type: "ADD_PACK",
      pack,
      packId: pack.id,
    });

    return pack;
  };
}

export function loadChildPacks(packId) {
  return async (dispatch, getState) => {
    if(!packId) {
      dispatch({
        type: "LOAD_PACK_FAILURE",
        code: 'loadSubpacks/001',
        message: "Pack id is required.",
        packId,
      });
      return;
    }

    const packs = await firebase.database().ref(`packs`)
      .orderByChild('parentPackId').equalTo(packId)
      .once('value').then(snapshot => snapshot.val() || {});

    return Object.keys(packs).map(packId => {
      const pack = {
        ...packs[packId],
        id: packId,
      };
      dispatch({type:"LOAD_PACK_SUCCESS", pack});
      return pack;
    });
  };
}

export function loadPack(packId) {
  return async (dispatch, getState) => {
    if(!packId) {
      dispatch({
        type: "LOAD_PACK_FAILURE",
        code: 'loadPack/001',
        message: "Pack id is required.",
        packId,
      });
      return;
    }

    // const user = getState().user;
    // const canAdmin = user.permissions.canAdminAgencies;
    let pack = getState().packs.all[packId];
    if(!!pack) {
      dispatch(markPackAsViewed(packId));
      return pack;
    }

    dispatch({type:"LOAD_PACK_REQUEST", packId});

    pack = await firebase.database().ref(`packs/${packId}`)
      .once('value').then(snapshot => ({...snapshot.val(), id:packId}));

    if(pack.parentPackId) {
      pack.schedule = await firebase.database()
        .ref(`packs/${pack.parentPackId}/schedule`)
        .once('value').then(s => ({...s.val(), editable:false}));
    }

    if(!pack) {
      dispatch({
        type:"LOAD_PACK_FAILURE",
        code: "loadPack/001",
        message: "Package not found.",
        packId,
      });
      return;
    }

    if(!!pack.agencyId) {
      await dispatch( loadAgency(pack.agencyId) );
    }
    if(!!pack.threadId) {
      await dispatch( loadThread(pack.threadId) );
    }

    // Update pack date viewed for this user.
    const userId = getState().user.id;
    if(packId && userId) {
      setProperty(pack, `dateViewed[${userId}]`, Date.now());
      firebase.database().ref(`packs/${packId}/dateViewed/${userId}`).set(Date.now());
    }

    dispatch({type:"LOAD_PACK_SUCCESS", pack});

    return pack;
  };
}

export function markPackAsViewed(packId) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.user.id;
    if(!userId) {
      return;
    }

    let pack = getProperty(state, `packs.all[${packId}]`);
    if(pack) {
      setProperty(pack, `dateViewed[${userId}]`, Date.now());
      dispatch({type:"UPDATE_PACK", pack});
    }

    firebase.database().ref(`packs/${packId}/dateViewed/${userId}`).set(Date.now());
  };
}

export function loadRecentAgencyPacks(agencyId) {
  return async (dispatch, getState) => {
    let packs = getState().packs.recent;
    if(!!packs) {
      return packs;
    }

    const agencyId = getState().user.agencyId;
    const packIds = await firebase.database().ref(`agencyPacks/${agencyId}`)
      .orderByValue().limitToLast(3)
      .once('value')
      .then(snapshot => {
        if(!snapshot.exists()) {
          return {};
        }
        return snapshot.val();
      });

    packs = {};

    await Promise.all(
      Object.keys(packIds).map(async packId => {
        const name = await firebase.database().ref(`packs/${packId}/name`)
          .once('value')
          .then(snapshot => snapshot.val());

        const preview = await firebase.database().ref(`packs/${packId}/preview`)
          .once('value')
          .then(snapshot => snapshot.val());

        return packs[packId] = {id:packId, name, preview};
      })
    );

    dispatch({
      type: 'LOAD_RECENT_PACKS',
      agencyId,
      packs,
    });

    return packs;
  };
}

export function loadPacks(options={}) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'LOAD_PACKS_REQUEST',
    });

    const offset = options.offset || 0;
    const query = options.query || "";

    const state = getState();
    const agencyId = getProperty(state, 'user.agencyId');
    const userId = getProperty(state, 'user.id');
    const email = getProperty(state, 'user.email');

    let filters = [
      `userId:${userId}`
    ];
    if(agencyId) {
      filters.push(`agencyId:${agencyId}`);
      filters.push(`recipients.agencyId:${agencyId}`);
    }
    if(email) {
      filters.push(`recipients.email:${email}`);
    }
    filters = filters.join(" OR ");

    const search = {
      query: query,
      offset: offset,
      length: 100,
      filters: filters,
    };

    const results = await packsIndex.search(search);
    const packs = {};

    // To maintain order in our object, add an order property to each talent.
    // If we're appending results rather than replacing them, add the existing
    // number of packs to maintain the order.
    const numExistingResults = offset > 0 ? Object.keys(state.packs.all).length : 0;
    if(results.hits) {
      results.hits.forEach((hit, i) =>
        packs[hit.objectID] = {
          ...basePack,
          ...hit,
          id:hit.objectID,
          order:i+numExistingResults
        }
      );
    }//fi hits

    dispatch({
      type: "LOAD_PACKS",
      count: results.nbHits,
      packs,
    });
  };
}

export function loadTalentPacks(talentId) {
  return async (dispatch, getState) => {
    const userAgencyId = getState().user.agencyId;
    let packs = getState().packs.byTalent[talentId];
    if(!!packs) {
      return packs;
    }

    dispatch({
      type:'LOAD_TALENT_PACKS_REQUEST',
      talentId
    });

    packs = await firebase.database().ref(`talentPacks/${talentId}`)
      .once('value').then(async snapshot => {
        if(!snapshot.exists()) {
          return {};
        }

        const talentPacks = snapshot.val();
        const packs = {};

        await Promise.all(
          Object.keys(talentPacks)
            .map(async packId => {
              const pack = await dispatch( loadPack(packId) );
              if(pack && pack.agencyId === userAgencyId) {
                packs[packId] = {...pack, id:packId };
              }
            })
        );

        return packs;
      });

    dispatch({
      type:'LOAD_TALENT_PACKS_SUCCESS',
      talentId,
      packs
    });

    return packs;
  };
}

export function loadLegacyTalentPacks(talentId) {
  return async (dispatch, getState) => {
    let packs = getState().packs.legacyByTalent[talentId];
    if(!!packs) {
      return packs;
    }

    dispatch({
      type:'LOAD_LEGACY_TALENT_PACKS_REQUEST',
      talentId
    });

    packs = await firebase.database().ref(`legacyTalentPacks/${talentId}`)
      .once('value').then(async snapshot => {
        if(!snapshot.exists()) {
          return {};
        }

        return snapshot.val();
      });

    dispatch({
      type:'LOAD_LEGACY_TALENT_PACKS',
      talentId,
      packs,
    });
  };
}

export function subscribeToPack(packId) {
  return (dispatch, getState) => {

    // Input validation.

    if(!packId) {
      console.warn('No pack provided to subscribeToPack.');
      return;
    }

    // Emit request.

    dispatch({type:"LOAD_PACK_REQUEST", packId});

    // Pack value handler.

    const handler = snapshot => {

      let pack = snapshot.val();
      if(!pack) {
        return;
      }

      pack = {id: packId, ...pack};
      let oldPack = getState().packs.all[packId];
      if(isEqual(oldPack, pack)) {
        return;
      }

      dispatch({
        type:"LOAD_PACK_SUCCESS",
        packId,
        pack,
      });

    };

    // Subscribe to pack talents.

    firebase.database().ref(`packs/${packId}`).on('value', handler);

    // Return unsubscribe function

    return () => {
      firebase.database().ref(`packs/${packId}`).off('value', handler);
    };
  };
}

export function updatePackTalent(packId, talentId, update) {
  return async (dispatch, getState) => {
    if(!packId || !talentId) {
      dispatch({
        type: "UPDATE_PACK_TALENT_FAILURE",
        code: 'updatePackTalent/001',
        packId,
        talentId,
      });
      return;
    }

    let ref = firebase.database().ref(`packTalents/${packId}/${talentId}`);
    
    let packTalent = await ref.once('value').then(s => s.val());
    Object.assign(packTalent, update);

    ref.update(update);

    dispatch({
      type:"UPDATE_PACK_TALENT",
      packId,
      talentId,
      talent: packTalent,
    });
  };
}

export function addPackTalent(packId, categoryId, talentId, imageTags=[], includePrivate=false, limitToDivision=false) {
  return async (dispatch, getState) => {
    if(!packId || !talentId) {
      dispatch({
        type: "ADD_PACK_TALENT_FAILURE",
        code: 'addPackTalent/001',
        packId,
        categoryId,
        talentId,
      });
      return;
    }

    // Let's see if the talent is already in the pack, and we're just adding a cateogry.
    let existingPackTalent = await firebase.database().ref(`packTalents/${packId}/${talentId}`).once('value').then(s => s.val());
    if(existingPackTalent) {
      let ref = await firebase.database().ref(`packTalents/${packId}/${talentId}/categories`).push(categoryId);
      let newKey = ref.key;
      existingPackTalent.categories = existingPackTalent.categories || {};
      existingPackTalent.categories[newKey] = categoryId;
      dispatch({
        type:"UPDATE_PACK_TALENT",
        packId,
        talentId,
        pack,
        talent: existingPackTalent,
      });
      return;
    }

    const now = Date.now();

    const state = getState();
    const userId = state.user.id;
    const userRole = getProperty(state, 'user.role', 'talent');

    const talent = await dispatch(loadTalent(talentId));
    const agency = await dispatch(loadAgency(talent.agencyId));
    const pack = await dispatch(loadPack(packId));

    if(!pack || !agency || !talent) {
      dispatch({
        type: "ADD_PACK_TALENT_FAILURE",
        code: 'addPackTalent/002',
        packId,
        categoryId,
        talentId,
      });
      return;
    }

    const allMedia = await dispatch(loadTalentMedia(talentId));
    const media = {};

    Object.keys(allMedia || {})
      .map(id => ({...allMedia[id], id}))
      .filter(item => {
        if(!item.active) {
          return false;
        }
        if(agency.requireTalentMediaApproval && !item.approved) {
          return false;
        }
        if(item.private && !includePrivate) {
          return false;
        }
        if(limitToDivision && !item.divisions?.includes(limitToDivision)) {
          return false;
        }
        return true;
      })
      .sort((a, b) => {
        const aRoleOrder = getProperty(a, `orderByRole.${userRole}`, null);
        const bRoleOrder = getProperty(b, `orderByRole.${userRole}`, null);
        const aUserOrder = getProperty(a, `orderByUser.${userId}`, null);
        const bUserOrder = getProperty(b, `orderByUser.${userId}`, null);

        const aOrder =  aRoleOrder !== null ? aRoleOrder :
                        aUserOrder !== null ? aUserOrder :
                        a.order;
        const bOrder =  bRoleOrder !== null ? bRoleOrder :
                        bUserOrder !== null ? aUserOrder :
                        b.order;

        return aOrder < bOrder ? -1 : 1;
      })
      .sort((a, b) => {
        const aHasTag = !isEmpty( intersection(imageTags, a.tags) );
        const bHasTag = !isEmpty( intersection(imageTags, b.tags) );
        if(aHasTag && !bHasTag) {
          return -1;
        }
        return 1;
      })
      .map((item, order) => ({...item, order}))
      .forEach(item => media[item.id] = item);

    if(!categoryId) {
      categoryId = Object.keys(pack.categories)[0];
    }

    const baseFields = ['id', 'firstName', 'lastName', 'contactName', 'image', 'dob', 'agencyId'];
    const publicFields = Object.keys(agency.talentFields || {}).filter(field => !!agency.talentFields[field].isPublic);
    const fields = [...baseFields, ...publicFields];

    const packTalent = pick(talent, fields);
    packTalent.category = categoryId;
    packTalent.categories = {"primary":categoryId};
    packTalent.media = media;

    const packTalents = await dispatch(loadPackTalents(packId));
    if(!packTalents[talentId]) {
      pack.talentsCount = pack.talentsCount ? pack.talentsCount + 1 : 1;
    }

    firebase.database().ref(`packTalents/${packId}/${talentId}`).set(packTalent);
    firebase.database().ref(`talentPacks/${talentId}/${packId}`).set(moment().valueOf());
    firebase.database().ref(`packs/${packId}/talentsCount`).set(pack.talentsCount);
    firebase.database().ref(`packs/${packId}/dateTalentsUpdated`).set(now);



    if(packId && userId) {
      setProperty(pack, `dateViewed[${userId}]`, now);
      firebase.database().ref(`packs/${packId}/dateViewed/${userId}`).set(now);
    }

    dispatch({
      type:"ADD_PACK_TALENT",
      packId,
      talentId,
      categoryId,
      pack,
      talent: packTalent,
    });

    dispatch( setPackTalentsPreview(packId) );

    updatePackDate(packId, now);

    return pack;
  };
}

export function addPackTalentsFromSearch(packId, categoryId, imageTags=[], includePrivate=false, limitToDivision=false) {
  return async (dispatch, getState) => {
    if(!packId) {
      dispatch({
        type: "ADD_PACK_TALENTS_FAILURE",
        code: 'addPackTalents/001',
        packId,
        categoryId,
      });
      return;
    }

    const now = Date.now();
    const state = getState();
    const agencyId = state.user.agencyId;
    const userRole = getProperty(state, 'user.role', 'talent');


    const agency = await dispatch(loadAgency(agencyId));
    const pack = await dispatch(loadPack(packId));

    if(!pack || !agency) {
      dispatch({
        type: "ADD_PACK_TALENTS_FAILURE",
        code: 'addPackTalents/002',
        packId,
        categoryId,
      });
      return;
    }

    if(!categoryId) {
      categoryId = Object.keys(pack.categories)[0];
    }

    await dispatch(showSnackbar(`Adding talent to ${pack.name}.`));


    const searchResults = await dispatch( loadMoreAgencyTalentsSearchResults(500) );

    const existingTalents = getState().packTalents.byPack[packId] || {};

    const baseFields = ['id', 'firstName', 'lastName', 'contactName', 'image', 'dob', 'agencyId'];
    const publicFields = Object.keys(agency.talentFields).filter(field => !!agency.talentFields[field].isPublic);
    const fields = [...baseFields, ...publicFields];

    const talents = {};

    await Promise.all(
      Object.keys(searchResults).map(async talentId => {

        if(!!existingTalents[talentId]) {
          let existingPackTalent = existingTalents[talentId];
          let ref = await firebase.database().ref(`packTalents/${packId}/${talentId}/categories`).push(categoryId);
          let newKey = ref.key;
          existingPackTalent.categories = existingPackTalent.categories || {};
          existingPackTalent.categories[newKey] = categoryId;
          dispatch({
            type:"UPDATE_PACK_TALENT",
            packId,
            talentId,
            pack,
            talent: existingPackTalent,
          });

          return;
        }

        const talent = searchResults[talentId];

        if(talent.status !== 'PAID' && talent.status !== 'TRIAL') {
          return;
        }

        const allMedia = await firebase.database().ref('media')
          .orderByChild('talentId').equalTo(talentId)
          .once('value').then(snapshot => {
            if(!snapshot.exists()) {
              return {};
            }
            const media = snapshot.val();
            for(const id in media) {
              media[id].id = id;
            }
            return media;
          });

        const media = {};
        Object.keys(allMedia || {})
          .map(id => ({...allMedia[id], id}))
          .filter(item => {
            if(!item.active) {
              return false;
            }
            if(agency.requireTalentMediaApproval && !item.approved) {
              return false;
            }
            if(item.private && !includePrivate) {
              return false;
            }
            if(limitToDivision && !item.divisions?.includes(limitToDivision)) {
              return false;
            }
            return true;
          })
          .sort((a, b) => {
            const aRoleOrder = getProperty(a, `orderByRole.${userRole}`, null);
            const bRoleOrder = getProperty(b, `orderByRole.${userRole}`, null);
            const aUserOrder = getProperty(a, `orderByUser.${userId}`, null);
            const bUserOrder = getProperty(b, `orderByUser.${userId}`, null);

            const aOrder =  aRoleOrder !== null ? aRoleOrder :
                            aUserOrder !== null ? aUserOrder :
                            a.order;
            const bOrder =  bRoleOrder !== null ? bRoleOrder :
                            bUserOrder !== null ? aUserOrder :
                            b.order;

            return aOrder < bOrder ? -1 : 1;
          })
          .sort((a, b) => {
            const aHasTag = !isEmpty( intersection(imageTags, a.tags) );
            const bHasTag = !isEmpty( intersection(imageTags, b.tags) );
            if(aHasTag && !bHasTag) {
              return -1;
            }
            return 1;
          })
          .map((item, order) => ({...item, order}))
          .forEach(item => media[item.id] = item);

        const packTalent = pick(talent, fields);
        packTalent.category = categoryId;
        packTalent.media = media;

        pack.talentsCount = pack.talentsCount ? pack.talentsCount + 1 : 1;

        if(!!packId && !!talentId && !!packTalent) {
          firebase.database().ref(`packTalents/${packId}/${talentId}`).set(packTalent);
          firebase.database().ref(`talentPacks/${talentId}/${packId}`).set(moment().valueOf());
          firebase.database().ref(`packs/${packId}/talentsCount`).set(pack.talentsCount);
        }

        talents[talentId] = packTalent;
      })
    );

    // Update pack status.
    const userId = state.user.id;
    firebase.database().ref(`packs/${packId}/dateTalentsUpdated`).set(now);
    if(packId && userId) {
      setProperty(pack, `dateViewed[${userId}]`, now);
      firebase.database().ref(`packs/${packId}/dateViewed/${userId}`).set(now);
    }

    dispatch({
      type: 'UPDATE_PACK',
      packId,
      pack,
    });

    dispatch({
      type:"LOAD_PACK_TALENTS_SUCCESS",
      packId,
      talents,
    });

    dispatch( showSnackbar(`${Object.keys(talents).length} talent added to ${pack.name}.`, 4000) );

    updatePackDate(packId, now);
  };
}

// todo: debounce dateUpdated so as not to trigger indexing on
// every keystroke.
export function updatePack(packId, update={}) {
  return (dispatch, getState) => {
    let pack = getState().packs.all[packId];
    if(!pack) {
      dispatch({
        type: 'UPDATE_PACK_FAILURE',
        code: 'updatePack/001',
        message: 'Pack not found.',
        packId,
      });
      return;
    }

    const now = Date.now();
    pack = {...pack, ...update};
    firebase.database().ref(`packs/${packId}`).update(update);
    firebase.database().ref(`agencyPacks/${pack.agencyId}/${pack.id}`).set(now);

    dispatch({
      type: 'UPDATE_PACK',
      packId,
      pack,
    });

    updatePackDate(packId, now);

    return pack;
  };
}

export function removePackTalent(packId, talentId, categoryId) {
  return async (dispatch, getState) => {
    const pack = await dispatch(loadPack(packId));
    const now = Date.now();

    const packTalents = await dispatch(loadPackTalents(packId));
    const packTalent = packTalents[talentId];

    if(packTalent.categories && Object.keys(packTalent.categories).length > 1) {
      let categoryKey = Object.keys(packTalent.categories).find(key => packTalent.categories[key] == categoryId);
      delete packTalent.categories[categoryKey];
      firebase.database().ref(`packTalents/${packId}/${talentId}/categories/${categoryKey}`).remove();

      if(packTalent.category == categoryId) {
        let newPrimaryCategoryKey = Object.keys(packTalent.categories).find(k => !!packTalent.categories[k]);
        let newPrimaryCategory = newPrimaryCategoryKey && packTalent.categories[newPrimaryCategoryKey];
        packTalent.category = newPrimaryCategory;
        firebase.database().ref(`packTalents/${packId}/${talentId}/category`).set(newPrimaryCategory);
      }
      dispatch({
        type:"UPDATE_PACK_TALENT",
        packId,
        talentId,
        pack,
        talent: packTalent,
      });
      return;
    }

    if(packTalents[talentId] && packTalents[talentId].isSelected) {
      await dispatch(deselectPackTalent(packId, talentId));
    }

    pack.talentsCount = pack.talentsCount ? pack.talentsCount - 1 : 0;

    firebase.database().ref(`packTalents/${packId}/${talentId}`).remove();
    firebase.database().ref(`talentPacks/${talentId}/${packId}`).remove();
    firebase.database().ref(`packs/${packId}/talentsCount`).set(pack.talentsCount);

    // Update pack status.
    const state = getState();
    const userId = state.user.id;
    firebase.database().ref(`packs/${packId}/dateTalentsUpdated`).set(now);
    if(packId && userId) {
      setProperty(pack, `dateViewed[${userId}]`, now);
      firebase.database().ref(`packs/${packId}/dateViewed/${userId}`).set(now);
    }

    dispatch({
      type:"REMOVE_PACK_TALENT",
      packId,
      talentId,
      pack,
    });

    dispatch( setPackTalentsPreview(packId) );

    updatePackDate(packId, now);

    return pack;
  };
}

export function selectAllPackTalent(packId, categoryId="all") {
  return async (dispatch, getState) => {
    const talents = await dispatch(loadPackTalents(packId));

    let all = Object.keys(talents);

    if(categoryId !== "all") {
      all = all.filter(talentId => !categoryId || talents[talentId].category === categoryId);
    }

    all.forEach(talentId => {
      talents[talentId].isSelected = true;
      dispatch(selectPackTalent(packId, talentId));
    });

    updatePackDate(packId);

    return talents;
  };
}

export function deselectAllPackTalent(packId, categoryId="all") {
  return async (dispatch, getState) => {
    const talents = await dispatch(loadPackTalents(packId));

    let all = Object.keys(talents);

    if(categoryId !== "all") {
      all = all.filter(talentId => !categoryId || talents[talentId].category === categoryId);
    }

    all.forEach(talentId => {
      talents[talentId].isSelected = false;
      dispatch(deselectPackTalent(packId, talentId));
    });

    updatePackDate(packId);

    return talents;
  };
}

export function selectPackTalent(packId, talentId) {
  return async (dispatch, getState) => {
    const talents = await dispatch(loadPackTalents(packId));
    const pack = await dispatch(loadPack(packId));


    if(!pack) {
      console.warn("No pack found to select talent.");
      return ;
    }

    const now = Date.now();

    // todo: let's trigger a notification to the agent in a better way.

    // if(selectedCount === 0) {
    //   dispatch(addMessage(pack.threadId, {
    //     packId,
    //     subject:`${pack.name} shortlist`,
    //     body:`
    //       <p>A shortlist has been created.</p>
    //     `
    //   }));
    // }

    dispatch( updatePack(packId, {}) );
    firebase.database().ref(`packTalents/${packId}/${talentId}/isSelected`).set(true);

    if(!pack.dateShortlist) {
      firebase.database().ref(`packs/${packId}/dateShortlist`).set(moment().valueOf());
      pack.dateShortlist = moment().valueOf();
    }
    firebase.database().ref(`packs/${packId}/shortlistSize`).transaction((count) => count ? count + 1 : 1);
    pack.shortlistSize = pack.shortlistSize ? pack.shortlistSize + 1 : 1;

    firebase.database().ref(`packs/${packId}/dateShortlistUpdated`).set(now);
    pack.dateShortlistUpdated = now;

    const state = getState();
    const userId = state.user.id;
    if(packId && userId) {
      setProperty(pack, `dateViewed[${userId}]`, now);
      firebase.database().ref(`packs/${packId}/dateViewed/${userId}`).set(now);
    }

    dispatch({
      type:"SELECT_PACK_TALENT",
      packId,
      pack,
      talentId,
    });

    updatePackDate(packId, now);

    return talents;
  };
}

export function deselectPackTalent(packId, talentId) {
  return async (dispatch, getState) => {
    const pack = await dispatch(loadPack(packId));

    const now = Date.now();

    if(!pack) {
      console.warn("No pack found to select talent.");
      return ;
    }

    firebase.database().ref(`packTalents/${packId}/${talentId}/isSelected`).set(false);
    firebase.database().ref(`packs/${packId}/shortlistSize`).transaction((count) => count ? count - 1 : 0);
    pack.shortlistSize = pack.shortlistSize ? pack.shortlistSize - 1 : 0;

    firebase.database().ref(`packs/${packId}/dateShortlistUpdated`).set(now);
    pack.dateShortlistUpdated = now;

    const state = getState();
    const userId = state.user.id;
    if(packId && userId) {
      setProperty(pack, `dateViewed[${userId}]`, now);
      firebase.database().ref(`packs/${packId}/dateViewed/${userId}`).set(now);
    }

    dispatch({
      type:"DESELECT_PACK_TALENT",
      packId,
      pack,
      talentId,
    });

    updatePackDate(packId, now);

    return pack;
  };
}

export function renamePackCategory(packId, categoryId, name) {
  return (dispatch, getState) => {
    const pack = clone(getState().packs.all[packId]);
    const dateUpdated = moment().valueOf();

    pack.categories[categoryId] = name;
    pack.dateUpdated = dateUpdated;
    firebase.database().ref(`packs/${packId}/categories/${categoryId}`).set(name);
    firebase.database().ref(`packs/${packId}/dateUpdated`).set(dateUpdated);

    dispatch({
      type:"UPDATE_PACK",
      packId,
      pack,
    });

    updatePackDate(packId);

    return pack;
  };
}

export function addPackCategory(packId) {
  return (dispatch, getState) => {
    let pack = getState().packs.all[packId];
    if(!pack) {
      dispatch({
        type: 'UPDATE_PACK_FAILURE',
        code: 'addPackCategory/001',
        message: 'Package not found.',
        packId,
      });
      return;
    }

    const name = `Category ${Object.keys(pack.categories || {}).length + 1}`
    const categoryId = Date.now();
    firebase.database().ref(`packs/${packId}/categories/${categoryId}`).set(name);
    const categories = {...pack.categories, [categoryId]:name};

    const dateUpdated = moment().valueOf();
    firebase.database().ref(`packs/${packId}/dateUpdated`).set(dateUpdated);

    pack = {...pack, categories, dateUpdated};
    dispatch({
      type:"UPDATE_PACK",
      packId,
      pack,
    });

    updatePackDate(packId);

    return categoryId;
  };
}

export function removePackCategory(packId, categoryId) {
  return (dispatch, getState) => {
    const pack = clone(getState().packs.all[packId]);
    const talents = getState().packTalents.byPack[packId] || {};

    delete pack.categories[categoryId];
    firebase.database().ref(`packs/${packId}/categories`).set(pack.categories);

    if(Object.keys(pack.categories || {}).length === 0) {
      const categoryId = firebase.database().ref(`packs/${packId}/categories`).push().key;
      pack.categories[categoryId] = "Category 1";
    }

    Object.keys(talents)
      .map(talentId => talents[talentId])
      .filter(talent => {
        if(talent.category === categoryId) {
          return true;
        }
        for(let id in talent?.categories || {}) {
          if(talent.categories[id] == categoryId) {
            return true;
          }
        }
        return false;
      })
      .forEach(talent => dispatch(removePackTalent(packId, talent.id, categoryId)));

    const dateUpdated = moment().valueOf();
    firebase.database().ref(`packs/${packId}/dateUpdated`).set(dateUpdated);
    pack.dateUpdated = dateUpdated;

    dispatch({
      type:"UPDATE_PACK",
      packId,
      pack,
    });

    updatePackDate(packId);

    return pack;
  };
}

export function updatePackSchedule(packId, schedule) {
  return (dispatch, getState) => {
    let pack = getState().packs.all[packId];
    if(!pack) {
      dispatch({
        type: 'UPDATE_PACK_FAILURE',
        code: 'updatePackSchedule/001',
        message: 'Package not found.',
        packId,
      });
      return;
    }

    const now = Date.now();
    pack = clone(pack);
    pack.schedule = {...pack.schedule, ...schedule};
    firebase.database().ref(`packs/${packId}/schedule`).update(schedule);

    const dateUpdated = moment().valueOf();
    firebase.database().ref(`packs/${packId}/dateUpdated`).set(dateUpdated);
    pack.dateUpdated = dateUpdated;

    const state = getState();
    const userId = state.user.id;
    if(packId && userId) {
      setProperty(pack, `dateViewed[${userId}]`, now);
      firebase.database().ref(`packs/${packId}/dateViewed/${userId}`).set(now);
    }

    dispatch({
      type:"UPDATE_PACK",
      packId,
      pack,
    });

    updatePackDate(packId, now);

    return pack;
  };
}

export function addPackScheduleTimeslot(packId, timeslot={}) {
  return (dispatch, getState) => {
    let pack = getState().packs.all[packId];
    if(!pack) {
      dispatch({
        type: 'UPDATE_PACK_FAILURE',
        code: 'addPackScheduleTimeslot/001',
        message: 'Package not found.',
        packId,
      });
      return;
    }

    pack = clone(pack);

    if(!pack.schedule) {
      pack.schedule = {timeslots:{}};
    }

    if(!pack.schedule.timeslots) {
      pack.schedule.timeslots = {};
    }

    const timeslotId = firebase.database().ref(`packs/${packId}/schedule/timeslots`).push(timeslot).key;
    pack.schedule.timeslots[timeslotId] = timeslot;

    const dateUpdated = moment().valueOf();
    firebase.database().ref(`packs/${packId}/dateUpdated`).set(dateUpdated);
    pack.dateUpdated = dateUpdated;

    dispatch({
      type:"UPDATE_PACK",
      packId,
      pack,
    });

    updatePackDate(packId);
  };
}

export function removePackScheduleTimeslot(packId, timeslotId) {
  return (dispatch, getState) => {
    let pack = getState().packs.all[packId];
    if(!pack) {
      dispatch({
        type: 'UPDATE_PACK_FAILURE',
        code: 'removePackScheduleTimeslot/001',
        message: 'Package not found.',
        packId,
      });
      return;
    }

    pack = clone(pack);

    // todo: we should remove any event already schedule in this timeslot, and
    // notify those talents that they must choose a new time.

    firebase.database().ref(`packs/${packId}/schedule/timeslots/${timeslotId}`).remove();
    delete pack.schedule.timeslots[timeslotId];

    const dateUpdated = moment().valueOf();
    firebase.database().ref(`packs/${packId}/dateUpdated`).set(dateUpdated);
    pack.dateUpdated = dateUpdated;

    dispatch({
      type:"UPDATE_PACK",
      packId,
      pack,
    });

    updatePackDate(packId);

    return pack;
  };
}

export function loadPackTalents(packId) {
  return async (dispatch, getState) => {
    let talents = getState().packTalents.byPack[packId];
    if(!!talents) {
      return talents;
    }

    dispatch({type:"LOAD_PACK_TALENTS_REQUEST", packId});


    // If the pack doesn't have an assigned agency id, then we treat it as not
    // locked to any particular agency and filter each talent to match the
    // user's agency.

    // If the user owns this pack, then always show all talent.

    const pack = await dispatch(loadPack(packId));
    const packAgencyId = pack.agencyId;
    const userAgencyId = getState().user.agencyId;
    const isOwnPack = pack.userId === getState().user.id;

    let packTalentRef = firebase.database().ref(`packTalents/${packId}`);
    if(!isOwnPack && !!userAgencyId && !packAgencyId) {
      packTalentRef = packTalentRef.orderByChild('agencyId').equalTo(userAgencyId);
    }

    talents = await packTalentRef.once('value').then(snapshot => {
      if(!snapshot.exists()) {
        return {};
      }
      const talents = snapshot.val();
      for(const id in talents) talents[id].id = id;
      return talents;
    });

    dispatch({
      type:"LOAD_PACK_TALENTS_SUCCESS",
      packId,
      talents,
    });

    return talents;
  };
}

export function subscribeToPackTalents(packId) {
  return async (dispatch, getState) => {

    // Input validation.

    if(!packId) {
      console.warn('No pack provided to subscribeToPackTalents.');
      return;
    }

    // Emit request.

    dispatch({type:"LOAD_PACK_TALENTS_REQUEST", packId});

    // Talent value handler.

    const handler = snapshot => {
      let talents = snapshot.val() || {};
      for(const id in talents) {
        talents[id].id = id;
        talents[id].packId = packId;
      }

      dispatch({
        type:"LOAD_PACK_TALENTS_SUCCESS",
        packId,
        talents,
      });
    };

    // Subscribe to pack talents.
    // If the pack doesn't have an assigned agency id, then we treat it as not
    // locked to any particular agency and filter each talent to match the
    // user's agency.

    // If the user owns this pack, then always show all talent.

    const pack = await dispatch(loadPack(packId));
    const packAgencyId = pack.agencyId;
    const userAgencyId = getState().user.agencyId;
    const isOwnPack = pack.userId === getState().user.id;

    let packTalentRef = firebase.database().ref(`packTalents/${packId}`);
    if(!isOwnPack && !!userAgencyId && !packAgencyId) {
      packTalentRef = packTalentRef.orderByChild('agencyId').equalTo(userAgencyId);
    }

    packTalentRef.on('value', handler);

    // Return unsubscribe function

    return () => {
      packTalentRef.off('value', handler);
    };
  };
}

export function subscribeToChildPackTalents(packId) {
  return async (dispatch, getState) => {
    if(!packId) {
      console.warn('No pack provided to subscribeToChildPackTalents.');
      return;
    }

    const childPacks = await dispatch( loadChildPacks(packId) );

    const unsubs = childPacks.map(async childPack => {
      return await dispatch( subscribeToPackTalents(childPack.id) );
    });

    return () => {
      unsubs.forEach(unsubscribe => unsubscribe());
    };
  };
}

export function setPackTalentsPreview(packId) {
  return async (dispatch, getState) => {
    if(!packId) {
      return "";
    }

    const pack = getState().packs.all[packId];
    let talents = getState().packTalents.byPack[packId];

    if(!talents) {
      talents = await firebase.database().ref(`packTalents/${packId}`)
        .limitToLast(10)
        .once('value').then(snapshot => snapshot.val());
    }

    if(!talents) {
      return;
    }

    const images = Object.keys(talents)
      .map(talentId => talents[talentId])
      .filter(talent => !!talent.image)
      .map(talent => talent.image.cloudinary_id)
      .slice(0, 4);


    let preview = "";

    // if(images.length < 4) {
      preview = cloudinaryUrl(images[0], {width:180, height:180, face:true});
    // }
    // else {
      // preview = cloudinary.url(images[0], {transformation: [
      //   {width:90, height:90, crop:'fill', secure:true},
      //   {overlay:images[1], width:90, height:90, x:90, crop:"fill"},
      //   {overlay:images[2], width:90, height:90, y:90, x:-45, crop:"fill"},
      //   {overlay:images[3], width:90, height:90, y:45, x:45, crop:"fill"},
      //   {width:180, height:180, crop:"crop"},
      // ]});
    // }

    if(preview) {
      firebase.database().ref(`packs/${packId}/preview`).set(preview);
      const updatedPack = {...pack, preview};

      dispatch({
        type: 'UPDATE_PACK',
        packId,
        pack: updatedPack,
      });
    }

    updatePackDate(packId);

    return pack;
  };
}

export function loadPackTalent(packId, talentId) {
  return async (dispatch, getState) => {
    let packTalents = getState().packTalents.byPack[packId];
    if(!!packTalents && !!packTalents[talentId]) {
      return packTalents[talentId];
    }

    dispatch({type:"LOAD_PACK_TALENT_REQUEST", packId, talentId});

    const packTalent = await firebase.database().ref(`packTalents/${packId}/${talentId}`)
      .once('value').then(snapshot => {
        if(!snapshot.exists()) {
          return {};
        }
        return {...snapshot.val(), id:snapshot.key()};
      });

    dispatch({
      type:"LOAD_PACK_TALENT_SUCCESS",
      packId,
      talentId,
      packTalent,
    });

    return packTalent;
  };
}

export function duplicatePack(packId) {
  return async (dispatch, getState) => {

    const state = getState();
    const pack = await dispatch(loadPack(packId));
    const talents = await dispatch(loadPackTalents(packId));

    const now = moment().valueOf();
    // todo: use incrementing numbers instead of "(copy)"
    const name = `${pack.name} (copy)`;

    const newPack = {
      agencyId: pack.agencyId,
      categories: pack.categories,
      name,
      userId: state.user.id,
      dateCreated: now,
      dateUpdated: now,
    };

    const ref = firebase.database().ref('packs').push(newPack);
    newPack.id = ref.key;

    firebase.database().ref(`packTalents/${newPack.id}`).set(talents);

    const thread = await dispatch( addThread({packId:newPack.id}) );
    firebase.database().ref(`packs/${newPack.id}/threadId`).set(thread.id);
    newPack.threadId = thread.id;

    dispatch({
      type: "ADD_PACK",
      pack: newPack,
      packId: newPack.id,
    });

    dispatch({
      type: "LOAD_PACK_TALENTS_SUCCESS",
      packId: newPack.id,
      talents,
    });

    return newPack;
  };
}

export function inviteToPack(packId, message) {
  return async (dispatch, getState) => {

    const pack = await dispatch(loadPack(packId));
    // const publicThread = await dispatch(loadThread(pack.threadId));

    // Add recipients to the pack and pack thread.

    const packRecipients = [];
    const addPackRecipientIfNeeded = recipient => {
      for(const i in packRecipients) {
        const isSameEmail = !!recipient.email && packRecipients[i].email === recipient.email;
        const isSameUser = !!recipient.userId && packRecipients[i].userId === recipient.userId;
        const isSameTalent = !!recipient.talentId && packRecipients[i].talentId === recipient.talentId;
        const recipientExists = isSameEmail || isSameUser || isSameTalent;
        if(recipientExists) {
          return;
        }
      }
      packRecipients.push(recipient);
    };
    for(const i in pack.recipients) {
      addPackRecipientIfNeeded(pack.recipients[i]);
    }
    // for(const i in publicThread.recipients) {
    //   addPackRecipientIfNeeded(publicThread.recipients[i]);
    // }
    for(const i in message.recipients) {
      addPackRecipientIfNeeded(message.recipients[i]);
    }

    // await dispatch(updateThread(publicThread.id, {recipients:packRecipients}));
    await dispatch(updatePack(packId, {recipients:packRecipients}));

    const recipients = message.recipients;
    const bccSender = message.bccSender || null;
    const ccSender = message.ccSender || null;
    const subject = message.subject;
    const body = message.body;
    const attachments = message.attachments;

    // If recipients have agencyIds, add pack to `agencyPacks`.

    for(const i in recipients) {
      const agencyId = getProperty(message, `recipients[${i}].agencyId`, null);
      if(agencyId) {
        firebase.database().ref(`agencyPacks/${agencyId}/${packId}`).set(Date.now());
      }
    }


    // Build a thread for each recipient.

    for(const i in recipients) {
      let thread  = await dispatch(addThread({recipients:{[i]:recipients[i]}, subject, packId}));
      const threadPreview = {
        userId: getState().user.id || null,
        agencyId: recipients[i].agencyId || null,
        dateUpdated: Date.now(),
        subject: message.subject || "",
        recipients: `${pack.name}`,
        preview: message.body
          .replace(/<(?:.|\n)*?>/gm, ' ')
          .replace(/\s\s+/g, ' ')
          .replace(/&nbsp;/g, ' ')
          .trim()
          .slice(0, 100),
      };
      firebase.database().ref(`packThreads/${packId}/${thread.id}`).set(threadPreview);
      dispatch(addMessage(thread.id, {body, subject, bccSender, ccSender, attachments}));
    }

    return true;
  };
}

export function requestPack(request) {
  return async (dispatch, getState) => {

    let { agencies, message, data } = request;

    // create the package
    const pack = await dispatch( addPack(data, {createThread:false}) );

    // build the recipients
    const recipients = {};
    for(const agencyId of agencies) {
      const agency = await dispatch( loadAgency(agencyId) );
      if(!agency) {
        continue;
      }

      recipients[agencyId] = {
        agencyId: agencyId,
        name: agency.contactName || "",
        email: agency.contactEmail || "",
      };
    }

    // todo: add email (invite) recipients

    const packUrl = `https://${window.location.hostname}/packs/${pack.id}`;
    const invitePayload = {
      recipients: recipients,
      bccSender: false,
      ccSender: false,
      subject: pack.name,
      body: `
        ${message}
        <br/>
        <div>
          <a href="${packUrl}">${packUrl}</a>
        </div>
      `
    };

    dispatch( inviteToPack(pack.id, invitePayload) );

    return pack;
  };
}




/* Messaging */

export function addThread(data) {
  return async (dispatch, getState) => {
    const {
      recipients,
      subject,
      packId,
      isSilent,
      ...rest
    } = data;

    // Add the thread.

    const thread = {
      /* deprecated */ date: moment().valueOf(),
      dateCreated: moment().valueOf(),
      dateUpdated: moment().valueOf(),
      recipients: recipients || {},
      subject: subject || "",
      createdByUserId: getState().user.id,
      agencyId: getState().user.agencyId,
      ...rest
    };

    if(!!packId) {
      const pack = await dispatch(loadPack(packId));
      thread.pack = {
        id: pack.id,
        name: pack.name,
      };
    }

    const ref = firebase.database().ref(`threads`).push(thread);
    thread.id = ref.key;

    dispatch({
      type: 'ADD_THREAD',
      threadId: thread.id,
      thread,
    });


    // If the thread is labeled is "silent", just return it here.

    if(isSilent === true) {
      return thread;
    }


    // Add the thread to this user.

    dispatch(addThreadToUser(thread.id));


    // Add the thread to all recipients. And if those recipients/talents
    // are managed by a user, add message to that user.

    for(const recipientId in recipients) {
      const recipient = recipients[recipientId];
      if(recipient.userId) {
        dispatch(addThreadToUser(thread.id, recipient.userId));
      }

      if(recipient.talentId) {
        dispatch(addThreadToTalent(thread.id, recipient.talentId));

        const talent = await dispatch(loadTalent(recipient.talentId));
        if(talent.userId) {
          dispatch(addThreadToUser(thread.id, talent.userId));
        }
      }
    }


    // If a packId is given, add the thread to that pack.

    if(!!packId) {
      dispatch(addThreadToPack(thread.id, packId));
    }

    return thread;
  };
}

export function addThreadToTalent(threadId, talentId) {
  return async (dispatch, getState) => {
    const thread = await dispatch(loadThread(threadId));

    firebase.database().ref(`talentThreads/${talentId}/${threadId}`).set({
      /* deprecated */ date: thread.dateUpdated,
      dateUpdated: thread.dateUpdated,
      subject: thread.subject || "",
      recipients: Object.keys(thread.recipients)
        .map(id => thread.recipients[id])
        .map(recipient => recipient.name).join(', '),
    });

    dispatch({
      type: "ADD_TALENT_THREAD",
      thread,
      talentId,
    });
  };
}

export function addThreadToUser(threadId, userId) {
  return async (dispatch, getState) => {
    userId = userId || getState().user.id;
    const thread = await dispatch(loadThread(threadId));

    firebase.database().ref(`userThreads/${userId}/${threadId}`).set({
      /* depricated */ date: thread.dateUpdated,
      dateUpdated: thread.dateUpdated,
      subject: thread.subject || "",
      recipients: Object.keys(thread.recipients)
        .map(id => thread.recipients[id])
        .map(recipient => recipient.name).join(', '),
    });

    dispatch({
      type: "ADD_USER_THREAD",
      thread,
      userId,
    });
  };
}

export function addThreadToPack(threadId, packId) {
  return async (dispatch, getState) => {

    if(!threadId || !packId) {
      return;
    }

    // add thread to pack

    const thread = await dispatch(loadThread(threadId));

    firebase.database().ref(`threads/${threadId}/packId`).set(packId);
    thread.packId = packId;

    firebase.database().ref(`packThreads/${packId}/${threadId}`).set({
      /* depricated */ date: thread.dateUpdated,
      agencyId: thread.agencyId || getState().user.agencyId || null,
      userId: getState().user.id || null,
      dateUpdated: thread.dateUpdated,
      subject: thread.subject || "",
      recipients: Object.keys(thread.recipients)
        .map(id => thread.recipients[id])
        .map(recipient => recipient.name).join(', '),
    });

    dispatch({
      type: "ADD_PACK_THREAD",
      threadId,
      thread,
      packId,
    });
  };
}

export function addThreadToThread(threadId, parentThreadId) {
  return async (dispatch, getState) => {
    const thread = await dispatch(loadThread(threadId));

    firebase.database().ref(`threads/${threadId}/parentThreadId`).set(parentThreadId);
    firebase.database().ref(`threadThreads/${parentThreadId}/${threadId}`).set({
      date: thread.date,
      subject: thread.subject || "",
      recipients: Object.keys(thread.recipients)
        .map(id => thread.recipients[id])
        .map(recipient => recipient.name).join(', '),
    });

    dispatch({
      type: "ADD_THREAD_THREAD",
      thread,
      parentThreadId,
    });
  };
}

export function removeThread(threadId) {
  return async (dispatch, getState) => {

    const thread = await dispatch(loadThread(threadId));

    if(!thread) {
      dispatch({
        type: 'REMOVE_THREAD_FAILURE',
        threadId,
        code: 'removeThread/01',
        message: "Thread not found",
      });
      return;
    }

    if(!!thread.pack) {
      dispatch(removeThreadFromPack(thread.id, thread.pack.id));
    }

    if(thread.createdByUserId) {
      dispatch(removeThreadFromUser(thread.id, thread.createdByUserId));
    }

    for(const recipientId in thread.recipients) {
      const recipient = thread.recipients[recipientId];
      if(recipient.userId) {
        dispatch(removeThreadFromUser(thread.id, recipient.userId));
      }
      if(recipient.talentId) {
        dispatch(removeThreadFromTalent(thread.id, recipient.talentId));
        const talent = await dispatch(loadTalent(recipient.talentId));
        if(talent.userId) {
          dispatch(removeThreadFromUser(thread.id, talent.userId));
        }
      }
    }

    firebase.database().ref(`threads/${threadId}`).remove();

    dispatch({
      type: 'REMOVE_THREAD',
      threadId,
    });
  };
}

export function removeThreadFromTalent(threadId, talentId) {
  return dispatch => {

    firebase.database().ref(`talentThreads/${talentId}/${threadId}`).remove();

    dispatch({
      type: 'REMOVE_TALENT_THREAD',
      threadId,
      talentId,
    });
  };
}

export function removeThreadFromUser(threadId, userId) {
  return dispatch => {

    firebase.database().ref(`userThreads/${userId}/${threadId}`).remove();

    dispatch({
      type: 'REMOVE_USER_THREAD',
      threadId,
      userId,
    });
  };
}

export function removeThreadFromPack(threadId, packId) {
  return dispatch => {

    firebase.database().ref(`packThreads/${packId}/${threadId}`).remove();

    dispatch({
      type: 'REMOVE_PACK_THREAD',
      threadId,
      packId,
    });
  };
}

export function updateThread(threadId, update) {
  return (dispatch, getState) => {
    let thread = getState().threads.all[threadId];
    if(!thread) {
      dispatch({
        type: 'UPDATE_THREAD_FAILURE',
        code: 'updateThread/001',
        message: 'Thread not found.',
        threadId,
      });
      return;
    }

    firebase.database().ref(`threads/${threadId}`).update(update);
    thread = Object.assign({}, thread, update);

    dispatch({
      type: 'UPDATE_THREAD',
      threadId,
      thread,
    });

    return thread;
  };
}

export function addMessage(threadId, body, /*deprecated, use body as options instead -> */ subject="", isSilent=false) {
  return async (dispatch, getState) => {
    const state = getState();

    // let subject = "";
    // let isSilent = false;
    let bccSender = null;
    let ccSender = null;
    let showSendingSnackbar = true;
    let files = [];
    let sms = false;
    let from = { name:'Skybolt' };

    if(isObject(body)) {
      subject = body.subject || "";
      files = body.attachments || [];
      isSilent = body.isSilent || false;
      if(body.from) {
        from = body.from;
      }
      if(body.bccSender) {
        bccSender = body.bccSender;
      }
      if(body.ccSender) {
        ccSender = body.ccSender;
      }
      showSendingSnackbar = body.showSnackbar !== false;
      sms = body.sms || false;
      body = body.body || "";
    }

    let attachments = [];
    if(files.length > 0) {
      if(showSendingSnackbar) {
        dispatch(showSnackbar("Uploading Attachments", 0));
      }

      for(const i in files) {
        const attachment = files[i];

        await new Promise((resolve, reject) => {
          let formData = new FormData();
          formData.append("upload_preset", cloudinaryUploadPreset);
          formData.append("file", attachment.file);

          const xhr = new XMLHttpRequest();

          xhr.onreadystatechange = function(e) {

            // not done yet
            if(xhr.readyState !== 4) {
              return;
            }

            // something went wrong.
            if(xhr.status !== 200) {
              console.log('error', xhr);
              reject();
              return;
            }

            var res = JSON.parse(xhr.responseText);
            if(!res || !res.public_id) {
              reject(res);
              return;
            }

            attachments.push({
              cloudinary_id:res.public_id,
              type: res.resource_type || null,
              format: res.format || null,
              name: attachment.file.name || "File",
            });
            resolve();
          };

          xhr.open("post", `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/auto/upload`);
          xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

          xhr.send(formData);

          // reader.onerror = function (error) {
          //   dispatch({
          //     type: 'UPLOAD_ATTACHMENT_FAILURE',
          //     code: 'addMessage/002',
          //     message: 'Uplaod media failure.',
          //     error,
          //   });
          //   reject(error);
          // };

          // reader.readAsDataURL(attachment.file);
        });
      }
    }

    if(showSendingSnackbar) {
      dispatch(showSnackbar("Sending", 0));
    }

    // Get sender data.
    // If the logged in user is one of the recipients, put them as the from.

    const now = moment().valueOf();
    const thread = await dispatch(loadThread(threadId));
    const parentThreadId = thread.parentThreadId || null;
    const lastMessage = thread.lastMessage;

    if(state.user.isLoggedIn) {
      const userId = state.user.id;
      const user = state.users.all[userId];

      from = find(thread.recipients, recipient => {
        // is this recipient the logged in user?
        if(recipient.userId === userId) {
          return true;
        }
        // does this user "own" the recipient talent?
        if(recipient.talentId && user.talents && user.talents[recipient.talentId]) {
          return true;
        }
        return false;
      });

      // add this user if they're not already a recipient.
      if(!from) {
        from = {
          userId: userId,
          name: `${user.firstName || ""} ${user.lastName || ""}`.trim() || "Skybolt",
        };

        const recipientRef = firebase.database().ref(`threads/${threadId}/recipients`).push(from);
        const recipientId = recipientRef.key;
        thread.recipients[recipientId] = from;

        dispatch( addThreadToUser(threadId) );
      }
    }


    // If the previous message contained SMS data, continue that trend.

    if(lastMessage && lastMessage.sms !== false) {
      const div = document.createElement('div');
      div.innerHTML = body;
      sms = div.innerText.trim().replace(/\n/g, '').replace(/\s+/g, ' ');
    }


    // create the message

    const message = {
      date: now,
      threadId,
      parentThreadId,
      bccSender,
      ccSender,
      from,
      body,
      sms,
      subject,
      isSilent,
      attachments,
    };

    if(lastMessage) {
      let quoteInfo = "";
      if(!!lastMessage.date) {
        quoteInfo += `On ${moment(lastMessage.date).utcOffset('-0700').format("dddd, MMMM Do YYYY, h:mm:ss a")}`;
      }
      if(!!lastMessage.from && !!lastMessage.from.name) {
        quoteInfo += ` ${lastMessage.from.name} wrote`;
      }
      message.quote = `
        <p>${quoteInfo}:</p>
        <blockquote>${lastMessage.body}</blockquote>
      `;
    }

    // update the thread with the latest message

    firebase.database().ref(`threads/${threadId}/hasMessages`).transaction((count) => count ? count + 1 : 1);
    firebase.database().ref(`threads/${threadId}/dateUpdated`).set(now);
    firebase.database().ref(`threads/${threadId}/lastMessage`).set(message);

    thread.hasMessages = (thread.hasMessages || 0) + 1;
    thread.dateUpdated = now;
    thread.lastMessage = message;

    dispatch({
      type: "UPDATE_THREAD",
      threadId,
      thread,
    });

    // if the thread has a pack id, update the pack with the latest message
    if(thread.packId) {
      dispatch(updatePack(thread.packId, {lastMessage:message}));
    }

    // if the thread has a parent thread, update that as well.

    if(parentThreadId) {
      const parentThread = await dispatch(loadThread(parentThreadId));

      firebase.database().ref(`threads/${parentThread.id}/hasMessages`).transaction((count) => count ? count + 1 : 1);
      firebase.database().ref(`threads/${parentThread.id}/dateUpdated`).set(now);
      firebase.database().ref(`threads/${parentThread.id}/lastMessage`).set(message);

      parentThread.hasMessages = (parentThread.hasMessages || 0) + 1;
      parentThread.dateUpdated = now;
      parentThread.lastMessage = message;

      dispatch({
        type: "UPDATE_THREAD",
        threadId: parentThread.id,
        thread: parentThread,
      });
    }

    // push the message

    const ref = firebase.database().ref(`messages`).push(message);
    message.id = ref.key;

    dispatch({
      type: 'ADD_MESSAGE',
      threadId,
      parentThreadId,
      messageId: message.id,
      message,
    });

    if(showSendingSnackbar) {
      dispatch(showSnackbar("Message sent"));
    }

    return message;
  };
}

export function messagePackTalents(packId, message) {
  return async (dispatch, getState) => {

    dispatch({
      type: "MESSAGE_PACK_TALENTS_REQUEST",
      packId,
    });

    const state = getState();
    const userId = state.user.id;

    let attachments = [];
    if(message.attachments.length > 0) {
      try {
        dispatch(showSnackbar("Uploading Attachments", 0));

        for(const i in message.attachments) {
          const attachment = message.attachments[i];

          await new Promise((resolve, reject) => {

            let formData = new FormData();
            formData.append('upload_preset', cloudinaryUploadPreset);
            formData.append("file", attachment.file);

            var xhr = new XMLHttpRequest();

            xhr.onreadystatechange = function(e) {
              // not done yet
              if(xhr.readyState !== 4) {
                return;
              }

              // something went wrong.
              if(xhr.status !== 200) {
                console.log('error', xhr);
                reject();
                return;
              }

              var res = JSON.parse(xhr.responseText);
              if(!res || !res.public_id) {
                reject(res);
                return;
              }

              attachments.push({
                cloudinary_id:res.public_id,
                type: res.resource_type || null,
                format: res.format || null,
                name: attachment.file.name || null,
              });
              resolve();
            };

            xhr.open("post", `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/auto/upload`);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

            xhr.send(formData);
          });
        }

        message.attachments = attachments;
      }
      catch(err) {
        dispatch(showSnackbar("Error uploading attachments."));
        return;
      }
    }

    firebase.database().ref(`transactions`).push({
      type:"MESSAGE_PACK_TALENTS",
      packId,
      message,
      userId,
      date: Date.now(),
      source: 'WEB'
    });

    dispatch(showSnackbar("Sent"));

  };
}

export function messageSelectedPackTalents(packId, message) {
  return async (dispatch, getState) => {

    dispatch({
      type: "MESSAGE_SELECTED_PACK_TALENTS_REQUEST",
      packId,
    });

    const state = getState();
    const userId = state.user.id;

    let attachments = [];
    if(message.attachments && message.attachments.length > 0) {
      dispatch(showSnackbar("Uploading Attachments", 0));

      for(const i in message.attachments) {
        const attachment = message.attachments[i];

        await new Promise((resolve, reject) => {

          let formData = new FormData();
          formData.append('upload_preset', cloudinaryUploadPreset);
          formData.append("file", attachment.file);

          var xhr = new XMLHttpRequest();

          xhr.onreadystatechange = function(e) {
            // not done yet
            if(xhr.readyState !== 4) {
              return;
            }

            // something went wrong.
            if(xhr.status !== 200) {
              console.log('error', xhr);
              reject();
              return;
            }

            var res = JSON.parse(xhr.responseText);
            if(!res || !res.public_id) {
              reject(res);
              return;
            }

            attachments.push({
              cloudinary_id:res.public_id,
              type: res.resource_type || null,
              format: res.format || null,
              name: attachment.file.name || null,
            });
            resolve();
          };

          xhr.open("post", `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/auto/upload`);
          xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

          xhr.send(formData);
        });
      }

      message.attachments = attachments;
    }

    let ref = firebase.database().ref(`transactions`).push({
      type:"MESSAGE_SELECTED_PACK_TALENTS",
      packId,
      message,
      userId,
      date: Date.now(),
      source: 'WEB'
    });

    return new Promise((resolve, reject) => {
      let handler = snap => {
        let val = snap.val();
        if(val.dateCompleted) {
          dispatch(showSnackbar("Sent"));
          ref.off('value', handler);
          resolve(true);
        }
        if(val.errors) {
          dispatch(showSnackbar("Error"));
          reject();
        }
      };
      ref.on('value', handler);
    });
  };
}

export function messageScheduledTalents(packId, message) {
  return async (dispatch, getState) => {

    const state = getState();
    const userId = state.user.id;

    let attachments = [];
    if(message.attachments && message.attachments.length > 0) {
      dispatch(showSnackbar("Uploading Attachments", 0));

      for(const i in message.attachments) {
        const attachment = message.attachments[i];

        await new Promise((resolve, reject) => {

          let formData = new FormData();
          formData.append('upload_preset', cloudinaryUploadPreset);
          formData.append("file", attachment.file);

          var xhr = new XMLHttpRequest();

          xhr.onreadystatechange = function(e) {
            // not done yet
            if(xhr.readyState !== 4) {
              return;
            }

            // something went wrong.
            if(xhr.status !== 200) {
              console.log('error', xhr);
              reject();
              return;
            }

            var res = JSON.parse(xhr.responseText);
            if(!res || !res.public_id) {
              reject(res);
              return;
            }

            attachments.push({
              cloudinary_id:res.public_id,
              type: res.resource_type || null,
              format: res.format || null,
              name: attachment.file.name || null,
            });
            resolve();
          };

          xhr.open("post", `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/auto/upload`);
          xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

          xhr.send(formData);
        });
      }

      message.attachments = attachments;
    }

    firebase.database().ref(`transactions`).push({
      type:"MESSAGE_SCHEDULED_PACK_TALENTS",
      packId,
      message,
      userId,
      date: Date.now(),
      source: 'WEB'
    });

    dispatch(showSnackbar("Sent"));
  };
}

export function messageAgencyTalentSearch(message) {
  return async (dispatch, getState) => {
    dispatch({
      type: "MESSAGE_AGENCY_SEARCH_TALENTS_REQUEST",
      message,
    });

    const state = getState();
    const userId = state.user.id;

    const agency = state.agencies.all[ state.user.agencyId ];
    const agencyTalentFields = agency.talentFields;
    const talentFields = {...baseTalentFields, ...agencyTalentFields};

    const search = state.agencyTalents.search;
    const filters = Object.keys(talentFields)
      .filter(fieldId => !!search[fieldId] && !isEmpty(search[fieldId]))
      .map(fieldId => {

        // change "age" into date ranges
        if(fieldId === 'age') {
          const years = search[fieldId];
          const ranges = years.map(year => {
            year = Number(year);
            const from = moment().subtract(year+1, 'years').valueOf();
            const to = moment().subtract(year, 'years').valueOf();
            return `dob:${from} TO ${to}`;
          });

          return `(${ranges.join(' OR ')})`;
        }

        // add any other fields, separated by OR
        const type = talentFields[fieldId].type;
        if(isArray(search[fieldId]) && search[fieldId].length > 0) {
          return `(${
            search[fieldId]
              .map(val => {
                if(isString(val)) {
                  val = `'${val}'`;
                }

                return type === 'select' ? `${fieldId}:${val}` :
                       type === 'number' || type === 'range' ? `${fieldId}:${parseFloat(val)} TO ${parseFloat(val)+parseFloat((talentFields[fieldId].step || 1)-.01)}` :
                       type === 'text' ? `${fieldId}:${val}` :
                       type === 'date' & val === 'valid' ? `${fieldId} > ${Date.now()}` :
                       type === 'date' & val === 'expired' ? `${fieldId} < ${Date.now()}` :
                       `${fieldId}=${val}`;
              })
              .join(' OR ')
          })`;
        }

        return type === 'select' ? `${fieldId}:${search[fieldId]}` :
               type === 'number' || type === 'range' ? `${fieldId}:${parseFloat(search[fieldId])} TO ${parseFloat(search[fieldId])+parseFloat((talentFields[fieldId].step || 1)-.01)}` :
               type === 'text' ? `${fieldId}:${search[fieldId]}` :
               type === 'date' & search[fieldId] === 'valid' ? `${fieldId} > ${Date.now()}` :
               type === 'date' & search[fieldId] === 'expired' ? `${fieldId} < ${Date.now()}` :
               `${fieldId}=${search[fieldId]}`;
      })

      // only show talents from the user's agency
      .concat(`agencyId:${state.user.agencyId}`)

      // if requiring talent approval, show only approved talent
      .concat(!!agency.requireTalentApproval ? "approved:true" : null)

      // add active division for agent users
      .concat(
        state.user.permissions.canPack && state.user.activeDivision
          ? `divisions:'${state.user.activeDivision}'`
          : []
      )

      // add talent tags
      .concat(
        search.tags.map(tag => `tags:'${tag}'`)
      )

      // add media tags
      .concat(
        search.mediaTags.map(tag => `mediaTags:'${tag}'`)
      )

      // filter by status
      .concat(
        search.status.map(status =>
          status === 'active'
            ? "(status:'PAID' OR status:'TRIAL')"
            : `status:'${status.toUpperCase()}'`
        )
        .join(' OR ')
      )

      .filter(el => !!el)
      .join(' AND ');

    const algoliaSearch = {
      query: search.query,
      filters
    };


    if(message.attachments.length > 0) {
      let attachments = [];
      dispatch(showSnackbar("Uploading Attachments", 0));

      for(const i in message.attachments) {
        const attachment = message.attachments[i];

        await new Promise((resolve, reject) => {

          let formData = new FormData();
          formData.append('upload_preset', cloudinaryUploadPreset);
          formData.append("file", attachment.file);

          var xhr = new XMLHttpRequest();

          xhr.onreadystatechange = function(e) {
            // not done yet
            if(xhr.readyState !== 4) {
              return;
            }

            // something went wrong.
            if(xhr.status !== 200) {
              console.log('error', xhr);
              reject();
              return;
            }

            var res = JSON.parse(xhr.responseText);
            if(!res || !res.public_id) {
              reject(res);
              return;
            }

            attachments.push({
              cloudinary_id:res.public_id,
              type: res.resource_type || null,
              format: res.format || null,
              name: attachment.file.name || null,
            });
            resolve();
          };

          xhr.open("post", `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/auto/upload`);
          xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

          xhr.send(formData);
        });
      }

      message.attachments = attachments;
    }

    firebase.database().ref(`transactions`).push({
      type:"MESSAGE_SEARCH_TALENTS",
      search: algoliaSearch,
      message,
      userId,
      date: Date.now(),
      source: 'WEB'
    });

    dispatch(showSnackbar("Sent"));

    // await dispatch(showSnackbar("Sending", 0));

    // const url = `${firebaseFunctionsURL}/messageTalentSearch`;
    // const options = {
    //   method: 'post',
    //   headers: {'content-type':'application/json'},
    //   body: JSON.stringify({
    //     search: algoliaSearch,
    //     from,
    //     message,
    //   })
    // };

    // try {
    //   const thread = await fetch(url, options).then(res => res.json());
    //   dispatch({
    //     type: "MESSAGE_AGENCY_SEARCH_TALENTS",
    //     thread,
    //   });
    //   dispatch(showSnackbar("Sent"));
    //   return thread;
    // }

    // catch(error) {
    //   dispatch({
    //     type: 'MESSAGE_AGENCY_SEARCH_TALENTS_FAILURE',
    //     code: 'messageAgencyTalentSearch/01',
    //     error: error,
    //     message: "Message failed. Please try again.",
    //   });
    //   dispatch(showSnackbar("Message failed. Please try again."));
    //   return Promise.reject(error);
    // }
  };
}

export function messageTalentSearch(message) {
  return async (dispatch, getState) => {
    dispatch({
      type: "MESSAGE_SEARCH_TALENTS_REQUEST",
      message,
    });

    const state = getState();
    const userId = state.user.id;

    const algoliaSearch = {
      query: state.talents.search.query,
      filters: state.talents.search.filters,
    };

    if(message.attachments.length > 0) {
      let attachments = [];
      dispatch(showSnackbar("Uploading Attachments", 0));

      for(const i in message.attachments) {
        const attachment = message.attachments[i];

        await new Promise((resolve, reject) => {

          let formData = new FormData();
          formData.append('upload_preset', cloudinaryUploadPreset);
          formData.append("file", attachment.file);

          var xhr = new XMLHttpRequest();

          xhr.onreadystatechange = function(e) {
            // not done yet
            if(xhr.readyState !== 4) {
              return;
            }

            // something went wrong.
            if(xhr.status !== 200) {
              console.log('error', xhr);
              reject();
              return;
            }

            var res = JSON.parse(xhr.responseText);
            if(!res || !res.public_id) {
              reject(res);
              return;
            }

            attachments.push({
              cloudinary_id:res.public_id,
              type: res.resource_type || null,
              format: res.format || null,
              name: attachment.file.name || null,
            });
            resolve();
          };

          xhr.open("post", `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/auto/upload`);
          xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

          xhr.send(formData);
        });
      }

      message.attachments = attachments;
    }

    firebase.database().ref(`transactions`).push({
      type:"MESSAGE_SEARCH_TALENTS",
      search: algoliaSearch,
      message,
      userId,
      date: Date.now(),
      source: 'WEB'
    });

    dispatch(showSnackbar("Sent"));

  };
}

export function downloadAgencyTalentSearch(message) {
  return async (dispatch, getState) => {
    dispatch({
      type: "DOWNLOAD_AGENCY_SEARCH_TALENTS_REQUEST",
      message,
    });

    const state = getState();

    const agencyId = state.user.agencyId;
    const agency = state.agencies.all[ agencyId ];
    const agencyTalentFields = agency.talentFields;
    const talentFields = {...baseTalentFields, ...agencyTalentFields};

    const search = state.agencyTalents.search;
    const filters = Object.keys(talentFields)
      .filter(fieldId => !!search[fieldId] && !isEmpty(search[fieldId]))
      .map(fieldId => {

        // change "age" into date ranges
        if(fieldId === 'age') {
          const years = search[fieldId];
          const ranges = years.map(year => {
            year = Number(year);
            const from = moment().subtract(year+1, 'years').valueOf();
            const to = moment().subtract(year, 'years').valueOf();
            return `dob:${from} TO ${to}`;
          });

          return `(${ranges.join(' OR ')})`;
        }

        // add any other fields, separated by OR
        const type = talentFields[fieldId].type;
        if(isArray(search[fieldId]) && search[fieldId].length > 0) {
          return `(${
            search[fieldId]
              .map(val => {
                if(isString(val)) {
                  val = `'${val}'`;
                }

                return type === 'select' ? `${fieldId}:${val}` :
                       type === 'number' || type === 'range' ? `${fieldId}:${parseFloat(val)} TO ${parseFloat(val)+parseFloat((talentFields[fieldId].step || 1)-.01)}` :
                       type === 'text' ? `${fieldId}:${val}` :
                       type === 'date' & val === 'valid' ? `${fieldId} > ${Date.now()}` :
                       type === 'date' & val === 'expired' ? `${fieldId} < ${Date.now()}` :
                       `${fieldId}=${val}`;
              })
              .join(' OR ')
          })`;
        }

        return type === 'select' ? `${fieldId}:${search[fieldId]}` :
               type === 'number' || type === 'range' ? `${fieldId}:${parseFloat(search[fieldId])} TO ${parseFloat(search[fieldId])+parseFloat((talentFields[fieldId].step || 1)-.01)}` :
               type === 'text' ? `${fieldId}:${search[fieldId]}` :
               type === 'date' & search[fieldId] === 'valid' ? `${fieldId} > ${Date.now()}` :
               type === 'date' & search[fieldId] === 'expired' ? `${fieldId} < ${Date.now()}` :
               `${fieldId}=${search[fieldId]}`;
      })

      // only show talents from the user's agency
      .concat(`agencyId:${state.user.agencyId}`)

      // if requiring talent approval, show only approved talent
      .concat(!!agency.requireTalentApproval ? "approved:true" : null)

      // add active division for agent users
      .concat(
        state.user.permissions.canPack && state.user.activeDivision
          ? `divisions:'${state.user.activeDivision}'`
          : []
      )

      // add talent tags
      .concat(
        search.tags.map(tag => `tags:'${tag}'`)
      )

      // add media tags
      .concat(
        search.mediaTags.map(tag => `mediaTags:'${tag}'`)
      )

      // filter by status
      .concat(
        search.status.map(status =>
          status === 'active'
            ? "(status:'PAID' OR status:'TRIAL')"
            : `status:'${status.toUpperCase()}'`
        )
        .join(' OR ')
      )

      .filter(el => !!el)
      .join(' AND ');

    const algoliaSearch = {
      query: search.query,
      filters
    };

    // console.log('searching', JSON.stringify(algoliaSearch));

    await dispatch(showSnackbar("Downloading"));

    const url = `${firebaseFunctionsURL}/searchTalentsCSV`;
    const options = {
      method: 'post',
      headers: {'content-type':'application/json'},
      body: JSON.stringify({
        agencyId: agencyId,
        search: algoliaSearch
      })
    };

    try {
      const csv = await fetch(url, options).then(res => res.text());
      var blob=new Blob([csv]);
      var link=document.createElement('a');
      link.href=window.URL.createObjectURL(blob);
      link.download="skybolt_searchresults.csv";
      link.click();

      dispatch({
        type: "DOWNLOAD_AGENCY_SEARCH_TALENTS",
      });
      return csv;
    }

    catch(error) {
      dispatch({
        type: 'DOWNLOAD_AGENCY_SEARCH_TALENTS_FAILURE',
        code: 'downloadAgencyTalentSearch/01',
        error: error,
        message: "Download failed. Please try again.",
      });
      dispatch(showSnackbar("Download failed. Please try again."));
      return Promise.reject(error);
    }
  };
}

let threadSubscription = {};
export function subscribeToThread(threadId) {
  return async (dispatch, getState) => new Promise(async (resolve, reject) => {
    if(threadSubscription[threadId]) {
      const thread = await dispatch( loadThread(threadId) );
      resolve(thread);
      return thread;
    }

    threadSubscription[threadId] = firebase.database().ref(`threads/${threadId}`)
      .on('value', snapshot => {
        if(!snapshot.exists()) {
          const error = {
            type: 'LOAD_THREAD_FAILURE',
            code: 'subscribeToThread/001',
            message: "Thread doesn't exist.",
            threadId
          };

          dispatch(error);
          reject(error) ;
          return;
        }

        const thread = {...snapshot.val(), id:snapshot.key};
        resolve(thread);
        dispatch({type:'LOAD_THREAD', threadId, thread});
      });
  });
}
export function unsubscribeFromThread(threadId) {
  return (dispatch, getState) => {
    firebase.database().ref(`threads/${threadId}`).off('value', threadSubscription[threadId]);
    threadSubscription[threadId] = null;
  };
}

let threadsSubscription = {};
export function subscribeToThreads(userId, limit=75) {
  return async (dispatch, getState) => {

    userId = userId || getState().user.id;
    if(!userId) {
      dispatch({
        type: 'LOAD_USER_THREADS_FAILURE',
        userId,
        code: 'subscribeToThreads/001',
        message: 'No user given'
      });
      return;
    }

    dispatch({
      type: 'LOAD_USER_THREADS_REQUEST',
      userId,
    });

    dispatch(unsubscribeFromThreads(userId));

    threadsSubscription[userId] = firebase.database().ref(`userThreads/${userId}`)
      .orderByChild('dateUpdated').limitToLast(limit)
      .on('value', async snapshot => {
        if(!snapshot.exists()) {
          dispatch({
            type: 'LOAD_USER_THREADS_FAILURE',
            code: 'subscribeToThreads/002',
            message: 'No threads exist.',
            userId,
          });
          return {};
        }

        const userThreads = snapshot.val();
        const threads = {};

        for(var threadId in userThreads) {
          if(threadId) {
            try {
              const thread = await dispatch( subscribeToThread(threadId) );
              if(thread) {
                threads[threadId] = thread;
              }
            }
            catch(error) {
              console.warn('subscribeToThreads', error);
            }
          }
        }

        dispatch({type:'LOAD_USER_THREADS_SUCCESS', userId, threads});
      });
  };
}
export function unsubscribeFromThreads(userId) {
  return (dispatch, getState) => {
    userId = userId || getState().user.id;
    if(!userId) {
      dispatch({
        type: 'LOAD_THREADS_FAILURE',
        userId,
        code: 'unsubscribeToThreads/001',
        message: 'No user given'
      });
      return;
    }

    firebase.database().ref(`userThreads/${userId}`).off('value', threadsSubscription[userId]);
    threadsSubscription[userId] = null;
  };
}

let threadMessagesCallback = {};
export function subscribeToThreadMessages(threadId) {
  return (dispatch, getState) => {
    if(!threadId) {
      return {};
    }
    threadMessagesCallback[threadId] = firebase.database().ref('messages')
      .orderByChild('threadId').equalTo(threadId)
      .on('value', snapshot => {

        if(!snapshot.exists()) {
          return {};
        }

        const messages = snapshot.val();
        for(const id in messages) {
          messages[id].id = id;
        }

        dispatch({type:'LOAD_MESSAGES_SUCCESS', threadId, messages});
      });
  };
}
export function unsubscribeFromThreadMessages(threadId) {
  return dispatch => {
    firebase.database().ref('messages').off('value', threadMessagesCallback[threadId]);
    threadMessagesCallback[threadId] = null;
  };
}

let threadThreadsCallback = {};
export function subscribeToThreadThreads(parentThreadId) {
  return async (dispatch, getState) => {

    threadThreadsCallback[parentThreadId] = firebase.database().ref(`threadThreads/${parentThreadId}`)
      .on('value', async snapshot => {
        if(!snapshot.exists()) {
          return {};
        }

        const threadThreads = snapshot.val();
        const threads = {};

        await Promise.all(
          Object.keys(threadThreads)
            .map(async threadId => threads[threadId] = await dispatch( subscribeToThread(threadId, true) ))
        );

        // self repair any bad data
        for(const id in threads) {
          if(!threads[id]) {
            delete threads[id];
            console.warn(`Removing bad data threadThreads/${parentThreadId}/${id} from ${threads}`);
            // firebase.database().ref(`threadThreads/${parentThreadId}/${id}`).remove();
          }
        }

        dispatch({type:'LOAD_THREAD_THREADS_SUCCESS', parentThreadId, threads});
      });
  };
}
export function unsubscribeFromThreadThreads(threadId) {
  return (dispatch, getState) => {
    firebase.database().ref(`threadThreads/${threadId}`).off('value', threadThreadsCallback[threadId]);
    threadThreadsCallback[threadId] = null;
  };
}

let packThreadsSubscription = {};
export function subscribeToPackThreads(packId) {
  return async (dispatch, getState) => {
    let ref = firebase.database().ref(`packThreads/${packId}`);

    packThreadsSubscription[packId] = ref.on('value', async snapshot => {
      if(!snapshot.exists()) {
        dispatch({
          type: 'LOAD_PACK_THREADS_FAILURE',
          code: 'loadPackThreads/001',
          message: 'No threads exist.',
          packId,
        });
        return {};
      }

      const packThreads = snapshot.val();
      const threads = {};
      const userId = getState().user.id;
      const userAgencyId = getState().user.agencyId;

      await Promise.all(
        Object.keys(packThreads)
          .filter(key => {
            const threadAgencyId = packThreads[key].agencyId;
            const threadUserId = packThreads[key].userId;
            if(!threadAgencyId) {
              return true;
            }
            if(!!userAgencyId && !!threadAgencyId && threadAgencyId === userAgencyId) {
              return true;
            }
            if(!!userId && !!threadUserId && userId === threadUserId) {
              return true;
            }
            if(getProperty(getState(), "user.permissions.canAdminAgencies", false)) {
              return true;
            }
            return false;
          })
          .map(async threadId => threads[threadId] = await dispatch( subscribeToThread(threadId) ))
      );

      dispatch({type:'LOAD_PACK_THREADS_SUCCESS', packId, threads});
    });
  };
}
export function unsubscribeFromPackThreads(packId) {
  return dispatch => {
    if(packThreadsSubscription[packId]) {
      firebase.database().ref(`packThreads/${packId}`).off('value', packThreadsSubscription[packId]);
      packThreadsSubscription[packId] = null;
    }
  };
}

let talentThreadsSubscription = {};
export function subscribeToTalentThreads(talentId, limit=30) {
  return async (dispatch, getState) => {

    const state = getState();
    const userId = state.user.id;
    const user = state.users.all[userId];
    const isOwnTalent = !!user.talents && !!user.talents[talentId];
    const agencyId = user.agencyId;

    talentThreadsSubscription[talentId] = firebase.database()
      .ref(`talentThreads/${talentId}`)
      .orderByChild('dateUpdated').limitToLast(limit)
      .on('value', async snapshot => {
        if(!snapshot.exists()) {
          dispatch({
            type: 'LOAD_TALENT_THREADS_FAILURE',
            code: 'subscribeToTalentThreads/001',
            message: 'No threads exist.',
            talentId,
          });
          return {};
        }

        const talentThreads = snapshot.val();
        const threads = {};

        // For each `talentThread`, subscribe to the real thread.
        // Also filter threads by agencyId.
        const threads$ = Object.keys(talentThreads || {})
          .map(async threadId => {
            const thread = await dispatch( subscribeToThread(threadId) );
            if(isOwnTalent || user.permissions.canAdminTalent || thread.agencyId === agencyId) {
              threads[threadId] = thread;
            }
            return thread;
          });

        if(threads$.length > 0) {
          await Promise.all(threads$);
        }

        // self repair any bad data
        for(const id in threads) {
          if(!threads[id]) {
            delete threads[id];
            // console.warn(`Cleaning bad data talentThreads/${talentId}/${id}`);
            // firebase.database().ref(`talentThreads/${packId}/${id}`).remove();
          }
        }

        dispatch({type:'LOAD_TALENT_THREADS_SUCCESS', talentId, threads});
      });
  };
}
export function unsubscribeFromTalentThreads(talentId) {
  return dispatch => {
    firebase.database().ref(`packThreads/${talentId}`).off('value', talentThreadsSubscription[talentId]);
    talentThreadsSubscription[talentId] = null;
  };
}

let messageReceiptsSubscription = {};
export function subscribeToMessageReceipts(threadId) {
  return async (dispatch, getState) => {
    dispatch({type:'LOAD_MESSAGE_RECEIPTS_REQUEST', threadId});

    let receipts = getState().messageReceipts.byThread[threadId] || {};

    messageReceiptsSubscription[threadId] = firebase.database().ref(`messageReceipts`)
      .orderByChild('threadId').equalTo(threadId)
      .on('value', snapshot => {
        receipts = {...receipts, ...snapshot.val()};
        dispatch({type:'LOAD_MESSAGE_RECEIPTS', threadId, receipts});
      });

    messageReceiptsSubscription['parent'+threadId] = firebase.database().ref(`messageReceipts`)
      .orderByChild('parentThreadId').equalTo(threadId)
      .on('value', snapshot => {
        receipts = {...receipts, ...snapshot.val()};
        dispatch({type:'LOAD_MESSAGE_RECEIPTS', threadId, receipts});
      });

  };
}
export function unsubscribeToMessageReceipts(threadId) {
  return dispatch => {
    firebase.database().ref(`messageReceipts`).off('value', messageReceiptsSubscription[threadId]);
    messageReceiptsSubscription[threadId] = null;

    firebase.database().ref(`messageReceipts`).off('value', messageReceiptsSubscription['parent'+threadId]);
    messageReceiptsSubscription['parent'+threadId] = null;
  };
}

export function loadPackThreads(packId, refresh=false) {
  return async (dispatch, getState) => {
    const state = getState();
    let threads = state.threads.byPack[packId];
    if(!!threads && !refresh) {
      return threads;
    }

    dispatch({type:'LOAD_PACK_THREADS_REQUEST', packId});

    threads = await firebase.database().ref(`packThreads/${packId}`)
      .once('value').then(async snapshot => {
        if(!snapshot.exists()) {
          dispatch({
            type: 'LOAD_PACK_THREADS_FAILURE',
            code: 'loadPackThreads/001',
            message: 'No threads exist.',
            packId,
          });
          return {};
        }

        const packThreads = snapshot.val();
        const threads = {};

        await Promise.all(
          Object.keys(packThreads).map(async threadId => threads[threadId] = await dispatch( loadThread(threadId) ))
        );

        // self repair any bad data
        // for(const id in threads) {
        //   if(!threads[id]) {
        //     delete threads[id];
        //     firebase.database().ref(`packThreads/${packId}/${id}`).remove();
        //   }
        // }

        return threads;
      });

    if(!threads) {
      threads = {};
    }

    dispatch({type:'LOAD_PACK_THREADS_SUCCESS', packId, threads});

    return threads;
  };
}

export function replyToPack(packId, message) {
  return async (dispatch, getState) => {

    const pack = await dispatch(loadPack(packId));
    const threads = await dispatch(loadPackThreads(packId));

    let thread = find(threads, thread => {
      return !!find(thread.recipients, recipient =>
        recipient.email === message.from.email
      );
    });

    if(!thread) {
      thread = await dispatch(addThread({
        packId,
        subject: pack.name,
        recipients: {
          0: message.from,
          1: {
            name: pack.userName,
            userId: pack.userId,
          }
        }
      }));
    }

    return await dispatch(addMessage(thread.id, message));

  };
}




/* Events */

export function loadEvent(eventId) {
  return async (dispatch, getState) => {
    let event = getState().events.all[eventId];
    if(event) {
      return event;
    }

    dispatch({type:"LOAD_EVENT_REQUEST", eventId});

    return firebase.database().ref(`events/${eventId}`)
      .once('value').then(snapshot => {
        if(!snapshot.exists()) {
          dispatch({
            type:"LOAD_EVENT_FAILURE",
            eventId,
            code: 'loadEvent/001',
            message:"Event not found.",
          });
          return null;
        }
        const event = snapshot.val();

        dispatch({
          type:"LOAD_EVENT",
          eventId,
          event: {...event, id:eventId},
        });

        return event;
      });
  };
}

export function loadTalentEvents(talentId, filters) {
  return async (dispatch, getState) => {

    dispatch({
      type: 'LOAD_TALENT_EVENTS_REQUEST',
      talentId,
      filters,
    });

    const facetFilters = [];

    facetFilters.push(`talentId:${talentId.replace(/^[-]/g, "\\-")}`);
    // facetFilters.push(`talentId:${talentId}`);

    let numericFilters = [];
    if(filters && filters.fromDate && filters.toDate) {
      numericFilters = [`date<${filters.toDate}`, `endDate>${filters.fromDate}`];
    }

    const search = {
      hitsPerPage: "1000",
      attributesToRetrieve: "*",
      typoTolerance: false,
      facets: `["talentId"]`,
      facetFilters: JSON.stringify([facetFilters]),
      numericFilters: JSON.stringify(numericFilters),
    };

    const events = {};
    const results = await eventsIndex.search(search);

    results.hits.forEach((hit, i) => {
      events[hit.objectID] = {
        ...baseEvent,
        ...hit,
        id: hit.objectID,
      };
    });

    dispatch({
      type: 'LOAD_TALENT_EVENTS_SUCCESS',
      events,
      talentId,
      filters,
    });

    return events;
  };
}

export function loadPackEvents(packId) {
  return async (dispatch, getState) => {
    if(!packId) {
      // console.log("no pack Id");
      return;
    }

    const state = getState();
    let events = state.events.byPack[packId];
    if(!!events) {
      return events;
    }



    dispatch({type:"LOAD_PACK_EVENTS_REQUEST", packId});

    events = await firebase.database().ref('events')
      .orderByChild('packId').equalTo(packId)
      .once('value').then(snapshot => {
        if(!snapshot.exists()) {
          return {};
        }
        const events = snapshot.val();
        for(const eventId in events) events[eventId].id = eventId;
        return events;
      });


    // If there's a parent pack ID. Load sibling events.

    const parentPackId = getProperty(state, `packs.all[${packId}].parentPackId`, null);
    if(parentPackId) {
      const siblingEvents = await firebase.databae().ref(`events`)
        .orderByChild('parentPackId').equalTo(parentPackId)
        .once('value').then(snapshot => {
          if(!snapshot.exists()) {
            return {};
          }
          const events = snapshot.val();
          for(const eventId in events) events[eventId].id = eventId;
          return events;
        });
      events = {...events, ...siblingEvents};
    }

    dispatch({
      type:"LOAD_PACK_EVENTS_SUCCESS",
      packId,
      events,
    });

    return events;
  };
}

export function loadAgencyEvents(agencyId) {
  return async (dispatch, getState) => {
    let events = getState().events.byAgency[agencyId];
    if(!!events) {
      return events;
    }

    dispatch({type:"LOAD_AGENCY_EVENTS_REQUEST", agencyId});

    events = await firebase.database().ref('events')
      .orderByChild('agencyId').equalTo(agencyId)
      .once('value').then(snapshot => {
        if(!snapshot.exists()) {
          return {};
        }
        const events = snapshot.val();
        for(const eventId in events) events[eventId].id = eventId;
        return events;
      });

    dispatch({
      type:"LOAD_AGENCY_EVENTS_SUCCESS",
      agencyId,
      events,
    });

    return events;
  };
}

export function loadUserEvents(filters={}) {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = state.user.id;
    const user = state.users.all[userId];

    if(!user) {
      dispatch({
        type: 'LOAD_USER_EVENTS_SUCCESS',
        events: {},
        userId,
      });
      return;
    }

    const facetFilters = [];

    if(user.permissions && user.permissions.canAgencyCalendar) {
      facetFilters.push(`agencyId:${user.agencyId}`);
    }
    else {
      Object.keys(user.talents)
        .filter(talentId => {
          const talent = user.talents[talentId];
          if(talent.status === 'CANCELED' || talent.status === 'EXPIRED' || talent.status === 'DELETED') {
            return false;
          }
          return true;
        })
        .forEach(talentId => {
          facetFilters.push(`talentId:${talentId.replace(/^[-]/g, "\\-")}`);
        });
    }

    if(isEmpty(facetFilters)) {
      dispatch({
        type: 'LOAD_USER_EVENTS_SUCCESS',
        events: {},
        userId,
      });

      return {};
    }

    let numericFilters = [];
    if(filters && filters.fromDate && filters.toDate) {
      numericFilters = [`date<${filters.toDate}`, `endDate>${filters.fromDate}`];
    }

    const search = {
      hitsPerPage: "1000",
      attributesToRetrieve: "*",
      typoTolerance: false,
      facets: `["agencyId","packId","talentId"]`,
      facetFilters: JSON.stringify([facetFilters]),
      numericFilters: JSON.stringify(numericFilters),
    };

    const events = {};
    const results = await eventsIndex.search(search);

    results.hits.forEach((hit, i) => {
      events[hit.objectID] = {
        ...baseEvent,
        ...hit,
        id: hit.objectID,
      };
    });

    dispatch({
      type: 'LOAD_USER_EVENTS_SUCCESS',
      events,
      userId,
    });

    return events;
  };
}

export function addPackEvent(packId, talentId, date, timeslotId=null, type="CASTING") {
  return async (dispatch, getState) => {
    const talent = getState().talents.all[talentId];
    const pack = await dispatch(loadPack(packId));


    // Create event.

    const event = {
      timeslotId,
      type,
      parentPackId: pack.parentPackId || null,
      packId: packId,
      packName: pack.name,
      talentId: talentId,
      agencyId: talent.agencyId,
      firstName: talent.firstName || "",
      lastName: talent.lastName || "",
      image: talent.image,
      status: 'pending',
      date,
      endDate: date,
      utcOffset: moment().utcOffset(),
    };

    const ref = firebase.database().ref('events').push(event);
    const eventId = ref.key;
    event.id = eventId;

    const eventsCount = pack.eventsCount ? pack.eventsCount + 1 : 1;
    dispatch(updatePack(packId, {eventsCount}));



    // Set upcoming event.

    const upcomingEvents = await firebase.database().ref(`talents/${event.talentId}/upcomingEvents`)
      .once('value').then(s => s.val() || {});

    upcomingEvents[eventId] = {
      id: event.id,
      packName: event.packName || null,
      date: event.date,
      endDate: event.endDate,
      notes: event.notes || null,
    };

    for(let id in upcomingEvents) {
      if(upcomingEvents[id].date < Date.now()) {
        delete upcomingEvents[id];
      }
    }

    firebase.database().ref(`talents/${event.talentId}/upcomingEvents`).set(upcomingEvents);


    // Dispatch action.

    dispatch({
      type: 'ADD_EVENT',
      packId,
      agencyId: talent.agencyId,
      talentId,
      eventId,
      event,
    });

    eventsIndex.clearCache();

    return event;
  };
}

export function addTalentEvent(talentId, data) {
  return async (dispatch, getState) => {

    if(!data.date) {
      return;
    }

    const talent = await dispatch( loadTalent(talentId) );

    const date = data.date ? data.date.valueOf() : null;
    const endDate = data.endDate ? data.endDate.valueOf() : date;
    const event = {
      talentId: talentId,
      agencyId: talent.agencyId || "",
      firstName: talent.firstName || "",
      lastName: talent.lastName || "",
      image: talent.image || "",
      date,
      endDate,
      allDay: data.allDay,
      notes: data.notes || "",
      utcOffset: moment().utcOffset(),
    };

    const ref = firebase.database().ref('events').push(event);
    const eventId = ref.key;
    event.id = eventId;

    // Set upcoming event for talent.

    const upcomingEvents = await firebase.database().ref(`talents/${event.talentId}/upcomingEvents`)
      .once('value').then(s => s.val() || {});

    upcomingEvents[eventId] = {
      id: event.id,
      packName: event.packName || null,
      date: event.date,
      endDate: event.endDate,
      notes: event.notes || null,
    };

    for(let id in upcomingEvents) {
      if(upcomingEvents[id].date < Date.now()) {
        delete upcomingEvents[id];
      }
    }

    firebase.database().ref(`talents/${event.talentId}/upcomingEvents`).set(upcomingEvents);


    // Dispatch

    dispatch({
      type: 'ADD_EVENT',
      agencyId: talent.agencyId,
      talentId,
      eventId,
      event,
    });

    eventsIndex.clearCache();

    return event;
  };
}

export function removeEvent(eventId) {
  return async (dispatch, getState) => {

    if(!eventId) {
      return;
    }

    const event = await dispatch(loadEvent(eventId));
    if(event.talentId) {
      firebase.database().ref(`talents/${event.talentId}/upcomingEvents/${eventId}`).remove();
    }
    firebase.database().ref(`events/${eventId}`).remove();

    dispatch({
      type: 'REMOVE_EVENT',
      eventId,
      event,
    });

    return eventId;
  };
}

export function updateEvent(eventId, update) {
  return async (dispatch, getState) => {
    let event = await dispatch(loadEvent(eventId));
    if(!event) {
      dispatch({
        type: 'UPDATE_EVENT_FAILURE',
        code: 'updateEvent/001',
        message: 'Event not found.',
        eventId,
      });
      return;
    }

    event = {...event, ...update};
    if(event.talentId) {

      const upcomingEvents = await firebase.database().ref(`talents/${event.talentId}/upcomingEvents`)
        .once('value').then(s => s.val() || {});

      upcomingEvents[eventId] = {
        id: eventId,
        packName: event.packName || null,
        date: event.date,
        endDate: event.endDate,
        notes: event.notes || null,
      };

      for(let id in upcomingEvents) {
        if(upcomingEvents[id].endDate ? upcomingEvents[id].endDate < Date.now() : upcomingEvents[id].date < Date.now()) {
          delete upcomingEvents[id];
        }
      }

      firebase.database().ref(`talents/${event.talentId}/upcomingEvents`).set(upcomingEvents);

    }

    firebase.database().ref(`events/${eventId}`).update(update);

    dispatch({
      type: 'UPDATE_EVENT',
      eventId,
      event,
    });

    return event;
  };
}

export function subscribeToPackEvents(packId) {
  return (dispatch, getState) => {
    if(!packId) {
      // console.log("no pack Id");
      return;
    }

    dispatch({type:"LOAD_PACK_EVENTS_REQUEST", packId});

    const handler = snapshot => {
      if(!snapshot.exists()) {
        return {};
      }
      const events = snapshot.val();
      for(const eventId in events) events[eventId].id = eventId;

      dispatch({
        type:"LOAD_PACK_EVENTS_SUCCESS",
        packId,
        events,
      });
    };

    firebase.database().ref('events')
      .orderByChild('packId').equalTo(packId)
      .on('value', handler);

    return () => {
      firebase.database().ref('events').off('value', handler);
    };
  };
}

export function subscribeToChildPackEvents(packId) {
  return async (dispatch, getState) => {
    if(!packId) {
      // console.warn('No pack provided to subscribeToChildPackEvents.');
      return;
    }

    const childPacks = await dispatch( loadChildPacks(packId) );

    const unsubs = childPacks.map(childPack => {
      return dispatch( subscribeToPackEvents(childPack.id) );
    });

    return () => {
      unsubs.forEach(unsubscribe => unsubscribe());
    };
  };
}





/* Users */

let unsubscribeFromUserAgency = null;
function subscribeToUserAgency(userId) {
  return dispatch => {
    if(!userId) {
      return;
    }
    const ref = firebase.database().ref(`users/${userId}/agencyId`);
    const handler = snap => {
      dispatch({
        type: "SET_AGENCY",
        userId: userId,
        agencyId: snap.val(),
      });
      dispatch(searchAgencyTalents());
    };

    ref.on('value', handler);
    return unsubscribeFromUserAgency = () => ref.off('value', handler);
  };
}

let unsubscribeFromUserDivision = null;
function subscribeToUserDivision(userId) {
  return dispatch => {
    if(!userId) {
      return;
    }
    const ref = firebase.database().ref(`users/${userId}/activeDivision`);
    const handler = snap => {
      dispatch({
        type: "SET_ACTIVE_DIVISION",
        userId: userId,
        division: snap.val(),
      });
      dispatch(searchAgencyTalents());
    };

    ref.on('value', handler);
    return unsubscribeFromUserDivision = () => ref.off('value', handler);
  };
}



export function loadUser(userId, options={refresh:false}) {
  return async (dispatch, getState) => {
    if(!userId) {
      dispatch({
        type:"LOAD_USER_FAILURE",
        code: 'loadUser/001',
        message: "Must supply a user.",
        userId,
      });
      return;
    }

    let user = getState().users.all[userId];
    if(user && !options.refresh) {
      return user;
    }

    dispatch({type:'LOAD_USER_REQUEST', userId});

    user = await firebase.database()
      .ref(`users/${userId}`)
      .once('value').then(snapshot => ({...baseUser, ...snapshot.val(), id:userId}));

    if(!user) {
      dispatch({
        type:"LOAD_USER_FAILURE",
        code: 'loadUser/002',
        message: "User not found",
        userId,
      });
      return null;
    }

    if(user.agencyId) {
      await dispatch(loadAgency(user.agencyId));
    }

    if(user.agencyIds) {
      for(const id of user.agencyIds) {
        await dispatch(loadAgency(id));
      }
    }

    if(user.talents) {
      await Promise.all(
        Object.keys(user.talents).map(async talentId => await dispatch(loadTalent(talentId)))
      );
    }

    dispatch({type:'LOAD_USER_SUCCESS', user});

    return user;
  };
}

export function loadLoggedInUser() {
  return async (dispatch, getState) => {
    const userId = firebase.auth().currentUser.uid;
    const user = await dispatch(loadUser(userId));

    if(!user) {
      dispatch({type:'LOGOUT', user});
      return null;
    }

    // if(user.agencyId) {
    //   await dispatch(loadAgency(user.agencyId));
    // }
    // dispatch(subscribeToUnreadMessages(userId));

    // if(user.permissions.canApproveTalents) {
    //   dispatch( subscribeToUnapprovedTalents(user.agencyId) );
    // }

    // if(user.permissions.canApproveMedia) {
    //   dispatch( subscribeToUnapprovedMedia(user.agencyId) );
    // }

    // if(user.talents) {
    //   for(const talentId in user.talents) {
    //     await dispatch(loadTalent(talentId));
    //   }
    // }

    let agencyId = user.agencyId;

    // If this user has permission to admin agencies, what the user's `agencyId`
    // for changes.
    if(user.permissions.canAdminAgencies) {
      dispatch(subscribeToUserAgency(userId));
    }

    if(user.permissions.canPack) {
      dispatch(subscribeToUserDivision(userId));
    }


    if(!agencyId && user.talents) {
      const state = getState();
      const userTalents = Object.keys(user.talents)
        .map(talentId => state.talents.all[talentId])
        .filter(talent => !!talent);

      const firstTalent = userTalents[0] || {};
      agencyId = firstTalent.agencyId;
    }

    dispatch({type:'LOGIN', user, agencyId});

    return user;
  };
}

export function register(info={}, invitation) {
  return async (dispatch, getState) => {

    // Log user out if already logged in.

    if(getState().user.isLoggedIn) {
      await dispatch( logout() );
    }


    // Load the agency.

    let userData = (invitation && invitation.user) || {};
    let role = info.role || userData.role || "talent";
    let agencyId = info.agencyId;
    if(!agencyId && invitation && invitation.user.agencyId) {
      agencyId = invitation.user.agencyId;
    }
    if(role === 'talent' && !agencyId) {
      agencyId = "skybolt";
    }

    let agency = null;
    if(agencyId) {
      agency = await dispatch( loadAgency(agencyId) );
      if(!agency) {
        dispatch({
          type: 'REGISTER_ERROR',
          code: 'register/002',
          message: "Agency not found.",
          info,
          invitation,
        });
        return;
      }
    }


    // This agency doesn't allow registration.

    if(!invitation && agency && !agency.allowTalentRegistration) {
      dispatch({
        type: 'REGISTER_ERROR',
        code: 'register/005',
        message: `${agency.name} doesn't allow open registration. Contact your agent for an invitation.`,
        info,
        invitation,
      });
      return;
    }


    // Update or create the talent if needed.

    let talentId = null;
    let talent = null;

    if(role === 'talent') {

      // Get the talent from the invitation or create it.
      if(invitation && invitation.talentId) {
        talentId = invitation.talentId;
      }
      else {
        let status;
        let requireSubscription;
        if(agency.requireTalentSubscription || agency.requireUserSubscription) {
          status = 'INCOMPLETE';
          requireSubscription = true;
        }
        else {
          status = 'PAID';
          requireSubscription = false;
        }

        talent = await dispatch( addTalent({agencyId, status, requireSubscription}) );
        talentId = talent.id;
      }

      // Add registration info to the talent.
      let talentUpdate = {
        agencyId,
        firstName: info.firstName || "",
        lastName: info.lastName || "",
        requireShowWelcome: true,
      };

      if(info.email) {
        talentUpdate.emails = {
          'registration': {
            email: info.email || "",
            label: "Home",
          },
        };
      }

      if(info.phone) {
        talentUpdate.phones = {
          'registration': {
            number: info.phone || "",
            label: "Home",
          },
        };
      }

      if(info.address) {
        talentUpdate = {...talentUpdate, ...info.address};
      }

      await dispatch( updateTalent(talentId, talentUpdate) );

    } // fi role === talent


    // Create a new user. If it fails, clean up any data created above.

    let user = {
      firstName: info.firstName,
      lastName: info.lastName,
      email: info.email,
      phone: info.phone,
      role: role,
      ...info.address,
    };

    if(role === 'agent' && !!agencyId) {
      user.agencyId = agencyId;
    }

    if(invitation && invitation.user) {
      user = {...invitation.user, ...user};
    }

    if(role === 'talent') {
      user.permissions = {canOwnTalents:true};
      user.talents = {[talentId]: Date.now()};
    }

    if(role === 'castingdirector') {
      user.permissions = {
        canViewPacks:true,
      };
      if(agencyId) {
        user.agencyIds = [agencyId];
      }
    }

    if(document.referrer) {
      user.referrer = document.referrer;
    }

    try {
      const res = await firebase.auth().createUserWithEmailAndPassword(info.email, info.password);
      user.id = res.user.uid;
      firebase.database().ref(`users/${user.id}`).set(user);

      // todo: why isn't this working?

      if(talentId) {
        dispatch( updateTalent(talentId, {userId:user.id, error:null}));
      }

      if(invitation && invitation.id) {
        firebase.database().ref(`invitations/${invitation.id}/error`).remove();
        firebase.database().ref(`invitations/${invitation.id}/dateUsed`).set(Date.now());
      }
    }
    catch(error) {
      if(talentId) {
        firebase.database().ref(`talents/${talentId}/error`).set(error);
      }
      if(invitation && invitation.id) {
        firebase.database().ref(`invitations/${invitation.id}/error`).set(error);
      }
      dispatch({
        type:"REGISTER_ERROR",
        code: 'register/003/'+error.code,
        message: error.message,
      });
      return;
    }


    // Load all new user data.

    await dispatch( loadLoggedInUser() );


    // Send welcome emails.

    if(role === 'talent' && !!talentId) {
      firebase.database().ref('transactions').push({type:'SEND_TALENT_WELCOME', talentId, date:Date.now()});
    }


    // Success

    dispatch({
      type:"REGISTER_SUCCESS",
      user,
    });

    return user;
  };
}

export function login(email, password, invitationId) {
  return async (dispatch, getState) => {
    if(getState().user.isLoggedIn) {
      await dispatch( logout() );
    }

    let res;
    try {
      res = await firebase.auth().signInWithEmailAndPassword(email, password);
    }
    catch(error) {
      dispatch({
        type:"LOGIN_ERROR",
        ...error,
      });
      return {success:false, ...error};
    }

    if(invitationId) {
      const userId = res.user.uid;
      const invitation = await dispatch( loadInvitation(invitationId) );

      if(userId) {
        const role = getProperty(invitation, 'user.role', 'talent');

        if(role === 'talent' && invitation.talentId) {
          firebase.database().ref(`users/${userId}/talents/${invitation.talentId}`).set(Date.now());
          firebase.database().ref(`users/${userId}/activeTalent`).set(invitation.talentId);
        }

        if(role === 'castingdirector') {
          const invitationAgencyIds = getProperty(invitation, 'user.agencyIds', []);
          const userAgencyIds = await firebase.database().ref(`users/${userId}/agencyIds`).once('value').then(snap => snap.val() || []);
          const agencyIds = uniq([...userAgencyIds, ...invitationAgencyIds]);
          if(userId && agencyIds) {
            firebase.database().ref(`users/${userId}/agencyIds`).set(agencyIds);
          }
        }
      }

      firebase.database().ref(`invitations/${invitationId}/dateUsed`).set(Date.now());
    }

    await dispatch( loadLoggedInUser() );

    return {success:true, ...res};
  };
}

export function resetPassword(email) {
  return async dispatch => {
    return await firebase.auth()
      .sendPasswordResetEmail(email)
      .then(res => {
        return {success:true, ...res};
      })
      .catch(error => {
        return {success:false, ...error};
      });
  };
}

export function logout() {
  return (dispatch, getState) => {
    if(!!firebase.auth().currentUser) {
      firebase.auth().signOut();
    }

    if(unsubscribeFromUserAgency) {
      unsubscribeFromUserAgency();
    }

    if(unsubscribeFromUserDivision) {
      unsubscribeFromUserDivision();
    }
    dispatch( unsubscribeFromUnapprovedMedia() );
    dispatch( unsubscribeFromThreads() );

    dispatch({type:'LOGOUT'});
  };
}

export function loadUsers() {
  return async (dispatch, getState) => {

    const users = await firebase.database().ref("users")
      .once('value').then(async snapshot => {
        if(!snapshot.exists()) {
          return {};
        }

        let users = snapshot.val();
        for(const id in users) {
          const agencyId = users[id].agencyId;
          const agencyName = await firebase.database()
            .ref(`agencies/${agencyId}/name`)
            .once('value')
            .then(snapshot => snapshot.val());

          users[id] = {...baseUser, ...users[id], agencyName, id};
        }
        return users;
      });


    dispatch({
      type: "LOAD_USERS_SUCCESS",
      users,
    });
  };
}

export function setActiveDivision(division) {
  return (dispatch, getState) => {
    const state = getState();
    const userId = state.user.id;
    const user = state.users.all[userId];

    firebase.database().ref(`users/${userId}/activeDivision`).set(division);

    dispatch({
      type: 'UPDATE_USER',
      userId,
      user: {
        ...user,
        activeDivision: division,
      }
    });

    dispatch({
      type: "SET_ACTIVE_DIVISION",
      division,
    });

    dispatch(searchAgencyTalents());
  };
}

export function setAgency(agencyId) {
  return async (dispatch, getState) => {
    const userId = getState().user.id;
    if(!userId) {
      return;
    }
    if(!agencyId) {
      return;
    }

    firebase.database().ref(`users/${userId}/agencyId`).set(agencyId);

    dispatch({
      type: "SET_AGENCY",
      userId: getState().user.id,
      agencyId,
    });

    await dispatch( loadAgency(agencyId) );
    dispatch( setActiveDivision(null) );
    // dispatch( loadUserEvents(null) );
    // dispatch( subscribeToUnapprovedMedia(agencyId) );
    // dispatch( subscribeToUnapprovedTalents(agencyId) );
  };
}

export function searchUsers(query="", filters={}) {
  return async (dispatch, getState) => {
    const state = getState();
    if(state.users.isSearching) {
      return;
    }

    dispatch({type:"SEARCH_USERS_REQUEST", query, filters});

    let filterString = [];

    if(!!filters.role && filters.role.length > 0) {
      if(!isArray(filters.role)) {
        filters.role = [filters.role];
      }
      const str = filters.role
        .map(role => `role:"${role.toLowerCase()}"`)
        .join(" OR ");
      filterString.push(`(${str})`);
    }

    if(!!filters.agencyId && filters.agencyId.length > 0) {
      const id = filters.agencyId;
      const str = `(agencyId:"${id}" OR agencyIds:"${id}")`;
      filterString.push(str);
    }

    filterString = filterString.join(" AND ");

    const results = await usersIndex.search({
      query,
      filters: filterString,
      length: 50,
      offset: 0
    });

    const users = {};
    results.hits.forEach(user => users[user.objectID] = {...user, id:user.objectID});

    dispatch({
      type: "SEARCH_USERS",
      query,
      filters,
      users,
    });

    return users;
  };
}

export function updateUser(userId, update) {
  return (dispatch, getState) => {
    const state = getState();
    const isLoggedInUser = state.user.id === userId;
    const user = state.users.all[userId];

    if(!userId) {
      dispatch({
        type: 'UPDATE_USER_ERROR',
        code: "updateUser/001",
        message: "User not found."
      });
      return null;
    }

    if(!user) {
      dispatch({
        type: 'UPDATE_USER_ERROR',
        code: "updateUser/002",
        message: "User not found."
      });
      return null;
    }

    firebase.database().ref(`users/${userId}`).update(update);
    const updatedUser = Object.assign({}, user, update);

    if(isLoggedInUser) {
      dispatch({
        type: 'UPDATE_LOGGED_IN_USER',
        user: updatedUser,
      });
    }

    dispatch({
      type: 'UPDATE_USER',
      user: updatedUser,
    });

    return updatedUser;
  };
}

export function updateUserPassword(oldPassword, newPassword) {
  return async (dispatch, getState) => {
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);

    return user
      .reauthenticateWithCredential(cred)
      .then(() => {

        return user.updatePassword(newPassword)
          .then(() => {
            dispatch({
              type: 'UPDATE_USER_PASSWORD'
            });
          })
          .catch((error)  => {
            dispatch({
              type: 'UPDATE_USER_PASSWORD_ERROR',
              code: 'updateUserPassword/002',
              error: error
            });
            return error;
          });

      })
      .catch(error => {
        dispatch({
          type: 'UPDATE_USER_PASSWORD_ERROR',
          code: 'updateUserPassword/001',
          error: error
        });
      });
  };
}

export function setUserActiveTalent(activeTalent) {
  return (dispatch, getState) => {

    if(!activeTalent) {
      dispatch({
        type: 'UPDATE_USER_ERROR',
        code: "setUserActiveTalent/001",
        message: "Talent not found."
      });
      return null;
    }

    const userId = getState().user.id;
    dispatch( updateUser(userId, {activeTalent}) );

    return activeTalent;
  };
}

export function getGoogleAuthURL(userId) {
  return (dispatch, getState) => {
    if(!userId) {
      userId = getState().user.id;
    }
    if(!userId) {
      return;
    }

    return fetch(`${firebaseFunctionsURL}/googleoauth2url?uid=${userId}`)
      .then(res => res.text());
  };
}

export function linkGoogle(query) {
  return async (dispatch, getState) => {
    const url = `${firebaseFunctionsURL}/googleoauth2callback${query}`;
    try {
      await fetch(url).then(res => res.json());
      await dispatch(loadUser( getState().user.id, {refresh:true} ));
    }
    catch(error) {
      console.log(error);
      return null;
    }
  };
}

export function removeUserFromAgency(userId, agencyId) {
  return (dispatch, getState) => {
    const state = getState();
    const isLoggedInUser = state.user.id === userId;
    const user = state.users.all[userId];

    if(!userId) {
      dispatch({
        type: 'UPDATE_USER_ERROR',
        code: "updateUser/001",
        message: "User not found."
      });
      return null;
    }

    if(!user) {
      dispatch({
        type: 'UPDATE_USER_ERROR',
        code: "updateUser/002",
        message: "User not found."
      });
      return null;
    }

    let update = {};
    if(user.agencyId === agencyId) {
      update.agencyId = null;
    }
    if(includes(user.agencyIds, agencyId)) {
      update.agencyIds = without(user.agencyIds, agencyId);
    }

    firebase.database().ref(`users/${userId}`).update(update);
    const updatedUser = Object.assign({}, user, update);

    usersIndex.clearCache();

    if(isLoggedInUser) {
      dispatch({
        type: 'UPDATE_LOGGED_IN_USER',
        user: updatedUser,
      });
    }

    dispatch({
      type: 'UPDATE_USER',
      user: updatedUser,
    });

    return updatedUser;
  };
}


/* Media */

export function loadTalentMedia(talentId) {
  return async (dispatch, getState) => {
    dispatch({type:"LOAD_TALENT_MEDIA_REQUEST", talentId});

    const media = await firebase.database().ref('media')
      .orderByChild('talentId').equalTo(talentId)
      .once('value').then(snapshot => {
        if(!snapshot.exists()) {
          return {};
        }
        const media = snapshot.val();
        for(const id in media) {
          media[id].id = id;
        }
        return media;
      });

    dispatch({
      type:"LOAD_TALENT_MEDIA_SUCCESS",
      talentId,
      media,
    });

    return media;
  };
}

function loadTalentMediaIfNeeded(talentId) {
  return async (dispatch, getState) => {
    let media = getState().media.byTalent[talentId];
    if(!!media) {
      return media;
    }

    return dispatch(loadTalentMedia(talentId));
  };
}

export function subscribeToTalentMedia(talentId) {
  return dispatch => {

    if(!talentId) {
      return;
    }

    const valueHandler = snap => {
      if(!snap.exists()) {
        return {};
      }
      const media = snap.val();
      for(const id in media) {
        media[id].id = id;
      }

      dispatch({
        type:"LOAD_TALENT_MEDIA_SUCCESS",
        talentId,
        media,
      });
    };

    let ref = firebase.database().ref('media')
      .orderByChild('talentId').equalTo(talentId);

    ref.on('value', valueHandler);

    return () => {
      // console.log('unsubscribing from talent media', talentId, ref);
      ref.off('value', valueHandler);
    };
  };
}

export function loadMedia(mediaId) {
  return async (dispatch, getState) => {
    let media = getState().media.all[mediaId];
    if(media) {
      return media;
    }

    media = await firebase.database().ref(`media/${mediaId}`)
      .once('value').then(snapshot => ({...snapshot.val(), id:snapshot.key}));

    dispatch({
      type: 'LOAD_MEDIA',
      mediaId,
      media,
    });

    return media;
  };
}

export function uploadTalentMedia(talentId, file, data, onProgress) {
  return (dispatch, getState) => new Promise((resolve, reject) => {

    let formData = new FormData();
    formData.append('upload_preset', cloudinaryUploadPreset);
    formData.append("file", file);

    var xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function(e) {
      // not done yet
      if(xhr.readyState !== 4) {
        return;
      }

      // something went wrong.
      if(xhr.status !== 200) {
        console.log('error', xhr);
        reject();
        return;
      }

      var res = JSON.parse(xhr.responseText);
      if(!res || !res.public_id) {
        reject(res);
        return;
      }

      const state = getState();
      const agencyId = getProperty(state, `talents.all[${talentId}].agencyId`, null);

      const media = {
        talentId,
        date: moment().valueOf(),
        divisions: [],
        tags: [],
        order: -1,
        approved: false,
        active: true,
        ...data,
        cloudinary_id:res.public_id,
        type: res.resource_type,
        format: res.format,
      };

      const mediaId = firebase.database()
        .ref('media')
        .push(media)
        .key;

      media.id = mediaId;

      // Flag media for approval if there's an agency here.

      if(agencyId) {
        firebase.database()
          .ref(`mediaForApproval/${agencyId}/${mediaId}`)
          .set(true);
      }

      // Dispatch and sort.

      dispatch({
        type: 'ADD_MEDIA',
        talentId,
        mediaId: mediaId,
        media: media,
      });

      dispatch(sortMedia(talentId));

      resolve(media);
    };

    if(onProgress) {
      xhr.upload.addEventListener("progress", e => {
        var progress = Math.round((e.loaded * 100.0) / e.total);
        onProgress(progress);
      });
    }

    xhr.open("post", `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/auto/upload`);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

    xhr.send(formData);
  });
}

export function linkTalentMedia(talentId, url, data) {
  return async (dispatch, getState) => {
    const state = getState();

    // Data check.

    const agencyId = getProperty(state, `talents.all[${talentId}].agencyId`, null);


    // Create new media item.

    const newMedia = {
      url: url,
      type: 'link',
      format: url.indexOf('vimeo') > -1 ? 'vimeo' : 'youtube',
      talentId,
      date: moment().valueOf(),
      divisions: [],
      tags: [],
      order: -1,
      approved: false,
      active: true,
      ...data,
    };
    const mediaId = firebase.database().ref('media').push(newMedia).key;
    newMedia.id = mediaId;


    // Flag media for approval

    firebase.database().ref(`mediaForApproval/${agencyId}/${mediaId}`).set(true);


    // Dispatch and sort.

    dispatch({
      type: 'ADD_MEDIA',
      talentId,
      mediaId: mediaId,
      media: newMedia,
    });

    dispatch(sortMedia(talentId));

    return newMedia;
  };
}

export function addMedia(talentId, data) {
  return async (dispatch, getState) => {
    const agencyId = getState().talents.all[talentId].agencyId;

    if(!agencyId) {
      dispatch({
        type: 'ADD_MEDIA_FAILURE',
        talentId,
        data,
      });
      return;
    }

    // const media = await dispatch(loadTalentMediaIfNeeded(talentId));
    // Object.keys(media).forEach((id, i) => {
    //   const item = media[id];
    //   dispatch( updateMedia(id, {order: item.order ? item.order+1 : i+1}) );
    // });

    const newMedia = {
      talentId,
      date: moment().valueOf(),
      divisions: [],
      tags: [],
      order: -1,
      approved: false,
      active: true,
      ...data,
    };
    const mediaId = firebase.database().ref('media').push(newMedia).key;
    newMedia.id = mediaId;

    firebase.database().ref(`mediaForApproval/${agencyId}/${mediaId}`).set(true);

    dispatch({
      type: 'ADD_MEDIA',
      talentId,
      mediaId: mediaId,
      media: newMedia,
    });

    debouncedSortMedia(talentId);

    return newMedia;
  };
}

export function uploadMedia(mediaId, file) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let media = getState().media.all[mediaId];
      if(!media) {
        dispatch({
          type: "UPLOAD_MEDIA_FAILURE",
          code: 'uploadMedia/001',
          message: "Media not found.",
          mediaId,
        });
        return;
      }

      dispatch({
        type: 'UPDATE_MEDIA',
        mediaId,
        media: {...media, isLoading:true}
      });

      let formData = new FormData();
      formData.append('upload_preset', cloudinaryUploadPreset);
      formData.append("file", file);

      var xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function(e) {
        // not done yet
        if(xhr.readyState !== 4) {
          return;
        }

        // something went wrong.
        if(xhr.status !== 200) {
          dispatch({
            type: 'UPLOAD_MEDIA_FAILURE',
            code: 'uploadMedia/002',
            mediaId,
            message: 'Uplaod media failure.',
          });
          reject();
          return;
        }

        var res = JSON.parse(xhr.responseText);
        if(!res || !res.public_id) {
          dispatch({
            type: 'UPLOAD_MEDIA_FAILURE',
            code: 'uploadMedia/002',
            mediaId,
            message: 'Uplaod media failure.',
          });
          reject(res);
          return;
        }

        const update = {
          cloudinary_id:res.public_id,
          type: res.resource_type,
          format: res.format,
          isLoading: false,
        };

        // note: reloading media from state to be sure not to overwrite any
        // updates made while media was uploading.
        media = {...getState().media.all[mediaId], ...update};
        firebase.database().ref(`media/${media.id}`).update(update);

        dispatch({
          type: 'UPDATE_MEDIA',
          mediaId,
          media,
        });

        resolve(media);
      };

      xhr.open("post", `https://api.cloudinary.com/v1_1/${cloudinaryCloudName}/auto/upload`);
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

      xhr.send(formData);

    });
  };
}

export function linkMedia(mediaId, url) {
  return async (dispatch, getState) => {
    let media = getState().media.all[mediaId];
    if(!media) {
      dispatch({
        type: "LINK_MEDIA_FAILURE",
        code: 'linkMedia/001',
        message: "Media not found.",
        mediaId,
      });
      return;
    }

    const update = {
      url: url,
      type: 'link',
      format: url.indexOf('vimeo') > -1 ? 'vimeo' : 'youtube'
    };

    media = {...media, ...update};
    firebase.database().ref(`media/${mediaId}`).update(update);

    dispatch({
      type: 'UPDATE_MEDIA',
      mediaId,
      media,
    });

    return media;
  };
}

export function removeMedia(mediaId, agencyId) {
  return async (dispatch, getState) => {
    const media = await dispatch( loadMedia(mediaId) );

    if(!agencyId) {
      const talent = await dispatch( loadTalent(media.talentId) );
      agencyId = talent.agencyId;
    }

    if(!agencyId) {
      agencyId = getState().user.agencyId;
    }

    firebase.database().ref(`media/${mediaId}`).remove();
    firebase.database().ref(`mediaForApproval/${agencyId}/${mediaId}`).remove();

    dispatch({
      type: 'REMOVE_MEDIA',
      mediaId,
      agencyId,
    });
  };
}

function sortMedia(talentId) {
  return async (dispatch, getState) => {
    const media = await dispatch(loadTalentMediaIfNeeded(talentId));
    const user = getState().user;
    const userRole = user.role || 'talent';
    const isTalentOwner = !!user.talents[talentId];
    const orderProperty = isTalentOwner ? 'order' : `orderByRole.${userRole}`;
    const orderPath = isTalentOwner ? 'order' : `orderByRole/${userRole}`;

    Object.keys(media)
      .sort((a, b) => {
        let aOrder = getProperty(media[a], orderProperty, media[a].order);
        let bOrder = getProperty(media[b], orderProperty, media[b].order);
        return aOrder < bOrder ? -1 : 1;
      })
      .map(async (id, i) => {
        if(getProperty(media[id], orderProperty) === i) {
          return media[id];
        }

        setProperty(media[id], orderProperty, i);
        dispatch({
          type: 'UPDATE_MEDIA',
          mediaId: id,
          media: media[id],
        });
        firebase.database().ref(`media/${id}/${orderPath}`).set(i);

        return media[id];
      });
  };
}

const debouncedSortMedia = debounce(sortMedia, 300);

export function updateMedia(mediaId, update) {
  return (dispatch, getState) => {
    let media = getState().media.all[mediaId];
    if(!media) {
      dispatch({
        type: 'UPDATE_MEDIA_FAILURE',
        code: 'updateMedia/001',
        message: 'Media not found.',
        mediaId,
      });
      return;
    }

    firebase.database().ref(`media/${mediaId}`).update(update);
    const updatedMedia = {...media, ...update};

    dispatch({
      type: 'UPDATE_MEDIA',
      mediaId,
      media: updatedMedia,
    });

    // dispatch( updateTalentMediaTags(media.talentId) );

    return updatedMedia;
  };
}

export function moveTalentMedia(talentId, mediaId, beforeMediaId) {
  return (dispatch, getState) => {
    let media = getState().media.byTalent[talentId];
    if(!media) {
      return media;
    }
    media = cloneDeep(media);

    const user = getState().user;
    const userRole = user.role || 'talent';
    const isTalentOwner = !!user.talents[talentId];
    const orderProperty = isTalentOwner ? 'order' : `orderByRole.${userRole}`;
    const orderPath = isTalentOwner ? 'order' : `orderByRole/${userRole}`;

    const thisMedia = media[mediaId];
    const beforeMedia = media[beforeMediaId];

    if(!thisMedia || !beforeMedia) {
      return;
    }

    const thisOrder = getProperty(thisMedia, orderProperty, thisMedia.order) || 0;
    const beforeOrder = getProperty(beforeMedia, orderProperty, beforeMedia.order) || 0;

    const newOrder = beforeOrder > thisOrder ? beforeOrder+0.5 : beforeOrder-0.5;

    setProperty(thisMedia, orderProperty, newOrder);


    return Object.keys(media)
      .sort((a, b) => {
        let aOrder = getProperty(media[a], orderProperty, media[a].order);
        let bOrder = getProperty(media[b], orderProperty, media[b].order);
        return aOrder < bOrder ? -1 : 1;
      })
      .map(async (id, i) => {
        if(getProperty(media[id], orderProperty) === i) {
          return media[id];
        }

        setProperty(media[id], orderProperty, i);
        dispatch({
          type: 'UPDATE_MEDIA',
          mediaId: id,
          media: media[id],
        });
        firebase.database().ref(`media/${id}/${orderPath}`).set(i);

        return media[id];
      });
  };
}

export function updatePackTalentMedia(packId, talentId, mediaId, update) {
  return async (dispatch, getState) => {
    const packTalent = await dispatch( loadPackTalent(packId, talentId) );
    let media = packTalent.media;
    if(!media) {
      dispatch({
        type: 'UPDATE_PACKTALENT_MEDIA_FAILURE',
        code: 'updatePackTalentMedia/001',
        message: 'Media not found.',
        packId,
        talentId,
        mediaId,
      });
      return;
    }

    firebase.database().ref(`packTalents/${packId}/${talentId}/media/${mediaId}`).update(update);
    const updatedMedia = {...media[mediaId], ...update};

    dispatch({
      type: 'UPDATE_PACK_TALENT',
      packId,
      talentId,
      talent: {
        ...packTalent,
        media:{
          ...media,
          [mediaId]: updatedMedia
        }
      }
    });

    return updatedMedia;
  };
}

function sortPackTalentMedia(packId, talentId) {
  return async (dispatch, getState) => {
    const talent = await dispatch(loadPackTalent(packId, talentId));
    const media = talent.media;

    await Promise.all([
      Object.keys(media)
        .sort((a, b) => media[a].order < media[b].order ? -1 : 1)
        .map(async (id, i) => {
          if(media[id].order !== i) {
            media[id].order = i;
            return await dispatch( updatePackTalentMedia(packId, talentId, id, {order:i}) );
          }
        })
    ]);

    return media;
  };
}

export function movePackTalentMedia(packId, talentId, mediaId, beforeMediaId) {
  return async (dispatch, getState) => {
    const talent = await dispatch(loadPackTalent(packId, talentId));
    const media = talent.media;

    const thisMedia = media[mediaId];
    const beforeMedia = media[beforeMediaId];

    if(!thisMedia || !beforeMedia) {
      return;
    }

    const thisOrder = thisMedia.order;
    const beforeOrder = beforeMedia.order;

    const newOrder = beforeOrder > thisOrder ? beforeOrder+0.01 : beforeOrder-0.01;
    await dispatch( updatePackTalentMedia(packId, talentId, mediaId, {order:newOrder}) );

    await dispatch( sortPackTalentMedia(packId, talentId) );

    return {...thisMedia, order:newOrder};
  };
}

export function movePackTalentMediaUp(packId, talentId, mediaId) {
  return async (dispatch, getState) => {
    const talent = await dispatch(loadPackTalent(packId, talentId));
    const media = talent.media;
    const thisMedia = media[mediaId];

    const switchMediaId = Object.keys(media)
      .filter(id => media[id].order < thisMedia.order)
      .sort((a, b) => media[a].order > media[b].order ? -1 : 1)[0];

    const switchMedia = media[switchMediaId];

    if(thisMedia && switchMedia) {
      await dispatch( updatePackTalentMedia(packId, talentId, mediaId, {order:switchMedia.order}) );
      await dispatch( updatePackTalentMedia(packId, talentId, switchMediaId, {order:thisMedia.order}) );
    }
  };
}

export function movePackTalentMediaDown(packId, talentId, mediaId) {
  return async (dispatch, getState) => {
    const talent = await dispatch(loadPackTalent(packId, talentId));
    const media = talent.media;
    const thisMedia = media[mediaId];

    const switchMediaId = Object.keys(media)
      .filter(id => media[id].order > thisMedia.order)
      .sort((a, b) => media[a].order < media[b].order ? -1 : 1)[0];

    const switchMedia = media[switchMediaId];

    if(thisMedia && switchMedia) {
      await dispatch( updatePackTalentMedia(packId, talentId, mediaId, {order:switchMedia.order}) );
      await dispatch( updatePackTalentMedia(packId, talentId, switchMediaId, {order:thisMedia.order}) );
    }
  };
}

export function movePackTalentMediaToTop(packId, talentId, mediaId) {
  return async (dispatch, getState) => {
    const talent = await dispatch(loadPackTalent(packId, talentId));
    const media = talent.media;

    let lowestOrder;
    for(var i in media) {
      if(lowestOrder === undefined || lowestOrder > media[i].order) {
        lowestOrder = media[i].order;
      }
    }

    dispatch( updatePackTalentMedia(packId, talentId, mediaId, {order:lowestOrder - 1}) );
  };
}

export function movePackTalentMediaToBottom(packId, talentId, mediaId) {
  return async (dispatch, getState) => {
    const talent = await dispatch(loadPackTalent(packId, talentId));
    const media = talent.media;

    let highestOrder;
    for(var i in media) {
      if(highestOrder === undefined || highestOrder < media[i].order) {
        highestOrder = media[i].order;
      }
    }

    dispatch( updatePackTalentMedia(packId, talentId, mediaId, {order:highestOrder + 1}) );
  };
}

export function removePackTalentMedia(packId, talentId, mediaId) {
  return async (dispatch, getState) => {
    const packTalent = await dispatch( loadPackTalent(packId, talentId) );
    const { [mediaId]:removedMedia, ...media } = packTalent.media;

    firebase.database().ref(`packTalents/${packId}/${talentId}/media/${mediaId}`).remove();

    dispatch({
      type:'UPDATE_PACK_TALENT',
      packId,
      talentId,
      talent: {...packTalent, media},
    });
  };
}

export function resetPackTalentMedia(packId, talentId) {
  return async (dispatch, getState) => {
    const state = getState();
    const agencyId = state.user.agencyId;
    const agency = await dispatch(loadAgency(agencyId));
    const packTalent = await dispatch( loadPackTalent(packId, talentId) );

    let media = await dispatch(loadTalentMedia(talentId));
    media = {...media};
    for(const key in media) {
      if(!media[key].active) {
        delete media[key];
      }
      else if(agency.requireTalentMediaApproval && !media[key].approved) {
        delete media[key];
      }
    }

    Object.keys(media)
      .map(id => ({id, ...media[id]}))
      .sort((a, b) => {
        const ao = a.orderByRole && a.orderByRole['agent'] ? a.orderByRole['agent'] :
                   a.order ? a.order :
                   0;
        const bo = b.orderByRole && b.orderByRole['agent'] ? b.orderByRole['agent'] :
                   b.order ? b.order :
                   0;
        return ao - bo;
      })
      .forEach((m, i) => {
        media[m.id].order = i;
      });

    firebase.database().ref(`packTalents/${packId}/${talentId}`).update({media});

    dispatch({
      type:'UPDATE_PACK_TALENT',
      packId,
      talentId,
      talent: {...packTalent, media},
    });
  };
}

export function filterPackTalentMedia(packId, talentId, criteria) {
  return async (dispatch, getState) => {
    const packTalent = await dispatch( loadPackTalent(packId, talentId) );

    const media = {...packTalent.media};
    for(const key in media) {
      if(!isMatch(media[key], criteria)) {
        delete media[key];
      }
    }

    firebase.database().ref(`packTalents/${packId}/${talentId}`).update({media});

    dispatch({
      type:'UPDATE_PACK_TALENT',
      packId,
      talentId,
      talent: {...packTalent, media},
    });

    return media;
  };
}

export function rotateMedia(mediaId) {
  return async (dispatch, getState) => {
    const media = await dispatch( loadMedia(mediaId) );
    let angle = media.angle || 0;
    angle = (angle + 90) % 360;

    // console.log('setting media angle', mediaId, angle)

    firebase.database().ref(`media/${mediaId}/angle`).set(angle);
    const updatedMedia = {...media, angle};

    dispatch({
      type: 'UPDATE_MEDIA',
      mediaId,
      media: updatedMedia,
    });
  };
}

export function loadUnapprovedMedia() {
  return async (dispatch, getState) => {
    const state = getState();
    const agencyId = state.user.agencyId;

    let media = state.media.forApproval;
    if(!!Object.keys(media).length > 0) {
      return media;
    }

    dispatch({type:"LOAD_UNAPPROVED_MEDIA_REQUEST", agencyId});

    media = await firebase.database().ref(`mediaForApproval/${agencyId}`)
      .once('value').then(snapshot => snapshot.val());

    if(!media) {
      return {};
    }

    await Promise.all(
      Object.keys(media).map(async mediaId => {
        return media[mediaId] = await dispatch( loadMedia(mediaId) );
      })
    );

    // Make sure all associated talents are loaded.
    await Promise.all(
      Object.keys(media).map(async mediaId => {
        return await dispatch( loadTalent(media[mediaId].talentId) );
      })
    );

    dispatch({
      type:"LOAD_UNAPPROVED_MEDIA_SUCCESS",
      media,
    });

    return media;
  };
}

let unapprovedMediaSubscription = null;
export function subscribeToUnapprovedMedia(agencyId) {
  return (dispatch, getState) => {

    if(!!unapprovedMediaSubscription) {
      dispatch( unsubscribeFromUnapprovedMedia() );
    }

    if(!agencyId) {
      agencyId = getState().user.agencyId;
    }

    unapprovedMediaSubscription = firebase.database().ref(`mediaForApproval/${agencyId}`).on('value', async snapshot => {

      let media = snapshot.val();
      if(!media) {
        return;
      }

      await Promise.all(
        Object.keys(media).map(async mediaId => {
          return media[mediaId] = await dispatch( loadMedia(mediaId) );
        })
      );

      await Promise.all(
        Object.keys(media).map(async mediaId => {
          return await dispatch( loadTalent(media[mediaId].talentId) );
        })
      );

      dispatch({
        type:"LOAD_UNAPPROVED_MEDIA_SUCCESS",
        media,
        agencyId,
      });

    });

  };
}
export function unsubscribeFromUnapprovedMedia() {
  return (dispatch, getState) => {
    if(!unapprovedMediaSubscription) {
      return;
    }
    const agencyId = getState().user.agencyId;
    firebase.database().ref(`mediaForApproval/${agencyId}`).off('value', unapprovedMediaSubscription);
  };
}







/* FAQ */

export function addFAQ() {
  return dispatch => {
    const faq = {dateCreated:Date.now(), active:false};

    const ref = firebase.database().ref('faqs').push(faq);
    faq.id = ref.key;

    dispatch({
      type: 'ADD_FAQ',
      faq,
    });

    return faq;
  };
}

export function loadFAQs() {
  return async dispatch => {
    const faqs = await firebase.database().ref(`faqs`)
      .once('value')
      .then(snapshot => snapshot.val());

    dispatch({
      type: 'LOAD_FAQS',
      faqs,
    });
  };
}

export function loadFAQ(id) {
  return async (dispatch, getState) => {
    let faq = getState().faqs.all[id];
    if(faq) {
      return faq;
    }

    faq = await firebase.database().ref(`faqs/${id}`)
      .once('value')
      .then(snapshot => ({id, ...snapshot.val()}));

    dispatch({
      type: 'LOAD_FAQ',
      faq,
    });

    return faq;
  };
}

export function updateFAQ(faq) {
  return async (dispatch, getState) => {
    if(!faq.id) {
      dispatch({
        type: 'UPDATE_FAQ_FAILURE',
        faq,
        code: 'updateFAQ/01',
        message: "That FAQ doesn't exist",
      });
      return;
    }

    const existingFaq = await dispatch( loadFAQ(faq.id) );

    firebase.database().ref(`faqs/${faq.id}`).update(faq);
    faq = {...existingFaq, ...faq};

    dispatch({
      type: 'UPDATE_FAQ',
      faq,
    });

    return faq;
  };
}

export function updateFAQMedia(faqId, file) {
  return async (dispatch, getState) => {
    // let faq = await dispatch( loadFAQ(faqId) );

    // faq.media = {...faq.media, isLoading:true};
    // dispatch({
    //   type: 'UPDATE_FAQ',
    //   faq,
    // });

    // const reader = new FileReader();
    // reader.readAsDataURL(file);

    // reader.onload = function () {
    //   cloudinary.uploader.unsigned_upload(reader.result, cloudinaryUploadPreset, res => {

    //     faq.media = {
    //       width: res.width,
    //       height: res.height,
    //       type: res.resource_type,
    //       format: res.format,
    //       cloudinary_id:res.public_id,
    //     };

    //     firebase.database().ref(`faqs/${faq.id}`).update(faq);

    //     dispatch({
    //       type: 'UPDATE_FAQ',
    //       faq,
    //     });

    //   },
    //   { resource_type: 'auto' });
    // };

    // reader.onerror = function (error) {
    //   dispatch({
    //     type: 'UPDATE_FAQ_FAILURE',
    //     code: 'updateFAQMedia/02',
    //     error,
    //   });
    // };
  };
}

export function removeFAQ(faq) {
  return async (dispatch, getState) => {
    if(!faq.id) {
      dispatch({
        type: 'REMOVE_FAQ_FAILURE',
        faq,
        code: 'removeFAQ/01',
        message: "That FAQ doesn't exist",
      })
      return;
    }

    firebase.database().ref(`faqs/${faq.id}`).remove();

    dispatch({
      type: 'REMOVE_FAQ',
      faq,
    });

    return null;
  }
}





/* Billing */

export function loadUserBilling(userId) {
  return async (dispatch, getState) => {
    userId = userId || getState().user.id;

    dispatch({
      type: "LOAD_USER_BILLING_REQUEST",
      userId,
    });

    const user = await dispatch(loadUser(userId));


    // if `user.epayId`, use that
    if(user.epayId) {
      const url = `${firebaseFunctionsURL}/getUSAePaySubscription?customerId=${user.epayId}`;
      const res = await fetch(url).then(res => res.json());

      if(res.error || res.code) {
        console.log('error', res);
        return;
      }

      const nextPayments = [
        {
          amount: res.amount,
          cycle: res.cycle.toLowerCase().indexOf('mon') > -1 ? 'month' : 'year',
          date: res.next,
          failures: res.failures,
          enabled: res.enabled,
        }
      ];

      dispatch({
        type: "LOAD_USER_BILLING",
        userId,
        nextPayments,
      });

      return nextPayments;
    }


    // else, load all talent billing info.

    const userTalents = Object.keys(user.talents || {});

    const nextPayments = [];

    for(const talentId of userTalents) {
      const talent = await dispatch(loadTalent(talentId));

      if(talent.epayId) {
        const url = `${firebaseFunctionsURL}/getUSAePaySubscription?customerId=${talent.epayId}`;
        const res = await fetch(url).then(res => res.json());

        if(res.error || res.code) {
          continue;
        }

        nextPayments.push({
          amount: res.amount,
          cycle: res.cycle.toLowerCase().indexOf('mon') > -1 ? 'month' : 'year',
          date: res.next,
          failures: res.failures,
          enabled: res.enabled,
        });
      }
    }

    dispatch({
      type: "LOAD_USER_BILLING",
      userId,
      nextPayments,
    });
  };
}

export function createTalentSubscription(talentId, plan, payment) {
  /*
  NOTE:

  plan should include:
  - cycle
  - talent
  - talentSetup
  - commission

  payment should include:
  - firstName
  - lastName
  - street
  - city
  - state
  - zip
  - number
  - exp
  - code
  - note

  */
  return async (dispatch, getState) => {
    dispatch({
      type: "CREATE_TALENT_SUBSCRIPTION_REQUEST",
      talentId,
      plan,
      payment,
    });

    const url = `${firebaseFunctionsURL}/createUSAePaySubscription`;
    const payload = {
      talentId,
      plan,
      payment,
    };

    const options = {
      method:'post',
      headers:{'content-type': 'application/json'},
      body: JSON.stringify(payload)
    };

    return fetch(url, options)
      .then((res) => {
        let json = res.json();
        if(res.ok) {
          return json;
        } else {
          return json.then(Promise.reject.bind(Promise));
        }
      })
      .then(async result => {
        dispatch({
          type: "CREATE_TALENT_SUBSCRIPTION",
          talentId,
          talent: result.talent,
        });
        if(!!plan.addons) {
          await dispatch( updateTalent(talentId, {requireAddons:true}));
        }
        if(plan.commission) {
          await dispatch( updateTalent(talentId, {commission:plan.commission}));
        }
        await dispatch( flagTalentForApproval(talentId) );

        return result.talent;
      })
      .catch(error => {

        // todo: report to sentry?

        dispatch({
          type: "CREATE_TALENT_SUBSCRIPTION_FAILURE",
          error: error,
        });
        return {error};
      });
  };
}

export function addTalentTrialSubscription(talentId) {
  return async (dispatch, getState) => {
    const agency = await dispatch( loadTalentAgency(talentId) );
    let talent = await dispatch( loadTalent(talentId) );

    if(!agency.allowTrialTalents) {
      return talent;
    }

    let dateExpires = null;
    if(agency.allowTrialTalents !== true) {
      dateExpires = moment().add(agency.allowTrialTalents).valueOf();
      firebase.database().ref(`talents/${talentId}/dateExpires`).set(dateExpires);
    }

    talent = await dispatch( flagTalentForApproval(talentId) );

    firebase.database().ref(`talents/${talentId}/requireSubscription`).remove();
    firebase.database().ref(`talents/${talentId}/status`).set("TRIAL");

    talent = {
      ...talent,
      status:"TRIAL",
      requireSubscription:false,
      dateExpires,
    };

    dispatch({
      type: "UPDATE_TALENT",
      talentId,
      talent,
    });

    return talent;
  };
}

export function updateTalentCreditCard(talentId, card) {
  return async (dispatch, getState) => {
    const talent = await dispatch( loadTalent(talentId) );
    if(!talent) {
      return;
    }

    const epayId = talent.epayId;
    if(!epayId) {
      return;
    }

    dispatch({
      type: 'LOAD_TALENT_SUBSCRIPTION_REQUEST',
      talentId,
    });

    const url = `${firebaseFunctionsURL}/updateUSAePayCard`;

    const payload = {
      customerId: epayId,
      enabled: talent.status === "PAID",
      card,
    };

    const options = {
      method:'post',
      headers:{'content-type': 'application/json'},
      body: JSON.stringify(payload)
    };

    return fetch(url, options)
      .then((res) => {
        let json = res.json();
        if(res.ok) {
          return json;
        } else {
          return json.then(Promise.reject.bind(Promise));
        }
      })
      .then(subscription => {
        dispatch(updateTalent(talentId, {dateExpires:null}));
        dispatch({
          type: 'LOAD_TALENT_SUBSCRIPTION',
          talentId,
          subscription,
        });
        return subscription;
      })
      .catch(error => {
        dispatch({
          type: 'LOAD_TALENT_SUBSCRIPTION_FAILURE',
          talentId,
          error,
        });
      });
  };
}

export function updateTalentBillingAddress(talentId, address) {
  return async (dispatch, getState) => {
    const talent = await dispatch( loadTalent(talentId) );
    if(!talent) {
      return;
    }

    const epayId = talent.epayId;
    if(!epayId) {
      return;
    }

    dispatch({
      type: 'LOAD_TALENT_SUBSCRIPTION_REQUEST',
      talentId,
    });

    const url = `${firebaseFunctionsURL}/updateUSAePayAddress`;

    const payload = {
      customerId: epayId,
      address,
    };

    const options = {
      method:'post',
      headers:{'content-type': 'application/json'},
      body: JSON.stringify(payload)
    };

    const subscription = await fetch(url, options).then(res => res.json());

    dispatch({
      type: 'LOAD_TALENT_SUBSCRIPTION',
      talentId,
      subscription,
    });
  };
}

export function updateUserCreditCard(userId, card) {
  return (dispatch, getState) => {
    const user = getState().users.all[userId];
    const talentIds = user.talents ? Object.keys(user.talents) : null;

    if(!talentIds) {
      return;
    }

    talentIds.forEach(talentId => {
      dispatch( updateTalentCreditCard(talentId, card) );
    });
  };
}

export function updateUserBillingAddress(userId, address) {
  return (dispatch, getState) => {
    const user = getState().users.all[userId];
    const talentIds = user.talents ? Object.keys(user.talents) : null;

    if(!talentIds) {
      return;
    }

    talentIds.forEach(talentId => {
      dispatch( updateTalentBillingAddress(talentId, address) );
    });
  };
}

export function cancelTalent(talentId) {
  return async (dispatch, getState) => {

    const talent = getState().talents.all[talentId];
    if(!talent || !talentId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'cancelTalent/01',
        message: 'Talent not found.',
      });
      return;
    }

    const byUserId = getState().user.id;

    firebase.database().ref(`transactions`).push({type: "CANCEL_TALENT", talentId, byUserId});

    const updatedTalent = {...talent, dateUpdated:Date.now(), status:'CANCELED'};
    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function upgradeTalent(talentId) {
  return async (dispatch, getState) => {
    const talent = getState().talents.all[talentId];
    if(!talent || !talentId) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'upgradeTalent/01',
        message: 'Talent not found.',
      });
      return false;
    }

    const agency = await dispatch( loadAgency(talent.agencyId) );
    if(!agency) {
      dispatch({
        type: 'UPDATE_TALENT_FAILURE',
        code: 'upgradeTalent/02',
        message: 'Agency not found.',
      });
      return false;
    }

    if(!!agency.chargeTalentsToAgency) {

      // Todo.
      // This will most likely never be used. Any agency paying for all talents
      // wouldn't have a trial membership.

      return;
    }
    else {

      if(!talent.epayId) {
        const error = {
          type: 'UPDATE_TALENT_FAILURE',
          code: 'upgradeTalent/03',
          message: 'No ePay ID found.',
        };
        dispatch(error);
        return {error};
      }

      if(!talent.plan) {
        const error = {
          type: 'UPDATE_TALENT_FAILURE',
          code: 'upgradeTalent/04',
          message: 'No plan found.',
        };
        dispatch(error);
        return {error};
      }

      const url = `${firebaseFunctionsURL}/enableUSAePaySubscription`;
      const options = {
        method: 'post',
        headers: {'content-type':'application/json'},
        body: JSON.stringify({talentId})
      };

      let result;
      try {
        result = await fetch(url, options).then(res => res.json());
      }
      catch(err) {
        const error = {
          type: 'UPDATE_TALENT_FAILURE',
          code: 'upgradeTalent/05',
          message: "Upgrade failed. Please try again.",
          error: err,
        };
        dispatch(error);
        return {error};
      }

      if(!result || result.code) {
        const error = {
          type: 'UPDATE_TALENT_FAILURE',
          code: 'upgradeTalent/05',
          message: "Upgrade failed. Please try again.",
          error: result,
        };
        dispatch(error);
        return {error};
      }

      dispatch({
        type: "LOAD_TALENT_SUBSCRIPTION",
        talentId,
        subscription: result,
      });
    }

    firebase.database().ref(`talents/${talentId}/status`).set("PAID");
    firebase.database().ref(`talents/${talentId}/dateExpires`).remove();

    // if(agency.requireTalentApproval) {
    //   firebase.database().ref(`talentsForApproval/${agency.id}/${talentId}`).set(true);
    // }

    const updatedTalent = {...talent, status:'PAID', dateExpires:null};
    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    return updatedTalent;
  };
}

export function loadTalentTransactions(talentId) {
  return async dispatch => {

    dispatch({
      type: "LOAD_TALENT_TRANSACTIONS_REQUEST",
      talentId,
    });

    const epayId = await firebase.database().ref(`talents/${talentId}/epayId`).once('value').then(s => s.val());
    let transactions = [];
    if(epayId) {
      transactions = await fetch(`${firebaseFunctionsURL}/getUsaepayTransactions?customerId=${epayId}`)
        .then(res => res.json());
    }

    if(!transactions) {
      transactions = [];
    }

    if(!!transactions.error) {
      transactions = [];
    }

    if(!isArray(transactions)) {
      transactions = [];
    }

    transactions.sort((a, b) => moment(a.date).isBefore(b.date) ? 1 : -1);

    dispatch({
      type: "LOAD_TALENT_TRANSACTIONS",
      talentId,
      transactions,
    });

    return transactions;
  }
}

export function loadUserTransactions(userId) {
  return async (dispatch, getState) => {
    userId = userId || getState().user.id;
    const user = await dispatch(loadUser(userId));

    dispatch({
      type: "LOAD_USER_TRANSACTIONS_REQUEST",
      userId,
    });

    let transactions = [];


    // if `user.epayId` load

    if(user.epayId) {
      const epayUrl = `${firebaseFunctionsURL}/getUsaepayTransactions?customerId=${user.epayId}`;
      transactions = await fetch(epayUrl).then(res => res.json());
    }

    // else load all talent transactions

    else {
      const talentIds = Object.keys(user.talents || {});
      for(const talentId of talentIds) {
        const talent = await dispatch(loadTalent(talentId));
        if(talent.epayId) {
          const epayUrl = `${firebaseFunctionsURL}/getUsaepayTransactions?customerId=${talent.epayId}`;
          const data = await fetch(epayUrl).then(res => res.json());
          transactions = [...transactions, ...data];
        }
      }
    }

    transactions.sort((a, b) => moment(a.date).isBefore(b.date) ? 1 : -1);

    dispatch({
      type: "LOAD_USER_TRANSACTIONS",
      userId,
      transactions,
    });
  };
}

export function loadTalentBilling(talentId) {
  return async (dispatch, getState) => {
    const talent = await dispatch( loadTalent(talentId) );

    if(talent.subscriptionAmount) {
      return talent;
    }

    // if talent has epay id, load epay payment data and add to talent
    if(talent.epayId) {
      dispatch({
        type: 'UPDATE_TALENT_REQUEST',
        talentId,
      });

      const apiUrl = `${firebaseFunctionsURL}/getUsaepayCustomer?customerId=`;
      let res;
      try {
        res = await fetch(apiUrl + talent.epayId).then(res => res.json());
      }
      catch(error) {
        dispatch({
          type: 'UPDATE_TALENT_ERROR',
          code: "loadTalentBilling/05",
          talentId,
          error,
        });
        return talent;
      }

      if(!!res.error) {
        dispatch({
          type: 'UPDATE_TALENT_ERROR',
          code: "loadTalentBilling/01",
          talentId,
          ...res,
        });
        return talent;
      }

      talent.hasSubscription = true;
      talent.subscriptionProvider = 'EPAY';
      talent.subscriptionAmount = res.amount;
      talent.subscriptionCycle = res.cycle.toLowerCase().indexOf('month') > -1 ? 'month' : 'year';
      talent.subscriptionNextDate = res.next;
      talent.subscriptionFailures = res.failures;
      talent.isLoading = false;

      // todo: do we want to permanently update the user data with this data
      // so next time we don't need to make the additional request?

      dispatch({
        type: 'UPDATE_TALENT',
        talentId,
        talent,
      });

      return talent;
    }


    // if the talent's user has a braintree subscription, load plan details
    // and add those to talent.
    if(!talent.userId) {
      return talent;
    }

    const user = await dispatch(loadUser(talent.userId));

    if(user.subscriptionId && user.planId) {
      dispatch({
        type: 'UPDATE_TALENT_REQUEST',
        talentId,
      });

      let url = `${firebaseFunctionsURL}/getBraintreeSubscription`;
      url += `?subscriptionId=${user.subscriptionId}`;
      const subscription = await fetch(url).then(res => res.json());

      if(subscription.status !== 'Active') {
        dispatch({
          type: "UPDATE_TALENT_ERROR",
          code: "loadTalentBilling/02",
          message: "Subscription is not active.",
          talentId,
        });

        return talent;
      }

      const agency = await dispatch(loadAgency(user.agencyId));
      const plan = agency.plans[user.planId];

      if(!plan) {
        dispatch({
          type: "UPDATE_TALENT_ERROR",
          code: "loadTalentBilling/04",
          message: "Plan not found.",
          talentId,
        });

        return talent;
      }

      talent.hasSubscription = true;
      talent.subscriptionProvider = 'BRAINTREE';
      talent.subscriptionAmount = talent.status === "PAID" ? plan.talent : 0;
      talent.subscriptionCycle = plan.cycle;
      talent.subscriptionNextDate = subscription.nextBillingDate;
      talent.subscriptionFailures = subscription.failureCount;
      talent.isLoading = false;

      // todo: do we want to permanently update the user data with this data
      // so next time we don't need to make the additional request?

      dispatch({
        type: 'UPDATE_TALENT',
        talentId,
        talent,
      });

      return talent;
    }

    return talent;
  };
}

export function loadTalentSubscription(talentId) {
  return async (dispatch, getState) => {
    let subscription = getState().subscriptions.byTalent[talentId];
    if(subscription) {
      return subscription;
    }

    const talent = await dispatch( loadTalent(talentId) );
    const epayId = talent.epayId;

    if(!epayId) {
      return null;
    }

    dispatch({
      type: 'LOAD_TALENT_SUBSCRIPTION_REQUEST',
      talentId,
      epayId,
    });

    subscription = await fetch(`${firebaseFunctionsURL}/getUSAePaySubscription?customerId=${epayId}`)
      .then(res => res.json())
      .catch(error => {
        // todo: handle error.
      });

    dispatch({
      type: 'LOAD_TALENT_SUBSCRIPTION',
      talentId,
      subscription,
    });
  };
}



/* Agencies */

export function loadAgencies(agencyIds=null) {
  return async dispatch => {

    dispatch({type: "LOAD_AGENCIES_REQUEST"});

    const agencies = await firebase.database()
      .ref(`agencies`)
      .once('value').then(snapshot => {
        let agencies = snapshot.val();
        for(const id in agencies) {
          agencies[id] = {...baseAgency, ...agencies[id], id};
        }

        // todo: do this by loading each one and Promise.all-ing it, rather
        // than filters after loading all of them...
        if(agencyIds !== null) {
          const requestedAgencies = {};
          for(const id in agencies) {
            if(agencyIds.indexOf(id) > -1) {
              requestedAgencies[id] = agencies[id];
            }
          }
        }
        return agencies;
      });

    dispatch({
      type: "LOAD_AGENCIES_SUCCESS",
      agencies,
    });

    return agencies;
  };
}

export function loadAgency(agencyId) {
  return async (dispatch, getState) => {

    if(!agencyId) {
      dispatch({
        type:"LOAD_AGENCY_FAILURE",
        code: 'loadAgency/001',
        message: "Must supply an agency.",
        agencyId
      });
      return;
    }

    let agency = getState().agencies.all[agencyId];
    if(agency) {
      return agency;
    }

    dispatch({type:"LOAD_AGENCY_REQUEST", agencyId});

    agency = await firebase.database()
      .ref(`agencies/${agencyId}`)
      .once('value').then(snapshot => {
        if(!snapshot.exists()) {
          return null;
        }
        return {...baseAgency, ...snapshot.val(), id:agencyId};
      });

    dispatch({
      type:"LOAD_AGENCY_SUCCESS",
      agency
    });

    return agency;
  };
}

export function loadAgencyUsers(agencyId) {
  return async (dispatch, getState) => {

    const agencyId = getState().user.agencyId;

    const users = await firebase.database().ref("users")
      .orderByChild('agencyId').equalTo(agencyId)
      .once('value').then(snapshot => {
        if(!snapshot.exists()) {
          return {};
        }

        const users = snapshot.val();
        for(const id in users) users[id].id = id;
        return users;
      });

    dispatch({
      type: "LOAD_USERS_SUCCESS",
      users,
    });
  };
}







/* DEPRECATED */

export function loadTalentThreads(talentId, refresh=false) {
  return async (dispatch, getState) => {
    const state = getState();
    let threads = state.threads.byTalent[talentId];
    if(!!threads && !refresh) {
      return threads;
    }

    dispatch({type:'LOAD_TALENT_THREADS_REQUEST', talentId});

    threads = await firebase.database().ref(`talentThreads/${talentId}`)
      // todo: some form of lazy loading
      .once('value')
      .then(async snapshot => {
        if(!snapshot.exists()) {
          return {};
        }

        const talentThreads = snapshot.val();
        const threads = {};

        await Promise.all(
          Object.keys(talentThreads).map(async threadId => {
            const thread = await dispatch( loadThread(threadId) );
            if(thread) threads[threadId] = thread;
          })
        );

        return threads;
      });

    if(!threads) {
      threads = {};
    }

    dispatch({type:'LOAD_TALENT_THREADS_SUCCESS', talentId, threads});

    return threads;
  };
}

export function loadUserSubscription(userId) {
  return async (dispatch, getState) => {
    userId = userId || getState().user.id;

    dispatch({
      type: "LOAD_SUBSCRIPTION_REQUEST",
      userId,
    });

    const user = await dispatch(loadUser(userId));
    const subscriptionId = user.subscriptionId;

    if(!subscriptionId) {
      dispatch({
        type: "LOAD_SUBSCRIPTION_ERROR",
        userId,
        code: 'loadUserSubscription/001',
        message: "No subscription Id for user " +userId,
      });
      return;
    }

    let url = `${firebaseFunctionsURL}/getBraintreeSubscription`;
    url += `?subscriptionId=${subscriptionId}`;
    const subscription = await fetch(url).then(res => res.json());

    if(subscription.status !== 'Active') {
      dispatch({
        type: "LOAD_SUBSCRIPTION_ERROR",
        userId,
        code: 'loadUserSubscription/002',
        message: "Subscription is not active."
      });
      return;
    }

    dispatch({
      type: "LOAD_SUBSCRIPTION",
      userId,
      subscription,
    });

    return subscription;
  };
}

export function loadTalentEpay(talentId) {
  return async (dispatch, getState) => {
    const talent = await dispatch(loadTalent(talentId));

    if(talent.epayId) {
      const apiUrl = `${firebaseFunctionsURL}/getUsaepayCustomer?customerId=`;
      const res = await fetch(apiUrl + talent.epayId).then(res => res.json());

      if(!!res.error) {
        dispatch({
          type: 'UPDATE_TALENT_ERROR',
          talentId,
          ...res,
        });
        return;
      }

      talent.epayAccount = res;
      dispatch({
        type: 'UPDATE_TALENT',
        talentId,
        talent,
      });
    }
    return talent;
  };
}

export function downgradeUserTalent(userId, talentId) {
  return async (dispatch, getState) => {
    const user = await dispatch(loadUser(userId));
    const talent = await dispatch(loadTalent(talentId));

    const updatedTalent = {...talent, status:'TRIAL'};
    firebase.database().ref(`talents/${talentId}/status`).set('TRIAL');

    dispatch({
      type: 'UPDATE_TALENT',
      userId,
      talentId,
      talent: updatedTalent,
    });


    const url = `${firebaseFunctionsURL}/removeTalentFromSubscription`;
    const subscriptionId = user.subscriptionId;
    const planId = user.planId;
    const options = {
      method: 'post',
      headers: {'content-type':'application/json'},
      body: JSON.stringify({
        subscriptionId,
        planId,
      })
    };

    try {
      const subscription = await fetch(url, options).then(res => res.json());
      dispatch({
        type: "LOAD_SUBSCRIPTION",
        userId,
        subscription,
      });
    }
    catch(err) {
      updatedTalent.status = 'PAID';
      firebase.database().ref(`talents/${talentId}/status`).set('PAID');

      dispatch({
        type: 'UPDATE_TALENT',
        talentId,
        talent: updatedTalent,
      });

      dispatch({
        type: 'ERROR',
        code: 'downgradeUserTalent/01',
        message: "Downgrade failed. Please try again.",
        error: err,
      });
      return;
    }

    talentsIndex.clearCache();

  };
}

export function loadAgencySubscription(agencyId) {
  return async (dispatch, getState) => {
    agencyId = agencyId || getState().user.agencyId;

    dispatch({
      type: "LOAD_SUBSCRIPTION_REQUEST",
      agencyId,
    });

    if(!agencyId) {
      dispatch({
        type: "LOAD_SUBSCRIPTION_ERROR",
        code: 'loadAgencySubscription/003',
        message: "No id given for agency",
      });
      return;
    }

    const agency = await dispatch(loadAgency(agencyId));
    const subscriptionId = agency.subscriptionId;

    if(!subscriptionId) {
      dispatch({
        type: "LOAD_SUBSCRIPTION_ERROR",
        agencyId,
        code: 'loadAgencySubscription/001',
        message: "No subscription Id for agency",
      });
      return;
    }

    let url = `${firebaseFunctionsURL}/getBraintreeSubscription`;
    url += `?subscriptionId=${subscriptionId}`;
    const subscription = await fetch(url).then(res => res.json());

    if(subscription.status !== 'Active') {
      dispatch({
        type: "LOAD_SUBSCRIPTION_ERROR",
        agencyId,
        code: 'loadAgencySubscription/002',
        message: "Subscription is not active.",
        subscription,
      });
      return;
    }

    dispatch({
      type: "LOAD_SUBSCRIPTION",
      agencyId,
      subscription,
    });
  };
}

export function getBraintreeToken(customerId) {
  return async (dispatch, getState) => {
    customerId = customerId || getState().user.id;
    const url = `${firebaseFunctionsURL}/createBraintreeToken`;
    return await fetch(`${url}?customerId=${customerId}`).then(res => res.text());
  };
}

export function upgradeUserTalent(userId, talentId) {
  return async (dispatch, getState) => {

    const state = getState();
    const user = state.users.all[userId];
    const talent = await dispatch( loadTalent(talentId) );
    const planId = user.planId || 'standard';
    const plan = plans[planId];

    const url = `${firebaseFunctionsURL}/addTalentToSubscription`;
    const subscriptionId = user.subscriptionId;
    const options = {
      method: 'post',
      headers: {'content-type':'application/json'},
      body: JSON.stringify({
        subscriptionId,
        customerId: userId,
        planId,
        setupFee: plan.talentSetup,
      })
    };

    return fetch(url, options)
      .then(res => res.json())
      .then(subscription => {

        if(!!subscription.error) {
          dispatch({
            type: 'ERROR',
            code: 'upgradeUserTalent/01',
            error: subscription.error,
            message: "Upgrade failed. Please try again.",
          });
          return subscription;
        }

        dispatch({
          type: "LOAD_SUBSCRIPTION",
          userId,
          subscription,
        });

        dispatch(loadUserTransactions(userId));

        firebase.database().ref(`talents/${talentId}/status`).set('PAID');
        firebase.database().ref(`talents/${talentId}/requireSubscription`).remove();

        const updatedTalent = {...talent, requireSubscription:false, status:'PAID'};
        dispatch({
          type: 'UPDATE_TALENT',
          userId,
          talentId,
          talent: updatedTalent,
        });

        return subscription;
      });

  };
}

export function addAgencyTalent(agencyId, talentId) {
  return async (dispatch, getState) => {
    const agency = await dispatch( loadAgency(agencyId) );
    const talent = await dispatch( loadTalent(talentId) );

    if(!agency) {
      dispatch({
        type: 'ERROR',
        code: 'addAgencyTalent/02',
        message: "Couldn't find agency",
        agencyId,
        talentId,
      });
      return;
    }

    if(!talent) {
      dispatch({
        type: 'ERROR',
        code: 'addAgencyTalent/03',
        message: "Couldn't find talent",
        agencyId,
        talentId,
      });
      return;
    }

    // In the odd event where the user doesn't have an agency yet,
    // make this the user's default agency.

    const user = await loadUser(talent.userId);
    if(!user.agencyId) {
      dispatch( updateUser(talent.userId, {agencyId}) );
    }


    // Add this talent to the agency.

    dispatch(updateTalent(talentId, {...talent, agencyId}));


    // If the agency requires a subscription, flag the talent as needing
    // to choose a plan.

    if(agency.requireTalentSubscription) {
      dispatch(updateTalent(talentId, {
        ...talent,
        status: 'INCOMPLETE',
        requireSubscription: agency.requireTalentSubscription,
      }));
    }


    // If this agency pays for talent. Add the talent to the agencies subscription.

    if(agency.chargeTalentsToAgency) {

      // Todo: Cancel talent subscription.

      const planId = agency.planId || 'agency';
      const plan = plans[planId];

      dispatch( updateTalent(talentId, {...talent, status:'PAID'}) );
      dispatch( updateAgency(agencyId, {...agency, talents:{...agency.talents, [talentId]:Date.now()}}));

      const url = `${firebaseFunctionsURL}/addTalentToSubscription`;
      const subscriptionId = agency.subscriptionId;
      const options = {
        method: 'post',
        headers: {'content-type':'application/json'},
        body: JSON.stringify({
          subscriptionId,
          customerId: agencyId,
          planId,
          setupFee: plan.talentSetup,
        })
      };

      try {
        const subscription = await fetch(url, options).then(res => res.json());
        dispatch({
          type: "LOAD_SUBSCRIPTION",
          agencyId,
          subscription,
        });
      }
      catch(error) {
        dispatch( updateTalent(talentId, talent) );
        dispatch( updateAgency(agencyId, agency) );

        dispatch({
          type: 'ERROR',
          code: 'addAgencyTalent/01',
          error: error,
          message: "Upgrade failed. Please try again.",
        });

        return Promise.reject(error);
      }

      await dispatch(loadAgencyTransactions(agencyId));
    }

    talentsIndex.clearCache();

    return true;
  };
}

export function removeAgencyTalent(agencyId, talentId) {
  return async (dispatch, getState) => {

    const agency = await dispatch(loadAgency(agencyId));
    const talent = await dispatch(loadTalent(talentId));

    const updatedTalent = {...talent, status:'CANCELED'};
    firebase.database().ref(`talents/${talentId}/status`).set('CANCELED');

    dispatch({
      type: 'UPDATE_TALENT',
      talentId,
      talent: updatedTalent,
    });

    const {[talentId]:removedTalent, ...talents} = agency.talents;
    dispatch( updateAgency(agencyId, {...agency, talents}) );

    const url = `${firebaseFunctionsURL}/removeTalentFromSubscription`;
    const subscriptionId = agency.subscriptionId;
    const planId = agency.planId;
    const options = {
      method: 'post',
      headers: {'content-type':'application/json'},
      body: JSON.stringify({
        subscriptionId,
        planId,
      })
    };

    try {
      const subscription = await fetch(url, options).then(res => res.json());
      dispatch({
        type: "LOAD_SUBSCRIPTION",
        agencyId,
        subscription,
      });
    }
    catch(err) {
      updatedTalent.status = 'PAID';
      firebase.database().ref(`talents/${talentId}/status`).set('PAID');
      dispatch( updateAgency(agencyId, agency) );

      dispatch({
        type: 'UPDATE_TALENT',
        talentId,
        talent: updatedTalent,
      });

      dispatch({
        type: 'ERROR',
        code: 'removeAgencyTalent/01',
        message: "Removal failed. Please try again.",
        error: err,
      });
      return;
    }

    talentsIndex.clearCache();

  };
}

export function loadUserCustomer(userId) {
  return async (dispatch, getState) => {
    userId = userId || getState().user.id;

    dispatch({
      type: "LOAD_CUSTOMER_REQUEST",
      userId,
    });

    let url = `${firebaseFunctionsURL}/getBraintreeCustomer`;
    url += `?userId=${userId}`;
    const customer = await fetch(url).then(res => res.json());

    dispatch({
      type: "LOAD_CUSTOMER",
      userId,
      customer,
    });
  };
}

export function loadAgencyUnapprovedTalents(options={}) {
  return async (dispatch, getState) => {
    let talents = getState().agencyTalents.forApproval;
    if(talents && !options.refresh) {
      return talents;
    }
    talents = {};

    const agencyId = getState().user.agencyId;

    dispatch({
      type:'LOAD_UNAPPROVED_TALENTS_REQUEST',
      agencyId,
    });

    const snapshot = await firebase.database().ref(`talentsForApproval/${agencyId}`).once('value');
    const value = snapshot.val();

    await Promise.all(
      Object.keys(value || {}).map(async key => talents[key] = await dispatch(loadTalent(key)))
    );

    dispatch({
      type:'LOAD_UNAPPROVED_TALENTS',
      talents
    });

    return talents;
  };
}

export function agreeToTerms() {
  return (dispatch, getState) => {
    const now = moment().valueOf();
    const userId = getState().user.id;

    if(!userId) {
      dispatch({
        type: 'AGREE_TO_TERMS_FAILURE',
        code: 'agreeToTerms/01',
        message: "No user found.",
      })
      return;
    }

    firebase.database().ref(`users/${userId}/agreedToTerms`).set(now);
    dispatch({
      type:"AGREE_TO_TERMS",
      agreedToTerms:now,
      userId,
    });
  };
}

export function addUserSubscription(userId, payment, planId='standard') {
  return async (dispatch, getState) => {
    userId = userId || getState().user.id;
    const user = getState().users.all[userId];

    const url = `${firebaseFunctionsURL}/createBraintreeSubscription`;
    const options = {
      method:'post',
      headers:{'content-type': 'application/json'},
      body: JSON.stringify({
        customerId: userId,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        planId,
        nonce:payment
      })
    };
    const subscription = await fetch(url, options).then(res => res.json());

    if(subscription.id) {
      firebase.database().ref(`users/${userId}/subscriptionId`).set(subscription.id);
    }
    firebase.database().ref(`users/${userId}/planId`).set(planId);
    firebase.database().ref(`users/${userId}/requireSubscription`).remove();

    dispatch({
      type: "LOAD_SUBSCRIPTION",
      userId,
      subscription,
      planId,
    });

    return subscription;
  };
}

export function moveTalentMediaToTop(talentId, mediaId) {
  return async (dispatch, getState) => {
    await dispatch( updateMedia(mediaId, {order:-1}) );
    return await dispatch( sortMedia(talentId) );
  };
}

export function moveTalentMediaToBottom(talentId, mediaId) {
  return async (dispatch, getState) => {
    const media = getState().media.byTalent[talentId];
    const count = Object.keys(media).length;
    await dispatch( updateMedia(mediaId, {order:count+1}) );
    return await dispatch( sortMedia(talentId) );
  };
}

export function moveTalentMediaUp(talentId, mediaId) {
  return async (dispatch, getState) => {
    let media = await dispatch(loadTalentMediaIfNeeded(talentId));
    media = await dispatch( sortMedia(talentId) );

    const thisMedia = media[mediaId];
    if(thisMedia.order > 0) {
      dispatch( updateMedia(mediaId, {order:thisMedia.order-1}) );
    }

    const switchMediaId = Object.keys(media).find(id => media[id].order === thisMedia.order-1);
    if(switchMediaId) {
      dispatch( updateMedia(switchMediaId, {order:media[switchMediaId].order+1}) );
    }
  };
}

export function moveTalentMediaDown(talentId, mediaId) {
  return async (dispatch, getState) => {
    let media = await dispatch(loadTalentMediaIfNeeded(talentId));
    media = await dispatch( sortMedia(talentId) );

    const thisMedia = media[mediaId];
    if(thisMedia.order < Object.keys(media).length-1) {
      dispatch( updateMedia(mediaId, {order:thisMedia.order+1}) );
    }

    const switchMediaId = Object.keys(media).find(id => media[id].order === thisMedia.order+1);
    if(switchMediaId) {
      dispatch( updateMedia(switchMediaId, {order:media[switchMediaId].order-1}) );
    }
  };
}

/* Depricated. Doing this based on search instead. */
export function messageAgencyTalents(agencyId, message) {
  return async (dispatch, getState) => {
    await dispatch(showSnackbar("Sending", 0));

    dispatch({
      type: "MESSAGE_AGENCY_TALENTS_REQUEST",
      agencyId,
    });

    const state = getState();
    const userId = state.user.id;
    const user = state.users.all[userId];
    const from = {
      userId: userId,
      name: `${user.firstName || ""} ${user.lastName || ""}`.trim() || "Agent",
    };

    const url = `${firebaseFunctionsURL}/messageAgencyTalent`;
    const options = {
      method: 'post',
      headers: {'content-type':'application/json'},
      body: JSON.stringify({
        from,
        agencyId,
        message,
      })
    };

    try {
      const thread = await fetch(url, options).then(res => res.json());
      dispatch({
        type: "MESSAGE_AGENCY_TALENTS",
        agencyId,
        thread,
      });
      dispatch(showSnackbar("Sent"));
      return thread;
    }

    catch(error) {
      dispatch({
        type: 'MESSAGE_AGENCY_TALENTS_FAILURE',
        code: 'messageAgencyTalents/01',
        error: error,
        message: "Message failed. Please try again.",
      });
      dispatch(showSnackbar("Message failed. Please try again."));
      return Promise.reject(error);
    }
  };
}

/* Depricated. Only used in messageScheduleTalent, which needs to be
transformed like messagePackTalent and messageTalentSearch */
export function messageBulk(message, recipients) {
  return async (dispatch, getState) => {
    await dispatch(showSnackbar("Sending", 0));

    // create the parent thread and add it to the user and the pack
    // gather the from data and add a `message` to the thread
    const state = getState();
    const userId = state.user.id;
    const user = state.users.all[userId];
    const from = {
      userId: userId,
      name: `${user.firstName || ""} ${user.lastName || ""}`.trim() || "Skybolt",
    };

    const parentThread = await dispatch(addThread({
      recipients,
      subject: message.subject,
      canReply: false,
      hasMessages: true,
      createdByUserId: userId,
      message: {
        from,
        ...message,
      },
      lastMessage: {
        from,
        ...message,
      },
      isSilent: true,
    }));

    dispatch(addThreadToUser(parentThread.id));

    // create a sub thread for each talent recipient
    // add the thread to the talent, the parent thread
    // and send the message
    const sends = Object.keys(recipients).map(async recipientId => {
      const recipient = recipients[recipientId];

      const thread = await dispatch(addThread({
        recipients: {
          [recipientId]: recipient,
          [userId]: {
            userId,
            name: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
          }
        },
        subject: message.subject,
        isSilent: true,
        createdByUserId: userId,
      }));

      dispatch(addThreadToThread(thread.id, parentThread.id));

      if(recipient.talentId) {
        dispatch(addThreadToTalent(thread.id, recipient.talentId));

        const talent = await dispatch(loadTalent(recipient.talentId));
        if(talent.userId) {
          dispatch(addThreadToUser(thread.id, talent.userId));
        }
      }

      let body = message.body;
      if(recipient.substitutions) {
        for(var key in recipient.substitutions) {
          var val = recipient.substitutions[key];
          body = body.replace(new RegExp(key, 'g'), val);
        }
      }

      const options = {
        body: body,
        subject: message.subject,
        showSnackbar: false,
      };

      dispatch(addMessage(thread.id, options));
    });

    await Promise.all(sends);

    // Show sent status.
    dispatch(showSnackbar("Sent"));

    return parentThread;
  };
}

export function renamePack(packId, name) {
  return (dispatch, getState) => {
    let pack = getState().packs.all[packId];

    if(!pack) {
      dispatch({
        type: "UPDATE_PACK_FAILURE",
        code: 'renamePack/001',
        message: "Pack not found.",
        packId,
      });
      return;
    }

    const dateUpdated = moment().valueOf();

    firebase.database().ref(`packs/${packId}/name`).set(name);
    firebase.database().ref(`threads/${pack.threadId}/subject`).set(name);
    firebase.database().ref(`packs/${packId}/dateUpdated`).set(dateUpdated);
    pack = {...pack, name, dateUpdated};

    dispatch({
      type: "UPDATE_PACK",
      packId,
      pack,
    });

    return pack;
  };
}

export function loadAgencyPacks(agencyId, limit=1000) {
  return async (dispatch, getState) => {
    dispatch({
      type: 'LOAD_AGENCY_PACKS_REQUEST',
      agencyId,
    });

    const packs = await firebase.database().ref('packs')
      .orderByChild('agencyId').equalTo(agencyId)
      .limitToLast(limit)
      .once('value').then(snapshot => {
        if(!snapshot.exists()) {
          return {};
        }
        const packs = snapshot.val();

        for(const id in packs) {
          packs[id].id = id;
        }

        return packs;
      });

    dispatch({
      type: 'LOAD_AGENCY_PACKS_SUCCESS',
      agencyId,
      packs,
    });

    return packs;
  };
}

// A stopgap measure to fill out pack previews.
export function loadPackTalentsPreview(packId) {
  return async (dispatch, getState) => {
    if(!packId) {
      return "";
    }

    const pack = getState().packs.all[packId];
    if(pack.preview) {
      return pack.preview;
    }

    let talents = getState().packTalents.byPack[packId];

    if(!talents) {
      talents = await firebase.database().ref(`packTalents/${packId}`)
        .limitToLast(10)
        .once('value').then(snapshot => snapshot.val());
    }

    if(!talents) {
      return;
    }

    const images = Object.keys(talents)
      .map(talentId => talents[talentId])
      .filter(talent => !!talent.image)
      .map(talent => talent.image.cloudinary_id)
      .slice(0, 4);


    let preview = "";

    // if(images.length < 4) {
      preview = cloudinaryUrl(images[0], {width:180, height:180, face:true});
    // }
    // else {
    //   preview = cloudinary.url(images[0], {transformation: [
    //     {width:90, height:90, crop:'fill', secure:true},
    //     {overlay:images[1], width:90, height:90, x:90, crop:"fill"},
    //     {overlay:images[2], width:90, height:90, y:90, x:-45, crop:"fill"},
    //     {overlay:images[3], width:90, height:90, y:45, x:45, crop:"fill"},
    //     {width:180, height:180, crop:"crop"},
    //   ]});
    // }

    if(preview) {
      firebase.database().ref(`packs/${packId}/preview`).set(preview);
      const updatedPack = {...pack, preview};

      dispatch({
        type: 'UPDATE_PACK',
        packId,
        pack: updatedPack,
      });
    }

    return pack;
  };
}

// A stopgap measure to fill out pack talent counts.
export function loadPackTalentsCount(packId) {
  return async (dispatch, getState) => {
    if(!packId) {
      return 0;
    }

    const pack = getState().packs.all[packId];
    if(pack.talentsCount !== undefined) {
      return pack.talentsCount;
    }

    let talents = await dispatch( loadPackTalents(packId) );
    const talentsCount = Object.keys(talents).length;

    firebase.database().ref(`packs/${packId}/talentsCount`).set(talentsCount);

    dispatch({
      type: 'UPDATE_PACK',
      packId,
      pack: {
        ...pack,
        talentsCount: talentsCount,
      }
    });

    return talentsCount;
  };
}

export function acceptPackRequest(requestId) {
  return async dispatch => {
    // Load pack request. Might look like,
    /*
    const request = {
      packId: pack.id,
      threadId: thread.id,
      name: message.subject,
      dateSent: Date.now(),
      dateAccepted: Date.now(),

    };
    */

    const req = await firebase.database().ref(`packRequests/${requestId}`).once('value').then(s => s.val());
    if(!req) {
      // todo: handle failure
      return false;
    }

    if(req.dateAccepted && req.childPackId) {
      return req.childPackId;
    }

    // Create a pack with
    // - the request name,
    // - the request parentPackId,
    const pack = await dispatch( addPack({name:req.name, parentPackId:req.packId}) );

    // Add thread to pack.

    dispatch(addThreadToPack(req.threadId, pack.id));

    // Mark the request as accepted.

    firebase.database().ref(`packRequests/${requestId}/dateAccepted`).set(Date.now());
    firebase.database().ref(`packRequests/${requestId}/childPackId`).set(pack.id);

    return pack.id;
  };
}